import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { useNavigate } from "react-router";
import { Row, Col } from "react-bootstrap";
import Button from "../../../../shared/components/Buttons/Button";

import CustomerImage from "../../../../assets/images/customerImage.svg";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import InputGenericField from "../../../../shared/components/inputGenericField/InputGenericField";
import {
  toastType,
  toastMessages,
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
  routesPathConstants,
  transactionConstants,
  tradeTransactionDefaultData,
  inventoryConstants,
} from "../../../../constants";
import {
  PrintIcon,
  GiftCardIcon,
} from "../../../../assets/icons/iconsProvider";
import {
  customToast,
  handleEmailOrSMSMessage,
  parseToThousandSeperatorDecimalNumber,
} from "../../../../shared/utility";
import { EMAIL_MESSAGE_TYPES } from "../../../../system/globalEnums";

const TradePaymentComplete = ({
  handleClick,
  newTradeData,
  setNewTradeData,
  handlePrintReceipt,
  sendEmailOrSMS,
  handleShowDraftModal,
  setAddBatchInventory,
}) => {
  const navigate = useNavigate();

  const initialValues = {
    email: newTradeData?.customer?.email || globalConstants.EMPTY_STRING,
    mobile: newTradeData?.customer?.mobile || globalConstants.EMPTY_STRING,
  };

  const handleSubmit = (data) => {};
  const restartTrade = () => {
    handleShowDraftModal();
    navigate(routesPathConstants.SELL_AND_TRADE_NEW_TRADE);
    setNewTradeData(tradeTransactionDefaultData);
    setAddBatchInventory([]);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values }) => (
        <div className="payment-cash-card-wrapper">
          <Row className="m-0">
            <Col
              md={12}
              className="d-flex justify-content-between align-items-center"
            >
              <span className="cash-heading">Payment Received</span>
            </Col>
            {newTradeData?.customer?.name !== "" &&
              newTradeData?.customer?.name !== null && (
                <Col md={12} className="my-3 py-2">
                  <div className="w-100 d-flex align-items-center selected-customer">
                    <img src={CustomerImage} alt="customer" />
                    <div className="d-flex flex-column justify-content-center ms-2">
                      <span className="customer-name-heading">{`${
                        newTradeData?.customer?.firstName || "Customer"
                      } ${newTradeData?.customer?.lastName || "Name"}`}</span>
                      <span>
                        {newTradeData?.customer?.mobile ||
                          newTradeData?.customer?.email ||
                          "(000) 000-0000"}
                      </span>
                    </div>
                    <div className="d-flex flex-column justify-content-center  align-items-center customer-credit ms-auto">
                      <span>Store Credit</span>
                      <span>
                        {/* ${newTradeData?.customer?.currentBalance || "0.00"} */}
                        $
                        {parseToThousandSeperatorDecimalNumber(
                          newTradeData?.customer?.currentBalance || 0
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
              )}
            <Col md={12} className="mb-3 d-flex gap-2 mt-3">
              <Button
                type="button"
                label={buttonNameConstants.PRINT}
                handleClick={() => handlePrintReceipt(newTradeData)}
                buttonType={buttonTypeConstants.LIGHT_PURPLE}
                className="w-100"
                IconImage={PrintIcon}
              ></Button>
              <Button
                type="button"
                className="w-100"
                label={buttonNameConstants.GIFT_RECEIPT}
                buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
                handleClick={() =>
                  handlePrintReceipt(
                    newTradeData,
                    buttonNameConstants.GIFT_RECEIPT
                  )
                }
                IconImage={GiftCardIcon}
              ></Button>
            </Col>

            <Col md={12} className="d-flex flex-column gap-2 my-3">
              <div className="w-100 d-flex  align-items-center gap-2">
                <span className="text-nowrap receipt-label">
                  Email a receipt:
                </span>

                <Col md={5} className=" ">
                  <InputTextField
                    className="background-gray"
                    name="email"
                    // label="Email"
                    placeHolder="Email"
                  />
                </Col>
                <Col md={4} className="marginLeft-auto ">
                  <Button
                    type="button"
                    label={buttonNameConstants.EMAIL}
                    handleClick={() => {
                      handleEmailOrSMSMessage(
                        EMAIL_MESSAGE_TYPES.TRANSACTION_RECEIPT,
                        true,
                        values,
                        newTradeData,
                        customToast,
                        toastMessages,
                        toastType,
                        sendEmailOrSMS
                      );
                    }}
                    buttonType={buttonTypeConstants.LIGHT_PURPLE}
                    className="w-100 email-send-buttons"
                    // isDisabled={!newTradeData?.customer?.email}
                    isDisabled={!values.email}
                  />
                </Col>
              </div>
              <div className="w-100 d-flex  align-items-center gap-2">
                <span className="text-nowrap receipt-label">
                  Text a receipt:
                </span>
                <Col md={5} className="">
                  <InputGenericField
                    name="mobile"
                    type="tel"
                    placeHolder="Phone Number"
                    // label="Phone Number"
                    inputMask="(999) 999-9999"
                    className="background-gray"
                  />
                </Col>
                <Col md={4} className="marginLeft-auto">
                  <Button
                    type="button"
                    label={buttonNameConstants.TEXT}
                    handleClick={() => {
                      handleEmailOrSMSMessage(
                        EMAIL_MESSAGE_TYPES.TRANSACTION_RECEIPT,
                        false,
                        values,
                        newTradeData,
                        customToast,
                        toastMessages,
                        toastType,
                        sendEmailOrSMS
                      );
                    }}
                    buttonType={buttonTypeConstants.LIGHT_PURPLE}
                    className="w-100 email-send-buttons"
                    // isDisabled={!newTradeData?.customer?.mobile}
                    isDisabled={!values.mobile}
                  />
                </Col>
              </div>
            </Col>

            <Col md={12} className="mb-3 d-flex gap-2 mt-3">
              <Button
                type="button"
                label={buttonNameConstants.BACK}
                handleClick={() => {
                  restartTrade();
                }}
                buttonType={buttonTypeConstants.GHOST_BUTTON}
                className="w-100"
              ></Button>
              <Button
                type="button"
                className="w-100"
                label={buttonNameConstants.COMPLETE}
                handleClick={() => {
                  restartTrade();
                }}
              ></Button>
            </Col>
          </Row>
        </div>
      )}
    </Formik>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  setAddBatchInventory: (data) =>
    dispatch({
      type:
        inventoryConstants.SET_ADD_BATCH_INVENTORY + globalConstants.SUCCESS,
      data: data,
    }),
});

//-------Export transaction Component
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TradePaymentComplete);
