import React from "react";

const ToggleButtonWithText = ({
  isToggle,
  handleLeftButtonClick,
  handleRightButtonClick,
  leftButtonLabel = "Button 1",
  rightButtonLabel = "Button 2",
  className,
}) => {
  return (
    <div className={`price-change-toggle-button-wrapper ${className}`}>
      <button
        type="button"
        className={`price-change-toggle-button ${
          !isToggle && "price-change-active-button"
        }`}
        onClick={() => handleLeftButtonClick()}
      >
        {leftButtonLabel}
      </button>
      <button
        type="button"
        className={`price-change-toggle-button ${
          isToggle && "price-change-active-button"
        }`}
        onClick={() => handleRightButtonClick()}
      >
        {rightButtonLabel}
      </button>
      <div
        className={`price-change-toggle-button-background  ${
          isToggle && "by-price-change-active"
        }`}
      ></div>
    </div>
  );
};

export default ToggleButtonWithText;
