import { GENRE_SUB_CATEGORY } from "../constants";
import { PRODUCT_TYPES_ENUMS } from "../system/globalEnums";

export const defaultTypes = [
  {
    productType: PRODUCT_TYPES_ENUMS.ALL,
    isCustom: false,
    isActive: true,
  },
  {
    productType: PRODUCT_TYPES_ENUMS.VIDEO_GAME,
    isCustom: false,
    isActive: true,
  },
  {
    productType: PRODUCT_TYPES_ENUMS.TRADING_CARD,
    isCustom: false,
    isActive: true,
  },
  {
    productType: PRODUCT_TYPES_ENUMS.OTHER,
    isCustom: false,
    isActive: true,
  },
];

export const defaultTradingCardCategories = [
  {
    name: "Pokemon Card",
    productTypeId: "090e889a-d73c-4d2e-842a-0946f15e01c6",
    isActive: true,
    id: "9fe6ec04-4f81-4e2b-b66d-d427b44165cc",
    _rid: "bGw9AJ6mlMvXAAAAAAAAAA==",
    _self: "dbs/bGw9AA==/colls/bGw9AJ6mlMs=/docs/bGw9AJ6mlMvXAAAAAAAAAA==/",
    _etag: '"00000000-0000-0000-bd66-9db6a63601da"',
    _attachments: "attachments/",
    _ts: 1718265139,
  },
  {
    name: "YuGiOh Card",
    productTypeId: "090e889a-d73c-4d2e-842a-0946f15e01c6",
    isActive: true,
    id: "8ca0c2f2-0dd0-466e-86a3-25e84ead1497",
    _rid: "bGw9AJ6mlMvZAAAAAAAAAA==",
    _self: "dbs/bGw9AA==/colls/bGw9AJ6mlMs=/docs/bGw9AJ6mlMvZAAAAAAAAAA==/",
    _etag: '"00000000-0000-0000-bd66-aa0e17e501da"',
    _attachments: "attachments/",
    _ts: 1718265159,
  },
  {
    name: "Magic Card",
    productTypeId: "090e889a-d73c-4d2e-842a-0946f15e01c6",
    isActive: true,
    id: "aeeb6f80-3855-48b4-b3fe-0699c8238cd6",
    _rid: "bGw9AJ6mlMvYAAAAAAAAAA==",
    _self: "dbs/bGw9AA==/colls/bGw9AJ6mlMs=/docs/bGw9AJ6mlMvYAAAAAAAAAA==/",
    _etag: '"00000000-0000-0000-bd66-a3f41cf801da"',
    _attachments: "attachments/",
    _ts: 1718265149,
  },
];

export const defaultCategories = [
  {
    id: "G25",
    name: "3DO",
    isActive: true,
    _rid: "A0sWAOZuINECAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINECAAAAAAAAAA==/",
    _etag: '"0001c99d-0000-0100-0000-665efe020000"',
    _attachments: "attachments/",
    _ts: 1717501442,
  },
  {
    id: "G36545",
    name: "Action Max",
    isActive: true,
    _rid: "A0sWAOZuINEEAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEEAAAAAAAAAA==/",
    _etag: '"68031140-0000-0100-0000-6657322d0000"',
    _attachments: "attachments/",
    _ts: 1716990509,
  },
  {
    id: "G46",
    name: "Amiga",
    isActive: true,
    _rid: "A0sWAOZuINEDAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEDAAAAAAAAAA==/",
    _etag: '"68032d3d-0000-0100-0000-665731080000"',
    _attachments: "attachments/",
    _ts: 1716990216,
  },
  {
    id: "G92",
    name: "Amiga CD32",
    isActive: true,
    _rid: "A0sWAOZuINEFAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEFAAAAAAAAAA==/",
    _etag: '"0000583d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G56",
    name: "Amiibo",
    isActive: true,
    _rid: "A0sWAOZuINEIAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEIAAAAAAAAAA==/",
    _etag: '"00005b3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G46757",
    name: "Amiibo Cards",
    isActive: true,
    _rid: "A0sWAOZuINEBAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEBAAAAAAAAAA==/",
    _etag: '"0000543d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G_Manual_2",
    name: "Amstrad CPC",
    isActive: true,
    _rid: "A0sWAOZuINHQAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINHQAAAAAAAAAA==/",
    _etag: '"68031342-0000-0100-0000-665733d60000"',
    _attachments: "attachments/",
    _ts: 1716990934,
  },
  {
    id: "G_Manual_1",
    name: "Amstrad GX4000",
    isActive: true,
    _rid: "A0sWAOZuINHPAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINHPAAAAAAAAAA==/",
    _etag: '"68030d42-0000-0100-0000-665733c00000"',
    _attachments: "attachments/",
    _ts: 1716990912,
  },
  {
    id: "G91",
    name: "Arcadia 2001",
    isActive: true,
    _rid: "A0sWAOZuINEKAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEKAAAAAAAAAA==/",
    _etag: '"00005d3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G24",
    name: "Atari 2600",
    isActive: true,
    _rid: "A0sWAOZuINERAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINERAAAAAAAAAA==/",
    _etag: '"0000643d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G45",
    name: "Atari 400",
    isActive: true,
    _rid: "A0sWAOZuINESAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINESAAAAAAAAAA==/",
    _etag: '"0000653d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G31",
    name: "Atari 5200",
    isActive: true,
    _rid: "A0sWAOZuINETAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINETAAAAAAAAAA==/",
    _etag: '"0000663d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G33",
    name: "Atari 7800",
    isActive: true,
    _rid: "A0sWAOZuINEUAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEUAAAAAAAAAA==/",
    _etag: '"0000673d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G26",
    name: "Atari Lynx",
    isActive: true,
    _rid: "A0sWAOZuINEVAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEVAAAAAAAAAA==/",
    _etag: '"0000683d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G146",
    name: "Atari ST",
    isActive: true,
    _rid: "A0sWAOZuINEWAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEWAAAAAAAAAA==/",
    _etag: '"0000693d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G90",
    name: "Bally Astrocade",
    isActive: true,
    _rid: "A0sWAOZuINEYAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEYAAAAAAAAAA==/",
    _etag: '"00006b3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G37",
    name: "CD-i",
    isActive: true,
    _rid: "A0sWAOZuINEXAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEXAAAAAAAAAA==/",
    _etag: '"00006a3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G63134",
    name: "Casio Loopy",
    isActive: true,
    _rid: "A0sWAOZuINEaAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEaAAAAAAAAAA==/",
    _etag: '"00006d3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G30",
    name: "Colecovision",
    isActive: true,
    _rid: "A0sWAOZuINEZAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEZAAAAAAAAAA==/",
    _etag: '"00006c3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G56918",
    name: "Commodore 16",
    isActive: true,
    _rid: "A0sWAOZuINEcAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEcAAAAAAAAAA==/",
    _etag: '"00006f3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G28",
    name: "Commodore 64",
    isActive: true,
    _rid: "A0sWAOZuINEbAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEbAAAAAAAAAA==/",
    _etag: '"00006e3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G52",
    name: "Disney Infinity",
    isActive: true,
    _rid: "A0sWAOZuINEdAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEdAAAAAAAAAA==/",
    _etag: '"0000703d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G53043",
    name: "Dreamcast Magazine",
    isActive: true,
    _rid: "A0sWAOZuINEfAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEfAAAAAAAAAA==/",
    _etag: '"0000723d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G53047",
    name: "Electronic Gaming Monthly",
    isActive: true,
    _rid: "A0sWAOZuINEeAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEeAAAAAAAAAA==/",
    _etag: '"0000713d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G28704",
    name: "Evercade",
    isActive: true,
    _rid: "A0sWAOZuINEgAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEgAAAAAAAAAA==/",
    _etag: '"0000733d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G55665",
    name: "FM Towns Marty",
    isActive: true,
    _rid: "A0sWAOZuINEiAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEiAAAAAAAAAA==/",
    _etag: '"0000753d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G83",
    name: "Fairchild Channel F",
    isActive: true,
    _rid: "A0sWAOZuINEhAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEhAAAAAAAAAA==/",
    _etag: '"0000743d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G55",
    name: "Famicom",
    isActive: true,
    _rid: "A0sWAOZuINElAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINElAAAAAAAAAA==/",
    _etag: '"0000783d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G117",
    name: "Famicom Disk System",
    isActive: true,
    _rid: "A0sWAOZuINEmAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEmAAAAAAAAAA==/",
    _etag: '"0000793d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "Action Max\t",
    name: "G36545",
    isActive: true,
    _rid: "A0sWAOZuINEGAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEGAAAAAAAAAA==/",
    _etag: '"0000593d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G68",
    name: "Game & Watch",
    isActive: true,
    _rid: "A0sWAOZuINEkAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEkAAAAAAAAAA==/",
    _etag: '"0000773d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G53044",
    name: "Game Informer",
    isActive: true,
    _rid: "A0sWAOZuINEjAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEjAAAAAAAAAA==/",
    _etag: '"0000763d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G55666",
    name: "Game Wave",
    isActive: true,
    _rid: "A0sWAOZuINEnAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEnAAAAAAAAAA==/",
    _etag: '"00007a3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G93",
    name: "Game.Com",
    isActive: true,
    _rid: "A0sWAOZuINEoAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEoAAAAAAAAAA==/",
    _etag: '"00007b3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G49",
    name: "GameBoy",
    isActive: true,
    _rid: "A0sWAOZuINErAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINErAAAAAAAAAA==/",
    _etag: '"00007e3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G1",
    name: "GameBoy Advance",
    isActive: true,
    _rid: "A0sWAOZuINEqAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEqAAAAAAAAAA==/",
    _etag: '"00007d3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G2",
    name: "GameBoy Color",
    isActive: true,
    _rid: "A0sWAOZuINEpAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEpAAAAAAAAAA==/",
    _etag: '"00007c3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G53046",
    name: "GamePro",
    isActive: true,
    _rid: "A0sWAOZuINEsAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEsAAAAAAAAAA==/",
    _etag: '"00007f3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G3",
    name: "Gamecube",
    isActive: true,
    _rid: "A0sWAOZuINEtAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEtAAAAAAAAAA==/",
    _etag: '"0000803d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G7728",
    name: "Gizmondo",
    isActive: true,
    _rid: "A0sWAOZuINEvAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEvAAAAAAAAAA==/",
    _etag: '"0000823d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G55667",
    name: "HyperScan",
    isActive: true,
    _rid: "A0sWAOZuINEuAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEuAAAAAAAAAA==/",
    _etag: '"0000813d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G27",
    name: "Intellivision",
    isActive: true,
    _rid: "A0sWAOZuINExAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINExAAAAAAAAAA==/",
    _etag: '"0000843d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G21",
    name: "Jaguar",
    isActive: true,
    _rid: "A0sWAOZuINFZAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFZAAAAAAAAAA==/",
    _etag: '"0000ac3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G65467",
    name: "Jaguar CD",
    isActive: true,
    _rid: "A0sWAOZuINHUAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINHUAAAAAAAAAA==/",
    _etag: '"02014fcc-0000-0100-0000-665f1e8f0000"',
    _attachments: "attachments/",
    _ts: 1717509775,
  },
  {
    id: "G66470",
    name: "LaserActive",
    isActive: true,
    _rid: "A0sWAOZuINHVAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINHVAAAAAAAAAA==/",
    _etag: '"020173cf-0000-0100-0000-665f1ebe0000"',
    _attachments: "attachments/",
    _ts: 1717509822,
  },
  {
    id: "G144",
    name: "Lego Dimensions",
    isActive: true,
    _rid: "A0sWAOZuINFYAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFYAAAAAAAAAA==/",
    _etag: '"0000ab3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G130",
    name: "Magnavox Odyssey",
    isActive: true,
    _rid: "A0sWAOZuINFXAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFXAAAAAAAAAA==/",
    _etag: '"0000aa3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G36",
    name: "Magnavox Odyssey 2",
    isActive: true,
    _rid: "A0sWAOZuINFcAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFcAAAAAAAAAA==/",
    _etag: '"0000af3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G131",
    name: "Magnavox Odyssey 300",
    isActive: true,
    _rid: "A0sWAOZuINFaAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFaAAAAAAAAAA==/",
    _etag: '"0000ad3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G132",
    name: "Mattel Aquarius",
    isActive: true,
    _rid: "A0sWAOZuINFhAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFhAAAAAAAAAA==/",
    _etag: '"0000b43d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G58494",
    name: "MegaZone",
    isActive: true,
    _rid: "A0sWAOZuINFdAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFdAAAAAAAAAA==/",
    _etag: '"0000b03d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G6956",
    name: "Microvision",
    isActive: true,
    _rid: "A0sWAOZuINFeAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFeAAAAAAAAAA==/",
    _etag: '"0000b13d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G69",
    name: "Mini Arcade",
    isActive: true,
    _rid: "A0sWAOZuINFfAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFfAAAAAAAAAA==/",
    _etag: '"0000b23d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G42",
    name: "N-Gage",
    isActive: true,
    _rid: "A0sWAOZuINFgAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFgAAAAAAAAAA==/",
    _etag: '"0000b33d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G17",
    name: "NES",
    isActive: true,
    _rid: "A0sWAOZuINFiAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFiAAAAAAAAAA==/",
    _etag: '"0000b53d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G116",
    name: "Neo Geo AES",
    isActive: true,
    _rid: "A0sWAOZuINFkAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFkAAAAAAAAAA==/",
    _etag: '"0000b73d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G60",
    name: "Neo Geo CD",
    isActive: true,
    _rid: "A0sWAOZuINFlAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFlAAAAAAAAAA==/",
    _etag: '"0000b83d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G18",
    name: "Neo Geo MVS",
    isActive: true,
    _rid: "A0sWAOZuINFjAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFjAAAAAAAAAA==/",
    _etag: '"02013ed4-0000-0100-0000-665f1eff0000"',
    _attachments: "attachments/",
    _ts: 1717509887,
  },
  {
    id: "G40",
    name: "Neo Geo Pocket Color",
    isActive: true,
    _rid: "A0sWAOZuINFmAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFmAAAAAAAAAA==/",
    _etag: '"0000b93d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G39",
    name: "Nintendo 3DS",
    isActive: true,
    _rid: "A0sWAOZuINFnAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFnAAAAAAAAAA==/",
    _etag: '"0000ba3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G4",
    name: "Nintendo 64",
    isActive: true,
    _rid: "A0sWAOZuINFoAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFoAAAAAAAAAA==/",
    _etag: '"0000bb3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G5",
    name: "Nintendo DS",
    isActive: true,
    _rid: "A0sWAOZuINFpAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFpAAAAAAAAAA==/",
    _etag: '"0000bc3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G81",
    name: "Nintendo Power",
    isActive: true,
    _rid: "A0sWAOZuINFqAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFqAAAAAAAAAA==/",
    _etag: '"0000bd3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G59",
    name: "Nintendo Switch",
    isActive: true,
    _rid: "A0sWAOZuINFrAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFrAAAAAAAAAA==/",
    _etag: '"0000be3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G6955",
    name: "PC FX",
    isActive: true,
    _rid: "A0sWAOZuINGWAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGWAAAAAAAAAA==/",
    _etag: '"0000e93d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G53045",
    name: "PC Gamer Magazine",
    isActive: true,
    _rid: "A0sWAOZuINGXAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGXAAAAAAAAAA==/",
    _etag: '"020142eb-0000-0100-0000-665f20450000"',
    _attachments: "attachments/",
    _ts: 1717510213,
  },
  {
    id: "G145",
    name: "PC Games",
    isActive: true,
    _rid: "A0sWAOZuINGYAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGYAAAAAAAAAA==/",
    _etag: '"0000eb3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G9",
    name: "PSP",
    isActive: true,
    _rid: "A0sWAOZuINGZAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGZAAAAAAAAAA==/",
    _etag: '"0000ec3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G55669",
    name: "Pippin",
    isActive: true,
    _rid: "A0sWAOZuINGaAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGaAAAAAAAAAA==/",
    _etag: '"0000ed3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G6",
    name: "Playstation",
    isActive: true,
    _rid: "A0sWAOZuINGbAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGbAAAAAAAAAA==/",
    _etag: '"0000ee3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G7",
    name: "Playstation 2",
    isActive: true,
    _rid: "A0sWAOZuINGcAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGcAAAAAAAAAA==/",
    _etag: '"0000ef3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G12",
    name: "Playstation 3",
    isActive: true,
    _rid: "A0sWAOZuINGdAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGdAAAAAAAAAA==/",
    _etag: '"0000f03d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G53",
    name: "Playstation 4",
    isActive: true,
    _rid: "A0sWAOZuINGeAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGeAAAAAAAAAA==/",
    _etag: '"0000f13d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G7468",
    name: "Playstation 5",
    isActive: true,
    _rid: "A0sWAOZuINGfAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGfAAAAAAAAAA==/",
    _etag: '"0000f23d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G43",
    name: "Playstation Vita",
    isActive: true,
    _rid: "A0sWAOZuINGgAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGgAAAAAAAAAA==/",
    _etag: '"0000f33d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G143",
    name: "Pokemon Mini",
    isActive: true,
    _rid: "A0sWAOZuINGhAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGhAAAAAAAAAA==/",
    _etag: '"0000f43d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G43345",
    name: "Rumble U",
    isActive: true,
    _rid: "A0sWAOZuINGiAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGiAAAAAAAAAA==/",
    _etag: '"0000f53d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G50",
    name: "Sega 32X",
    isActive: true,
    _rid: "A0sWAOZuINGjAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGjAAAAAAAAAA==/",
    _etag: '"0000f63d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G23",
    name: "Sega CD",
    isActive: true,
    _rid: "A0sWAOZuINGkAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGkAAAAAAAAAA==/",
    _etag: '"0000f73d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G16",
    name: "Sega Dreamcast",
    isActive: true,
    _rid: "A0sWAOZuINGlAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGlAAAAAAAAAA==/",
    _etag: '"0000f83d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G20",
    name: "Sega Game Gear",
    isActive: true,
    _rid: "A0sWAOZuINGmAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGmAAAAAAAAAA==/",
    _etag: '"0000f93d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G15",
    name: "Sega Genesis",
    isActive: true,
    _rid: "A0sWAOZuINGnAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGnAAAAAAAAAA==/",
    _etag: '"0000fa3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G29",
    name: "Sega Master System",
    isActive: true,
    _rid: "A0sWAOZuINGoAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGoAAAAAAAAAA==/",
    _etag: '"0000fb3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G136",
    name: "Sega Pico",
    isActive: true,
    _rid: "A0sWAOZuINGpAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGpAAAAAAAAAA==/",
    _etag: '"0000fc3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G14",
    name: "Sega Saturn",
    isActive: true,
    _rid: "A0sWAOZuINGqAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGqAAAAAAAAAA==/",
    _etag: '"0000fd3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G61110",
    name: "Sharp X68000",
    isActive: true,
    _rid: "A0sWAOZuINGrAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGrAAAAAAAAAA==/",
    _etag: '"0000fe3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G48",
    name: "Skylanders",
    isActive: true,
    _rid: "A0sWAOZuINGsAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGsAAAAAAAAAA==/",
    _etag: '"0000ff3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G43344",
    name: "Starlink",
    isActive: true,
    _rid: "A0sWAOZuINGtAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGtAAAAAAAAAA==/",
    _etag: '"0000003e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G60326",
    name: "Stoneheart",
    isActive: true,
    _rid: "A0sWAOZuINGuAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGuAAAAAAAAAA==/",
    _etag: '"0000013e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G26297",
    name: "Strategy Guide",
    isActive: true,
    _rid: "A0sWAOZuINGvAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGvAAAAAAAAAA==/",
    _etag: '"0000023e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G66",
    name: "Super Famicom",
    isActive: true,
    _rid: "A0sWAOZuINGwAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGwAAAAAAAAAA==/",
    _etag: '"0000033e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G13",
    name: "Super Nintendo",
    isActive: true,
    _rid: "A0sWAOZuINGxAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGxAAAAAAAAAA==/",
    _etag: '"0000043e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G55668",
    name: "Supervision",
    isActive: true,
    _rid: "A0sWAOZuINGyAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGyAAAAAAAAAA==/",
    _etag: '"0000053e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G120",
    name: "TI-99",
    isActive: true,
    _rid: "A0sWAOZuINGzAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGzAAAAAAAAAA==/",
    _etag: '"0000063e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G51260",
    name: "Tapwave Zodiac",
    isActive: true,
    _rid: "A0sWAOZuING0AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuING0AAAAAAAAAA==/",
    _etag: '"0000073e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G43401",
    name: "Tiger R-Zone",
    isActive: true,
    _rid: "A0sWAOZuING1AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuING1AAAAAAAAAA==/",
    _etag: '"0000083e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G6927",
    name: "TurboGrafx CD",
    isActive: true,
    _rid: "A0sWAOZuING2AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuING2AAAAAAAAAA==/",
    _etag: '"0000093e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G19",
    name: "TurboGrafx-16",
    isActive: true,
    _rid: "A0sWAOZuING3AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuING3AAAAAAAAAA==/",
    _etag: '"00000a3e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G56889",
    name: "VTech Socrates",
    isActive: true,
    _rid: "A0sWAOZuING4AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuING4AAAAAAAAAA==/",
    _etag: '"00000b3e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G32",
    name: "Vectrex",
    isActive: true,
    _rid: "A0sWAOZuING5AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuING5AAAAAAAAAA==/",
    _etag: '"00000c3e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G44",
    name: "Vic-20",
    isActive: true,
    _rid: "A0sWAOZuING6AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuING6AAAAAAAAAA==/",
    _etag: '"00000d3e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G22",
    name: "Virtual Boy",
    isActive: true,
    _rid: "A0sWAOZuING7AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuING7AAAAAAAAAA==/",
    _etag: '"00000e3e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G57",
    name: "Wholesale",
    isActive: true,
    _rid: "A0sWAOZuING8AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuING8AAAAAAAAAA==/",
    _etag: '"00000f3e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G11",
    name: "Wii",
    isActive: true,
    _rid: "A0sWAOZuING9AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuING9AAAAAAAAAA==/",
    _etag: '"0000103e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G47",
    name: "Wii U",
    isActive: true,
    _rid: "A0sWAOZuING+AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuING+AAAAAAAAAA==/",
    _etag: '"0000113e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G151",
    name: "WonderSwan",
    isActive: true,
    _rid: "A0sWAOZuING-AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuING-AAAAAAAAAA==/",
    _etag: '"0000123e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G152",
    name: "WonderSwan Color",
    isActive: true,
    _rid: "A0sWAOZuINHAAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINHAAAAAAAAAAA==/",
    _etag: '"0000133e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G8",
    name: "Xbox",
    isActive: true,
    _rid: "A0sWAOZuINHBAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINHBAAAAAAAAAA==/",
    _etag: '"0000143e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G10",
    name: "Xbox 360",
    isActive: true,
    _rid: "A0sWAOZuINHCAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINHCAAAAAAAAAA==/",
    _etag: '"0000153e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G54",
    name: "Xbox One",
    isActive: true,
    _rid: "A0sWAOZuINHDAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINHDAAAAAAAAAA==/",
    _etag: '"0000163e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G7585",
    name: "Xbox Series X",
    isActive: true,
    _rid: "A0sWAOZuINHEAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINHEAAAAAAAAAA==/",
    _etag: '"0000173e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G44257",
    name: "ZX Spectrum",
    isActive: true,
    _rid: "A0sWAOZuINHFAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINHFAAAAAAAAAA==/",
    _etag: '"0000183e-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G68532",
    name: "JP 3DO",
    isActive: true,
    _rid: "A0sWAOZuINHSAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINHSAAAAAAAAAA==/",
    _etag: '"0201bfbb-0000-0100-0000-665f1d9d0000"',
    _attachments: "attachments/",
    _ts: 1717509533,
  },
  {
    id: "G112",
    name: "JP GameBoy",
    isActive: true,
    _rid: "A0sWAOZuINEzAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEzAAAAAAAAAA==/",
    _etag: '"0000863d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G79",
    name: "JP GameBoy Advance",
    isActive: true,
    _rid: "A0sWAOZuINEwAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEwAAAAAAAAAA==/",
    _etag: '"0000833d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G113",
    name: "JP GameBoy Color",
    isActive: true,
    _rid: "A0sWAOZuINE8AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINE8AAAAAAAAAA==/",
    _etag: '"00008f3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G98",
    name: "JP Gamecube",
    isActive: true,
    _rid: "A0sWAOZuINEyAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEyAAAAAAAAAA==/",
    _etag: '"0000853d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G68060",
    name: "JP LaserActive",
    isActive: true,
    _rid: "A0sWAOZuINHTAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINHTAAAAAAAAAA==/",
    _etag: '"020103c0-0000-0100-0000-665f1ddd0000"',
    _attachments: "attachments/",
    _ts: 1717509597,
  },
  {
    id: "G122",
    name: "JP MSX",
    isActive: true,
    _rid: "A0sWAOZuINE0AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINE0AAAAAAAAAA==/",
    _etag: '"0000873d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G128",
    name: "JP MSX2",
    isActive: true,
    _rid: "A0sWAOZuINE1AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINE1AAAAAAAAAA==/",
    _etag: '"0000883d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G115",
    name: "JP Neo Geo AES",
    isActive: true,
    _rid: "A0sWAOZuINE5AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINE5AAAAAAAAAA==/",
    _etag: '"00008c3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G43342",
    name: "JP Neo Geo CD",
    isActive: true,
    _rid: "A0sWAOZuINE3AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINE3AAAAAAAAAA==/",
    _etag: '"00008a3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G114",
    name: "JP Neo Geo MVS",
    isActive: true,
    _rid: "A0sWAOZuINE2AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINE2AAAAAAAAAA==/",
    _etag: '"020178c9-0000-0100-0000-665f1e650000"',
    _attachments: "attachments/",
    _ts: 1717509733,
  },
  {
    id: "G59835",
    name: "JP Neo Geo Pocket",
    isActive: true,
    _rid: "A0sWAOZuINE4AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINE4AAAAAAAAAA==/",
    _etag: '"00008b3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G129",
    name: "JP Neo Geo Pocket Color",
    isActive: true,
    _rid: "A0sWAOZuINE6AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINE6AAAAAAAAAA==/",
    _etag: '"00008d3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G119",
    name: "JP Nintendo 3DS",
    isActive: true,
    _rid: "A0sWAOZuINE7AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINE7AAAAAAAAAA==/",
    _etag: '"00008e3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G99",
    name: "JP Nintendo 64",
    isActive: true,
    _rid: "A0sWAOZuINE9AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINE9AAAAAAAAAA==/",
    _etag: '"0000903d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G111",
    name: "JP Nintendo DS",
    isActive: true,
    _rid: "A0sWAOZuINE-AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINE-AAAAAAAAAA==/",
    _etag: '"0000923d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G100",
    name: "JP Nintendo Switch",
    isActive: true,
    _rid: "A0sWAOZuINE+AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINE+AAAAAAAAAA==/",
    _etag: '"0000913d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G82",
    name: "JP PC Engine",
    isActive: true,
    _rid: "A0sWAOZuINFBAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFBAAAAAAAAAA==/",
    _etag: '"0000943d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G154",
    name: "JP PC Engine CD",
    isActive: true,
    _rid: "A0sWAOZuINFFAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFFAAAAAAAAAA==/",
    _etag: '"0000983d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G110",
    name: "JP PSP",
    isActive: true,
    _rid: "A0sWAOZuINFAAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFAAAAAAAAAAA==/",
    _etag: '"0000933d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G107",
    name: "JP Playstation",
    isActive: true,
    _rid: "A0sWAOZuINFDAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFDAAAAAAAAAA==/",
    _etag: '"0000963d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G108",
    name: "JP Playstation 2",
    isActive: true,
    _rid: "A0sWAOZuINFCAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFCAAAAAAAAAA==/",
    _etag: '"0000953d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G109",
    name: "JP Playstation 3",
    isActive: true,
    _rid: "A0sWAOZuINFKAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFKAAAAAAAAAA==/",
    _etag: '"00009d3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G118",
    name: "JP Playstation 4",
    isActive: true,
    _rid: "A0sWAOZuINFGAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFGAAAAAAAAAA==/",
    _etag: '"0000993d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G7470",
    name: "JP Playstation 5",
    isActive: true,
    _rid: "A0sWAOZuINFEAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFEAAAAAAAAAA==/",
    _etag: '"0000973d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G106",
    name: "JP Playstation Vita",
    isActive: true,
    _rid: "A0sWAOZuINFJAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFJAAAAAAAAAA==/",
    _etag: '"00009c3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G64",
    name: "JP Sega Dreamcast",
    isActive: true,
    _rid: "A0sWAOZuINFIAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFIAAAAAAAAAA==/",
    _etag: '"00009b3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G74",
    name: "JP Sega Game Gear",
    isActive: true,
    _rid: "A0sWAOZuINFHAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFHAAAAAAAAAA==/",
    _etag: '"00009a3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G6900",
    name: "JP Sega Mark III",
    isActive: true,
    _rid: "A0sWAOZuINFRAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFRAAAAAAAAAA==/",
    _etag: '"0000a43d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G140",
    name: "JP Sega Mega CD",
    isActive: true,
    _rid: "A0sWAOZuINFNAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFNAAAAAAAAAA==/",
    _etag: '"0000a03d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G105",
    name: "JP Sega Mega Drive",
    isActive: true,
    _rid: "A0sWAOZuINFLAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFLAAAAAAAAAA==/",
    _etag: '"00009e3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G137",
    name: "JP Sega Pico",
    isActive: true,
    _rid: "A0sWAOZuINFOAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFOAAAAAAAAAA==/",
    _etag: '"0000a13d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G67",
    name: "JP Sega Saturn",
    isActive: true,
    _rid: "A0sWAOZuINFMAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFMAAAAAAAAAA==/",
    _etag: '"00009f3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G142",
    name: "JP Super 32X",
    isActive: true,
    _rid: "A0sWAOZuINFQAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFQAAAAAAAAAA==/",
    _etag: '"0000a33d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G76",
    name: "JP Virtual Boy",
    isActive: true,
    _rid: "A0sWAOZuINFUAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFUAAAAAAAAAA==/",
    _etag: '"0000a73d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G102",
    name: "JP Wii",
    isActive: true,
    _rid: "A0sWAOZuINFPAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFPAAAAAAAAAA==/",
    _etag: '"0000a23d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G104",
    name: "JP Wii U",
    isActive: true,
    _rid: "A0sWAOZuINFSAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFSAAAAAAAAAA==/",
    _etag: '"0000a53d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G125",
    name: "JP Xbox",
    isActive: true,
    _rid: "A0sWAOZuINFVAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFVAAAAAAAAAA==/",
    _etag: '"0000a83d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G124",
    name: "JP Xbox 360",
    isActive: true,
    _rid: "A0sWAOZuINFTAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFTAAAAAAAAAA==/",
    _etag: '"0000a63d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G126",
    name: "JP Xbox One",
    isActive: true,
    _rid: "A0sWAOZuINFWAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFWAAAAAAAAAA==/",
    _etag: '"0000a93d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G7587",
    name: "JP Xbox Series X",
    isActive: true,
    _rid: "A0sWAOZuINFbAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFbAAAAAAAAAA==/",
    _etag: '"0000ae3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G68531",
    name: "PAL 3DO",
    isActive: true,
    _rid: "A0sWAOZuINHWAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINHWAAAAAAAAAA==/",
    _etag: '"0201e1d7-0000-0100-0000-665f1f310000"',
    _attachments: "attachments/",
    _ts: 1717509937,
  },
  {
    id: "G153",
    name: "PAL Amiga CD32",
    isActive: true,
    _rid: "A0sWAOZuINFsAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFsAAAAAAAAAA==/",
    _etag: '"0000bf3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G62701",
    name: "PAL Atari 7800",
    isActive: true,
    _rid: "A0sWAOZuINFtAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFtAAAAAAAAAA==/",
    _etag: '"0000c03d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G61287",
    name: "PAL Evercade",
    isActive: true,
    _rid: "A0sWAOZuINFuAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFuAAAAAAAAAA==/",
    _etag: '"0000c13d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G73",
    name: "PAL GameBoy",
    isActive: true,
    _rid: "A0sWAOZuINFvAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFvAAAAAAAAAA==/",
    _etag: '"0000c23d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G80",
    name: "PAL GameBoy Advance",
    isActive: true,
    _rid: "A0sWAOZuINFwAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFwAAAAAAAAAA==/",
    _etag: '"0000c33d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G77",
    name: "PAL GameBoy Color",
    isActive: true,
    _rid: "A0sWAOZuINFxAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFxAAAAAAAAAA==/",
    _etag: '"0000c43d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G70",
    name: "PAL Gamecube",
    isActive: true,
    _rid: "A0sWAOZuINFyAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFyAAAAAAAAAA==/",
    _etag: '"0000c53d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G123",
    name: "PAL MSX",
    isActive: true,
    _rid: "A0sWAOZuINFzAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINFzAAAAAAAAAA==/",
    _etag: '"0000c63d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G127",
    name: "PAL MSX2",
    isActive: true,
    _rid: "A0sWAOZuINF0AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINF0AAAAAAAAAA==/",
    _etag: '"0000c73d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G141",
    name: "PAL Mega Drive 32X",
    isActive: true,
    _rid: "A0sWAOZuINF1AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINF1AAAAAAAAAA==/",
    _etag: '"0000c83d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G63150",
    name: "PAL N-Gage",
    isActive: true,
    _rid: "A0sWAOZuINF2AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINF2AAAAAAAAAA==/",
    _etag: '"0000c93d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G58",
    name: "PAL NES",
    isActive: true,
    _rid: "A0sWAOZuINF3AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINF3AAAAAAAAAA==/",
    _etag: '"0000ca3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G61530",
    name: "PAL Neo Geo Pocket",
    isActive: true,
    _rid: "A0sWAOZuINF4AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINF4AAAAAAAAAA==/",
    _etag: '"0000cb3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G37074",
    name: "PAL Neo Geo Pocket Color",
    isActive: true,
    _rid: "A0sWAOZuINF5AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINF5AAAAAAAAAA==/",
    _etag: '"0000cc3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G94",
    name: "PAL Nintendo 3DS",
    isActive: true,
    _rid: "A0sWAOZuINF6AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINF6AAAAAAAAAA==/",
    _etag: '"020132da-0000-0100-0000-665f1f520000"',
    _attachments: "attachments/",
    _ts: 1717509970,
  },
  {
    id: "G62",
    name: "PAL Nintendo 64",
    isActive: true,
    _rid: "A0sWAOZuINF7AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINF7AAAAAAAAAA==/",
    _etag: '"02015ddd-0000-0100-0000-665f1f810000"',
    _attachments: "attachments/",
    _ts: 1717510017,
  },
  {
    id: "G78",
    name: "PAL Nintendo DS",
    isActive: true,
    _rid: "A0sWAOZuINF8AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINF8AAAAAAAAAA==/",
    _etag: '"020199e3-0000-0100-0000-665f1fd80000"',
    _attachments: "attachments/",
    _ts: 1717510104,
  },
  {
    id: "G87",
    name: "PAL Nintendo Switch",
    isActive: true,
    _rid: "A0sWAOZuINF9AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINF9AAAAAAAAAA==/",
    _etag: '"0201e5e7-0000-0100-0000-665f20140000"',
    _attachments: "attachments/",
    _ts: 1717510164,
  },
  {
    id: "G84",
    name: "PAL PSP",
    isActive: true,
    _rid: "A0sWAOZuINF+AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINF+AAAAAAAAAA==/",
    _etag: '"0000d13d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G72",
    name: "PAL Playstation",
    isActive: true,
    _rid: "A0sWAOZuINF-AAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINF-AAAAAAAAAA==/",
    _etag: '"0000d23d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G63",
    name: "PAL Playstation 2",
    isActive: true,
    _rid: "A0sWAOZuINGAAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGAAAAAAAAAAA==/",
    _etag: '"0000d33d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G75",
    name: "PAL Playstation 3",
    isActive: true,
    _rid: "A0sWAOZuINGBAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGBAAAAAAAAAA==/",
    _etag: '"0000d43d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G86",
    name: "PAL Playstation 4",
    isActive: true,
    _rid: "A0sWAOZuINGCAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGCAAAAAAAAAA==/",
    _etag: '"0000d53d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G7469",
    name: "PAL Playstation 5",
    isActive: true,
    _rid: "A0sWAOZuINGDAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGDAAAAAAAAAA==/",
    _etag: '"0000d63d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G101",
    name: "PAL Playstation Vita",
    isActive: true,
    _rid: "A0sWAOZuINGEAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGEAAAAAAAAAA==/",
    _etag: '"0000d73d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G65",
    name: "PAL Sega Dreamcast",
    isActive: true,
    _rid: "A0sWAOZuINGFAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGFAAAAAAAAAA==/",
    _etag: '"0000d83d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G121",
    name: "PAL Sega Game Gear",
    isActive: true,
    _rid: "A0sWAOZuINGGAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGGAAAAAAAAAA==/",
    _etag: '"0000d93d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G51",
    name: "PAL Sega Master System",
    isActive: true,
    _rid: "A0sWAOZuINGHAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGHAAAAAAAAAA==/",
    _etag: '"0000da3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G139",
    name: "PAL Sega Mega CD",
    isActive: true,
    _rid: "A0sWAOZuINGIAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGIAAAAAAAAAA==/",
    _etag: '"0000db3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G71",
    name: "PAL Sega Mega Drive",
    isActive: true,
    _rid: "A0sWAOZuINGJAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGJAAAAAAAAAA==/",
    _etag: '"0000dc3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G138",
    name: "PAL Sega Pico",
    isActive: true,
    _rid: "A0sWAOZuINGKAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGKAAAAAAAAAA==/",
    _etag: '"0000dd3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G97",
    name: "PAL Sega Saturn",
    isActive: true,
    _rid: "A0sWAOZuINGLAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGLAAAAAAAAAA==/",
    _etag: '"0000de3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G61",
    name: "PAL Super Nintendo",
    isActive: true,
    _rid: "A0sWAOZuINGMAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGMAAAAAAAAAA==/",
    _etag: '"0000df3d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G133",
    name: "PAL Vectrex",
    isActive: true,
    _rid: "A0sWAOZuINGNAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGNAAAAAAAAAA==/",
    _etag: '"0000e03d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G134",
    name: "PAL Videopac G7000",
    isActive: true,
    _rid: "A0sWAOZuINGOAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGOAAAAAAAAAA==/",
    _etag: '"0000e13d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G135",
    name: "PAL Videopac G7400",
    isActive: true,
    _rid: "A0sWAOZuINGPAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGPAAAAAAAAAA==/",
    _etag: '"0000e23d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G85",
    name: "PAL Wii",
    isActive: true,
    _rid: "A0sWAOZuINGQAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGQAAAAAAAAAA==/",
    _etag: '"0000e33d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G96",
    name: "PAL Wii U",
    isActive: true,
    _rid: "A0sWAOZuINGRAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGRAAAAAAAAAA==/",
    _etag: '"0000e43d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G88",
    name: "PAL Xbox",
    isActive: true,
    _rid: "A0sWAOZuINGSAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGSAAAAAAAAAA==/",
    _etag: '"0000e53d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G89",
    name: "PAL Xbox 360",
    isActive: true,
    _rid: "A0sWAOZuINGTAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGTAAAAAAAAAA==/",
    _etag: '"0000e63d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G95",
    name: "PAL Xbox One",
    isActive: true,
    _rid: "A0sWAOZuINGUAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGUAAAAAAAAAA==/",
    _etag: '"0000e73d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G7586",
    name: "PAL Xbox Series X",
    isActive: true,
    _rid: "A0sWAOZuINGVAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINGVAAAAAAAAAA==/",
    _etag: '"0000e83d-0000-0100-0000-65b8de270000"',
    _attachments: "attachments/",
    _ts: 1706614311,
  },
  {
    id: "G56638",
    name: "Asian English Nintendo 3DS",
    isActive: true,
    _rid: "A0sWAOZuINEJAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEJAAAAAAAAAA==/",
    _etag: '"00005c3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G59682",
    name: "Asian English PSP",
    isActive: true,
    _rid: "A0sWAOZuINENAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINENAAAAAAAAAA==/",
    _etag: '"0000603d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G_Manual_3",
    name: "Asian English Playsation 2",
    isActive: true,
    _rid: "A0sWAOZuINHRAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINHRAAAAAAAAAA==/",
    _etag: '"0201a8b0-0000-0100-0000-665f1cfa0000"',
    _attachments: "attachments/",
    _ts: 1717509370,
  },
  {
    id: "G56634",
    name: "Asian English Playstation 3",
    isActive: true,
    _rid: "A0sWAOZuINELAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINELAAAAAAAAAA==/",
    _etag: '"00005e3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G56635",
    name: "Asian English Playstation 4",
    isActive: true,
    _rid: "A0sWAOZuINEOAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEOAAAAAAAAAA==/",
    _etag: '"0000613d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G56636",
    name: "Asian English Playstation 5",
    isActive: true,
    _rid: "A0sWAOZuINEMAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEMAAAAAAAAAA==/",
    _etag: '"00005f3d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G56637",
    name: "Asian English Playstation Vita",
    isActive: true,
    _rid: "A0sWAOZuINEQAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEQAAAAAAAAAA==/",
    _etag: '"0000633d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
  {
    id: "G56633",
    name: "Asian English Switch",
    isActive: true,
    _rid: "A0sWAOZuINEPAAAAAAAAAA==",
    _self: "dbs/A0sWAA==/colls/A0sWAOZuINE=/docs/A0sWAOZuINEPAAAAAAAAAA==/",
    _etag: '"0000623d-0000-0100-0000-65b8de260000"',
    _attachments: "attachments/",
    _ts: 1706614310,
  },
];

export const defaultSubCategories = GENRE_SUB_CATEGORY.PRICECHARTING_GENRE.map(
  (subCategoryObject) => ({
    name: subCategoryObject.subCategory,
    isActive: true,
  })
);

export const DEFAULT_TAGS = {
  systemsDefaultTags: {
    complete: [
      "#missing-manual",
      "#missing-wires",
      "#missing-controller",
      "#damaged",
    ],
    loose: [
      "#with-manual",
      "#missing-wires",
      "#missing-controller",
      "#damaged",
    ],
    boxOnly: ["#damaged"],
  },
  gamesDefaultTags: {
    complete: ["#missing-manual", "#damaged"],
    loose: ["#with-manual", "#damaged"],
    boxOnly: ["#damaged"],
    manualOnly: ["#damaged"],
  },
};

export const SYSTEM_SUBCATEGORY = "SYSTEM_SUBCATEGORY";
