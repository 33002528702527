//-------Email rejex pattern

import { ROLES } from "../../constants";

// value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/i,
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
const checkSpace = /^(?:[^\s]+)?$/;
const uppercaseCheck = /^(?=.*[A-Z]).*$/;
const numbersCheck = /^(?=.*\d).+$/;
const specialCharacterCheck = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).+$/;
const onlyNumbersCheck = /^[0-9]+$/;
export const phoneRegex = /\(\d{3}\) \d{3}-\d{4}/;
export const importPhoneRegex =
  /^(1\s?)?(\+1\s?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
export const cleanImportPhoneRegex = /^\+1|[-() ]/g;

export const reportValidationSchema = (values) => {
  let errors = {};

  if (!values.fullname) {
    errors.fullname = "Fullname is required";
  }
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "Invalid Email Format";
  }
  if (!values.problem) {
    errors.problem = "Problem is required";
  }

  return errors;
};

//-------Login validation schema
export const loginValidationSchema = (values) => {
  let errors = {};

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "Invalid Email Format";
  }

  if (!values.password) {
    errors.password = "Password is required";
  }

  return errors;
};
//------- Global tradeIns validation schema
export const tradeInsGlobalValidationSchema = (values) => {
  let errors = {};

  if (!values.cashMargin) {
    errors.cashMargin = "Cash margin is required.";
  } else if (!numbersCheck.test(values.cashMargin)) {
    errors.cashMargin = "Cash margin should only contain numbers";
  }
  if (!values.tradeMargin) {
    errors.tradeMargin = "Trade margin is required.";
  } else if (!numbersCheck.test(values.tradeMargin)) {
    errors.tradeMargin = "Trade margin should only contain numbers";
  }
  // if (
  //   numbersCheck.test(values.cashMargin) &&
  //   numbersCheck.test(values.tradeMargin)
  // ) {
  //   const cashOfferValue = Number(values.cashMargin);
  //   const tradeOfferValue = Number(values.tradeMargin);

  //   if (cashOfferValue <= tradeOfferValue) {
  //     errors.cashMargin = "Cash should be greater.";
  //   }
  // }

  return errors;
};

//------- Global tradeIns validation schema
export const globalMarkupValidationSchema = (values) => {
  let errors = {};
  if (!Number(values.markupPercentage)) {
    errors.markupPercentage = "Percentage Markup is required.";
  }

  return errors;
};

//-------Monthlyl tradeIns validation schema
export const tradeInsMonthlyValidationSchema = (values) => {
  let errors = {};
  if (!values.monthlyPrice) {
    errors.monthlyPrice = "Monthly budget is required.";
  } else if (!numbersCheck.test(values.monthlyPrice)) {
    errors.monthlyPrice = "Monthly budget should only contain numbers";
  }

  return errors;
};

//-------Add User validation schema
export const addUserValidationSchema = (values) => {
  let errors = {};

  if (!values.role) {
    errors.role = "Role is required";
  }

  if (!values.firstName) {
    errors.firstName = "First Name is required";
  }

  if (!values.lastName) {
    errors.lastName = "Last Name is required";
  }

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "Invalid Email Format";
  }
  if (values.password.length < 4 || values.password.length > 6) {
    errors.password = "Pin length must be between 4 and 6";
  } else if (!onlyNumbersCheck.test(values.password)) {
    errors.password = "Pin can only contain Numbers";
  }
  if (values.role === ROLES.EMPLOYEE) {
    if (!values.stores.length > 0 || !values.stores || !values.stores) {
      errors.stores = "Select atleast one store";
    }
  }
  return errors;
};

//-------Add User validation schema
export const addUserWithPasswordValidationSchema = (values) => {
  let errors = {};

  if (!values.role) {
    errors.role = "Role is required";
  }

  if (!values.firstName) {
    errors.firstName = "First Name is required";
  }

  if (!values.lastName) {
    errors.lastName = "Last Name is required";
  }

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "Invalid Email Format";
  }
  if (values.password.length < 4 || values.password.length > 6) {
    errors.password = "Pin length must be between 4 and 6";
  } else if (!onlyNumbersCheck.test(values.password)) {
    errors.password = "Pin can only contain Numbers";
  }
  if (values.role === ROLES.EMPLOYEE) {
    if (!values.stores.length > 0 || !values.stores || !values.stores) {
      errors.stores = "Select atleast one store";
    }
  }

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "Confirm Pin not matched";
  }
  return errors;
};

//-------Add New Payment Device schema
export const addPaymentDeviceSchema = (values) => {
  let errors = {};

  if (!values.serialNumber) {
    errors.serialNumber = "Serial Number is required.";
  }

  if (!values.poiId) {
    errors.poiId = "Nickname is required.";
  }

  if (!values.store) {
    errors.store = "Store is required.";
  }

  return errors;
};
export const addStoreValidationSchema = (values) => {
  let errors = {};
  if (!values.storeName) {
    errors.storeName = "Store Name is required.";
  }
  if (!values.line1) {
    errors.line1 = "Address is required.";
  }
  if (!values.city) {
    errors.city = "City is required.";
  }
  if (!values.state) {
    errors.state = "State is required.";
  }
  if (!values.zip) {
    errors.zip = "Zip code is required.";
  } else if (!onlyNumbersCheck.test(values.zip)) {
    errors.zip = "Zip code should only contain numbers";
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = "Phone Number is required.";
  } else {
    if (!phoneRegex.test(values.phoneNumber)) {
      errors.phoneNumber = "Invalid Phone Number.";
    }
  }

  if (!values.defaultTax) {
    errors.defaultTax = "Defaut Tax is required.";
  }
  if (!values.billingAmount) {
    errors.billingAmount = "Billing Amount is required.";
  }
  if (!values.companyId) {
    errors.companyId = "Company is required.";
  }
  return errors;
};
export const addStoreAsCompanyValidationSchema = (values) => {
  let errors = {};
  if (!values.storeName) {
    errors.storeName = "Store Name is required.";
  }
  if (!values.line1) {
    errors.line1 = "Address is required.";
  }
  if (!values.city) {
    errors.city = "City is required.";
  }
  if (!values.state) {
    errors.state = "State is required.";
  }
  if (!values.zip) {
    errors.zip = "Zip code is required.";
  } else if (!onlyNumbersCheck.test(values.zip)) {
    errors.zip = "Zip code should only contain numbers";
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = "Phone Number is required.";
  } else {
    if (!phoneRegex.test(values.phoneNumber)) {
      errors.phoneNumber = "Invalid Phone Number.";
    }
  }

  if (!values.defaultTax) {
    errors.defaultTax = "Defaut Tax is required.";
  }
  if (!values.billingAmount) {
    errors.billingAmount = "Billing Amount is required.";
  }
  if (!values.password) {
    errors.password = "Password is required.";
  } else if (!checkSpace.test(values.password)) {
    errors.password = "Do not enter space in password";
  } else if (!uppercaseCheck.test(values.password)) {
    errors.password = "Password should contain atleast one Capital Letter";
  } else if (!numbersCheck.test(values.password)) {
    errors.password = "Password should contain numbers";
  } else if (!specialCharacterCheck.test(values.password)) {
    errors.password = "Password should contain atleast one special character";
  }

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "Invalid Email Format";
  }

  return errors;
};
//-------Update User validation schema
export const updateUserValidationSchema = (values) => {
  let errors = {};

  if (!values.role) {
    errors.role = "Role is required";
  }

  if (!values.firstName) {
    errors.firstName = "First Name is required";
  }

  if (!values.lastName) {
    errors.lastName = "Last Name is required";
  }

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "Invalid Email Format";
  }
  return errors;
};

//-------Update User validation schema
export const updateUserWithPasswordValidationSchema = (values) => {
  let errors = {};

  if (!values.role) {
    errors.role = "Role is required";
  }

  if (!values.firstName) {
    errors.firstName = "First Name is required";
  }

  if (!values.lastName) {
    errors.lastName = "Last Name is required";
  }

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "Invalid Email Format";
  }

  if (!values.password) {
    errors.password = "Password is required.";
  } else if (!checkSpace.test(values.password)) {
    errors.password = "Do not enter space in password";
  } else if (!uppercaseCheck.test(values.password)) {
    errors.password = "Password should contain atleast one Capital Letter";
  } else if (!numbersCheck.test(values.password)) {
    errors.password = "Password should contain numbers";
  } else if (!specialCharacterCheck.test(values.password)) {
    errors.password = "Password should contain atleast one special character";
  }

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "Confirm Password not matched";
  }

  return errors;
};

//-------Add User validation schema
export const addSystemUserValidationSchema = (values) => {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = "First Name is required";
  }

  if (!values.lastName) {
    errors.lastName = "Last Name is required";
  }

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "Invalid Email Format";
  }

  if (!values.password) {
    errors.password = "Password is required";
  } else if (!checkSpace.test(values.password)) {
    errors.password = "Do not enter space in password";
  } else if (!uppercaseCheck.test(values.password)) {
    errors.password = "Password should contain atleast one Capital Letter";
  } else if (!numbersCheck.test(values.password)) {
    errors.password = "Password should contain numbers";
  } else if (!specialCharacterCheck.test(values.password)) {
    errors.password = "Password should contain atleast one special character";
  }
  return errors;
};

//-------Add User validation schema
export const updateSystemUserValidationSchema = (values) => {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = "First Name is required";
  }

  if (!values.lastName) {
    errors.lastName = "Last Name is required";
  }

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "Invalid Email Format";
  }

  return errors;
};

//-------Add User validation schema
export const updateSystemUserWithPasswordValidationSchema = (values) => {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = "First Name is required";
  }

  if (!values.lastName) {
    errors.lastName = "Last Name is required";
  }

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "Invalid Email Format";
  }

  if (!values.password) {
    errors.password = "Password is required.";
  } else if (!checkSpace.test(values.password)) {
    errors.password = "Do not enter space in password";
  } else if (!uppercaseCheck.test(values.password)) {
    errors.password = "Password should contain atleast one Capital Letter";
  } else if (!numbersCheck.test(values.password)) {
    errors.password = "Password should contain numbers";
  } else if (!specialCharacterCheck.test(values.password)) {
    errors.password = "Password should contain atleast one special character";
  }

  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "Confirm Password not matched";
  }

  return errors;
};

//-------Update User validation schema
export const addReceiptLogoValidationSchema = (values) => {
  let errors = {};

  if (!values.returnPolicy) {
    errors.returnPolicy = "Refund Policy is required.";
  }

  return errors;
};

//-------Update User Ino validation schema
export const updateUserInfoValidationSchema = (values) => {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = "First Name is required";
  }

  if (!values.lastName) {
    errors.lastName = "Last Name is required";
  }

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "Invalid Email Format";
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = "Phone Number is required.";
  } else {
    if (!phoneRegex.test(values.phoneNumber)) {
      errors.phoneNumber = "Invalid Phone Number.";
    }
  }

  return errors;
};

//-------Update User password validation schema
export const updateUserPasswordSchema = (values) => {
  let errors = {};

  if (!values.password) {
    errors.password = "Password is required";
  } else if (!checkSpace.test(values.password)) {
    errors.password = "Do not enter space in password";
  } else if (!uppercaseCheck.test(values.password)) {
    errors.password = "Password should contain atleast one Capital Letter";
  } else if (!numbersCheck.test(values.password)) {
    errors.password = "Password should contain numbers";
  } else if (!specialCharacterCheck.test(values.password)) {
    errors.password = "Password should contain atleast one special character";
  }

  if (!values.oldPassword) {
    errors.oldPassword = "Old Password is required.";
  }

  if (!(values.password === values.repeatPassword)) {
    errors.repeatPassword = "Repeat password not matched";
  }

  return errors;
};

//-------Update User password validation schema
export const updateUserPinSchema = (values) => {
  let errors = {};

  if (values.password.length < 4 || values.password.length > 6) {
    errors.password = "Pin length must be between 4 and 6";
  } else if (!onlyNumbersCheck.test(values.password)) {
    errors.password = "Pin can only contain Numbers";
  }

  if (!values.oldPassword) {
    errors.oldPassword = "Old Pin is required.";
  }

  if (!(values.password === values.repeatPassword)) {
    errors.repeatPassword = "Repeat Pin not matched";
  }

  return errors;
};

//-------Edit Inventory validation schema
export const editInventoryValidationSchema = (values) => {
  let errors = {};
  // product name, category, condition validation
  if (!values.productName && values.productName === "") {
    errors.productName = "Product Name is required";
  }
  if (!values.category && values.category === "") {
    errors.category = "Category is required";
  }
  if (!values.condition && values.condition === "") {
    errors.condition = "Condition is required";
  }
  // Marketing Price Validation
  if (!values.unitsellprice && values.unitsellprice === "") {
    errors.unitsellprice = "Instock Price is required";
  } else if (isNaN(values.unitsellprice) || values.unitsellprice < 0) {
    errors.unitsellprice = "Invalid Marketing Price";
  }

  // Cost Per Item Validation
  if (!values.costPerItem && values.costPerItem === "") {
    errors.costPerItem = "Cost Per Item is required";
  } else if (isNaN(values.costPerItem) || values.costPerItem < 0) {
    errors.costPerItem = "Invalid Cost Per Item";
  }

  // Profit Validation
  if (!values.profit && values.profit === "") {
    errors.profit = "Profit is required";
  } else if (isNaN(values.profit)) {
    errors.profit = "Invalid Profit";
  }

  // Margin Validation
  if (!values.margin && values.margin === "") {
    errors.margin = "Margin is required";
  } else if (isNaN(values.margin)) {
    errors.margin = "Invalid Margin";
  }

  // Units Online Validation
  // if (!values.unitsOnline) {
  //   errors.unitsOnline = "Units Online is required";
  // } else if (
  //   isNaN(values.unitsOnline) ||
  //   values.unitsOnline < 0 ||
  //   !Number.isInteger(values.unitsOnline)
  // ) {
  //   errors.unitsOnline = "Invalid Units Online";
  // }

  // Units On Hand Validation
  if (!values.unitsOnHand && values.unitsOnHand === "") {
    errors.unitsOnHand = "Units On-Hand is required";
  }

  return errors;
};

export const addCustomerValidationSchema = (values) => {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = "First Name is required";
  }

  if (!values.lastName) {
    errors.lastName = "Last Name is required";
  }

  if (values.email) {
    if (!emailRegex.test(values.email)) {
      errors.email = "Invalid Email Format";
    }
  }

  // else if (!values.mobile) {
  //   errors.email = "Email is required if phone number is not provided.";
  // }

  if (values.mobile) {
    if (!phoneRegex.test(values.mobile)) {
      errors.mobile = "Invalid Phone Number.";
    }
  }
  //  else if (!values.email) {
  //   errors.mobile = "Phone Number is required if email is not provided.";
  // }

  // if (!values.driverLicense) {
  //   errors.driverLicense = "Driver License is required";
  // }

  // if (!values.line1) {
  //   errors.line1 = "Street 1 is required";
  // }
  // if (!values.street2) {
  //   errors.street2 = "Street 1 is required";
  // }

  // if (!values.city) {
  //   errors.city = "City is required.";
  // }
  // if (!values.state) {
  //   errors.state = "State is required.";
  // }

  // if (!values.zip) {
  //   errors.zip = "Zip code is required.";
  // } else
  // if (!onlyNumbersCheck.test(values.zip)) {
  //   errors.zip = "Zip code should only contain numbers";
  // }

  if (
    values.currentBalance === undefined ||
    values.currentBalance === null ||
    values.currentBalance === ""
  ) {
    errors.currentBalance = "Current Balance is required";
  } else if (
    isNaN(values.currentBalance) ||
    parseFloat(values.currentBalance) < 0
  ) {
    errors.currentBalance =
      "Current Balance should only contain non-negative numbers";
  }

  return errors;
};

//-------add printer validation schema
export const addPrinterValidationSchema = (values) => {
  let errors = {};

  if (!values.selectedPrinter) {
    errors.selectedPrinter = "Printer is required";
  }

  if (!values.printerName) {
    errors.printerName = "Printer name is required";
  }
  return errors;
};

//-------Update User validation schema
export const addNewIventoryValidationSchema = (
  values,
  isSerialNumberRequired
) => {
  let errors = {};

  if (!values.quantity || values.quantity === "0") {
    errors.quantity = "quantity is required.";
  }
  if (!values.costOfGoods) {
    errors.costOfGoods = "COGS is required.";
  }
  if (!values.inStockPrice) {
    errors.inStockPrice = "Price is required.";
  }
  if (isSerialNumberRequired && !values.serialNumber) {
    errors.serialNumber = "Serial Number is required.";
  }
  return errors;
};

export const addNewTradeValidationSchema = (values, isSerialNumberRequired) => {
  let errors = {};

  if (!values.quantity) {
    errors.quantity = "Quantity is required.";
  }
  if (!values.inStockPrice) {
    errors.inStockPrice = "Price is required.";
  }
  if (!values.cashOffer) {
    errors.cashOffer = "Cash offer is required.";
  } else if (!numbersCheck.test(values.cashOffer)) {
    errors.cashOffer = "Cash offer should only contain numbers";
  }
  if (!values.tradeOffer) {
    errors.tradeOffer = "Trade offer is required.";
  } else if (!numbersCheck.test(values.tradeOffer)) {
    errors.tradeOffer = "Trade offer should only contain numbers";
  }
  if (isSerialNumberRequired && !values.serialNumber) {
    errors.serialNumber = "Serial Number is required.";
  }
  return errors;
};

export const addCustomItemValidationSchema = (values) => {
  let errors = {};

  if (!values.title) {
    errors.title = "Title is required.";
  }

  if (!values.condition) {
    errors.condition = "Condition is required.";
  }

  if (!values.quantity || values.quantity === "0") {
    errors.quantity = "Quantity is required.";
  }

  if (
    !values.stockPrice ||
    values.stockPrice === "0" ||
    values.stockPrice === "0.00"
  ) {
    errors.stockPrice = "Stock Price is required.";
  }

  if (!values.type) {
    errors.type = "Type is required.";
  }

  if (!values.category) {
    errors.category = "Category is required.";
  }

  return errors;
};

export const addSaleCustomItemValidationSchema = (values) => {
  let errors = {};

  if (!values.itemName) {
    errors.itemName = "Title is required.";
  }

  if (!values.quantity || values.quantity === "0") {
    errors.quantity = "Quantity is required.";
  }

  if (!values.price || values.price === "0" || values.price === "0.00") {
    errors.price = "Sell Price is required.";
  }

  if (!values.productType) {
    errors.productType = "Type is required.";
  }

  return errors;
};

export const addTradeCustomItemValidationSchema = (values) => {
  let errors = {};

  if (!values.product_name) {
    errors.product_name = "Item Name is required.";
  }
  if (!values.quantity || !Number(values.quantity)) {
    errors.quantity = "Quantity is required.";
  }
  if (!values.cashOffer) {
    errors.cashOffer = "Cash offer is required.";
  } else if (!numbersCheck.test(values.cashOffer)) {
    errors.cashOffer = "Cash offer should only contain numbers";
  }
  if (!values.tradeOffer) {
    errors.tradeOffer = "Trade offer is required.";
  } else if (!numbersCheck.test(values.tradeOffer)) {
    errors.tradeOffer = "Trade offer should only contain numbers";
  }
  if (!values.inStockPrice || !Number(values.inStockPrice)) {
    errors.inStockPrice = "Price is required.";
  }
  if (!values.productType) {
    errors.productType = "Product Type is required.";
  }

  if (!values.category_name) {
    errors.category_name = "Category is required.";
  }
  if (!values.condition) {
    errors.condition = "Condition is required.";
  }

  return errors;
};

export const addQuickTradeInValidationSchema = (values) => {
  let errors = {};

  if (!values.cashOffer) {
    errors.cashOffer = "Cash offer is required.";
  } else if (!numbersCheck.test(values.cashOffer)) {
    errors.cashOffer = "Cash offer should only contain numbers";
  }
  if (!values.tradeOffer) {
    errors.tradeOffer = "Trade offer is required.";
  } else if (!numbersCheck.test(values.tradeOffer)) {
    errors.tradeOffer = "Trade offer should only contain numbers";
  }
  if (!values.totalItems) {
    errors.totalItems = "Total Items is required.";
  }
  return errors;
};
export const addGiftCardValidationSchema = (values) => {
  let errors = {};

  if (!values.amountToLoad || !Number(values.amountToLoad)) {
    errors.amountToLoad = "Amount is required.";
  }
  return errors;
};

export const paymentPrintValidationSchema = (values) => {
  let errors = {};

  if (!values.printQuantity) {
    errors.printQuantity = "Print quantity is required";
  }

  if (!values.itemTitle) {
    errors.itemTitle = "Item title is required";
  }
  if (!values.printerType) {
    errors.printerType = "Printer name is required";
  }
  return errors;
};

//-------Scan Gift Card Barcode validation schema
export const scanGiftCardBarcodeValidationSchema = (values) => {
  let errors = {};

  if (!values.GiftCardBarcode) {
    errors.GiftCardBarcode = "Gift Card Barcode is required.";
  }
  return errors;
};

export const tradeInsModalWithOutCheckValidationSchema = (values) => {
  let errors = {};

  if (!values.cashOffer) {
    errors.cashOffer = "Cash offer is required.";
  } else if (!numbersCheck.test(values.cashOffer)) {
    errors.cashOffer = "Cash offer should only contain numbers";
  }

  if (!values.tradeOffer) {
    errors.tradeOffer = "Trade offer is required.";
  } else if (!numbersCheck.test(values.tradeOffer)) {
    errors.tradeOffer = "Trade offer should only contain numbers";
  }

  // Ensure you're using the correct keys for comparison and error messaging
  // if (
  //   onlyNumbersCheck.test(values.cashOffer) &&
  //   onlyNumbersCheck.test(values.tradeOffer)
  // ) {
  //   const cashOfferValue = Number(values.cashOffer);
  //   const tradeOfferValue = Number(values.tradeOffer);

  //   // Ensure error message key matches the field being validated
  //   if (cashOfferValue <= tradeOfferValue) {
  //     errors.cashOffer = "Cash should be greater.";
  //   }
  // }

  return errors;
};

export const markupModalWithOutCheckValidationSchema = (values) => {
  let errors = {};

  if (!Number(values.markupPercentage)) {
    errors.markupPercentage = "Percentage Markup is required.";
  }

  return errors;
};

//------- modal tradeIns validation schema
export const tradeInsModalValidationSchema = (values) => {
  let errors = {};

  // Cash Offer validation
  if (!values.cashOffer) {
    errors.cashOffer = "Cash offer is required.";
  } else if (!numbersCheck.test(values.cashOffer)) {
    errors.cashOffer = "Cash offer should only contain numbers";
  }

  // Trade Offer validation
  if (!values.tradeOffer) {
    errors.tradeOffer = "Trade offer is required.";
  } else if (!numbersCheck.test(values.tradeOffer)) {
    errors.tradeOffer = "Trade offer should only contain numbers";
  }

  // Min Price validation
  if (values.minPrice === undefined) {
    // Checks for undefined specifically, allowing zero
    errors.minPrice = "Min Price is required.";
  } else if (!numbersCheck.test(values.minPrice)) {
    errors.minPrice = "Min Price should only contain numbers";
  }

  // Max Price validation
  if (!values.maxPrice) {
    errors.maxPrice = "Max Price is required.";
  } else if (!numbersCheck.test(values.maxPrice)) {
    errors.maxPrice = "Max Price should only contain numbers";
  }

  // Additional checks
  if (
    numbersCheck.test(values.minPrice) &&
    numbersCheck.test(values.maxPrice)
  ) {
    const minPriceValue = Number(values.minPrice);
    const maxPriceValue = Number(values.maxPrice);

    // Check to ensure maxPrice is greater than minPrice
    if (minPriceValue > maxPriceValue) {
      errors.maxPrice = "Max Price should be greater than Min Price.";
    }
  }

  return errors;
};

//------- modal tradeIns validation schema
export const markupModalValidationSchema = (values) => {
  let errors = {};

  // Cash Offer validation
  if (!Number(values.markupPercentage)) {
    errors.markupPercentage = "Percentage Markup is required.";
  }

  // Min Price validation
  if (values.minPrice === undefined) {
    // Checks for undefined specifically, allowing zero
    errors.minPrice = "Min Price is required.";
  }

  // Max Price validation
  if (!values.maxPrice) {
    errors.maxPrice = "Max Price is required.";
  }

  // Additional checks
  else if (
    numbersCheck.test(values.minPrice) &&
    numbersCheck.test(values.maxPrice)
  ) {
    const minPriceValue = Number(values.minPrice);
    const maxPriceValue = Number(values.maxPrice);

    // Check to ensure maxPrice is greater than minPrice
    if (minPriceValue >= maxPriceValue) {
      errors.maxPrice = "Max Price should be greater than Min Price.";
    }
  }

  return errors;
};

export const discountValidationSchema = (values) => {
  let errors = {};

  // Description Validation
  if (!values.description) {
    errors.description = "Description is required.";
  }

  // Discount Value Validation
  if (!values.discountPercentage) {
    errors.discountPercentage = "Discount Value is required.";
  } else if (
    isNaN(values.discountPercentage) ||
    Number(values.discountPercentage) <= 0
  ) {
    errors.discountPercentage =
      "Please enter a valid discount value greater than 0.";
  } else if (Number(values.discountPercentage) > 100) {
    errors.discountPercentage =
      "Please enter a valid discount value less than or equal to 100.";
  }

  // Min Price validation
  if (values.minPrice === undefined) {
    // Checks for undefined specifically, allowing zero
    errors.minPrice = "Min Price is required.";
  }

  // Max Price validation
  if (!values.maxPrice) {
    errors.maxPrice = "Max Price is required.";
  }

  // Additional checks
  else if (
    numbersCheck.test(values.minPrice) &&
    numbersCheck.test(values.maxPrice)
  ) {
    const minPriceValue = Number(values.minPrice);
    const maxPriceValue = Number(values.maxPrice);

    // Check to ensure maxPrice is greater than minPrice
    if (minPriceValue >= maxPriceValue) {
      errors.maxPrice = "Max Price should be greater than Min Price.";
    }
  }
  return errors;
};
export const discountWithoutRangeValidationSchema = (values) => {
  let errors = {};

  // Description Validation
  if (!values.description) {
    errors.description = "Description is required.";
  }

  // Discount Value Validation
  if (!values.discountPercentage) {
    errors.discountPercentage = "Discount Value is required.";
  } else if (
    isNaN(values.discountPercentage) ||
    Number(values.discountPercentage) <= 0
  ) {
    errors.discountPercentage =
      "Please enter a valid discount value greater than 0.";
  } else if (Number(values.discountPercentage) > 100) {
    errors.discountPercentage =
      "Please enter a valid discount value less than or equal to 100.";
  }

  return errors;
};

export const importUserValidationSchema = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};

export const addCustomTypeValidationSchema = (values) => {
  const errors = {};
  if (!values.customType) {
    errors.customType = "Type is Required";
  }
  return errors;
};

export const addCustomCategoryValidationSchema = (values) => {
  const errors = {};
  if (!values.type) {
    errors.type = "Type is Required";
  }
  if (!values.categoryName) {
    errors.categoryName = "Category is Required";
  }
  return errors;
};

export const addCustomSubCategoryValidationSchema = (values) => {
  const errors = {};
  if (!values.type) {
    errors.type = "Type is Required";
  }

  if (!values.categoryName) {
    errors.categoryName = "Category is Required";
  }

  if (!values.subCategoryName) {
    errors.subCategoryName = "Category is Required";
  }
  return errors;
};

export const addCompanyValidationSchema = (values) => {
  let errors = {};
  if (!values.companyName) {
    errors.companyName = "Company Name is required.";
  }

  if (values.phoneNumber && !phoneRegex.test(values.phoneNumber)) {
    errors.phoneNumber = "Invalid Phone Number.";
  }

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "Invalid Email Format";
  }
  if (!values.password) {
    errors.password = "Password is required";
  } else if (!checkSpace.test(values.password)) {
    errors.password = "Do not enter space in password";
  } else if (!uppercaseCheck.test(values.password)) {
    errors.password = "Password should contain atleast one Capital Letter";
  } else if (!numbersCheck.test(values.password)) {
    errors.password = "Password should contain numbers";
  } else if (!specialCharacterCheck.test(values.password)) {
    errors.password = "Password should contain atleast one special character";
  }

  return errors;
};
