import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import qz from "qz-tray";

import Topbar from "../../shared/components/topbar/Topbar";
import { printerActions } from "../../redux/actions";
import Button from "../../shared/components/Buttons/Button";
import {
  toastType,
  toastMessages,
  printerConstants,
  buttonNameConstants,
  buttonTypeConstants,
} from "../../constants";
import {
  connectToQZTray,
  disconnectFromQZTray,
  printTestReceiptData,
} from "../../shared/Printers/ReceiptPrinters/printReceipt";
import { customToast, generateUuid } from "../../shared/utility";
import {
  testCashDrawer,
  testReceiptPrintDesign,
} from "../../shared/Printers/ReceiptPrinters/testPrintDesign";
import { testZebraPrintDesign } from "../../shared/Printers/ZebraPrinter/testZebraPrintDesign";
import PirnterDropdown from "../../shared/components/selectDropdown/PirnterDropdown";
import {
  setItemToLocalStorage,
  getItemFromLocalStorage,
} from "../../system/storage";

const PrinterSettings = (props) => {
  const { currentStore, printer, addNewPrinter } = props;

  //------printer reducer
  const { defaultZebraPrinter, defaultReceiptPrinter } = printer;

  //-------usestate
  const [allSystemAvailablePrinters, setAllSystemAvailableReceiptPrinters] =
    useState([]);

  //-------get all receipt printers
  const getAllReceiptPrinters = () => {
    connectToQZTray().then(async () => {
      await qz.printers
        .find()
        .then((printers) => {
          const printerList = printers.map((printer, index) => {
            return { id: index + 1, printerName: printer };
          });
          setAllSystemAvailableReceiptPrinters(printerList);
          disconnectFromQZTray();
        })
        .catch((error) => {
          customToast(error, toastType.ERROR);
        });
    });
  };

  //-------handle add print
  const handleAddPrinterSubmit = (values, printerType) => {
    let localDeviceIp = getItemFromLocalStorage(
      printerConstants.LOCAL_DEVICE_IP
    );
    if (!localDeviceIp) {
      localDeviceIp = generateUuid();
      setItemToLocalStorage(printerConstants.LOCAL_DEVICE_IP, localDeviceIp);
    }

    if (currentStore) {
      const data = {
        printerName: values.label,
        printerType: printerType,
        isDefault: true,
        localDeviceIp,
        generalSettings: {
          vendorId: "NoNeed",
          productId: "NoNeed",
          productName: values.label,
          serialNumber: "NoNeed",
          manufacturerName: "NoNeed",
          DeviceType: "NoNeed",
        },
        store: {
          id: currentStore?.id,
          name: currentStore?.storeName,
        },
      };
      addNewPrinter(data, currentStore?.id);
    }
  };

  //-------handle test receipt print
  const handleReceiptTestPrint = () => {
    if (defaultReceiptPrinter) {
      printTestReceiptData(
        defaultReceiptPrinter.printerName,
        testReceiptPrintDesign()
      );
    } else {
      customToast(
        toastMessages.PLEASE_SET_DEFAULT_RECEIPT_PRINTER,
        toastType.ERROR
      );
    }
  };

  //-------Test Cash Drawer Open
  const handleTestCashDrawerClick = () => {
    if (defaultReceiptPrinter) {
      printTestReceiptData(defaultReceiptPrinter.printerName, testCashDrawer());
    } else {
      customToast(
        toastMessages.PLEASE_SET_DEFAULT_RECEIPT_PRINTER,
        toastType.ERROR
      );
    }
  };

  //-------handle test zebra print
  const handleZebraTestPrint = () => {
    if (defaultZebraPrinter) {
      printTestReceiptData(
        defaultZebraPrinter.printerName,
        testZebraPrintDesign
      );
    } else {
      customToast(
        toastMessages.PLEASE_SET_DEFAULT_ZEBRA_PRINTER,
        toastType.ERROR
      );
    }
  };

  useEffect(() => {
    getAllReceiptPrinters();
  }, [currentStore]);

  return (
    <>
      <Topbar title="Printer Settings"></Topbar>
      <Row className="m-0">
        <Col md={9} className="d-flex align-items-center gap-3 mb-4">
          <Row className="m-0 w-100">
            <Col md={2} className="d-flex align-items-center">
              <span className="text-nowrap">Receipt Printer</span>
            </Col>
            <Col md={6} className="d-flex align-items-center">
              <PirnterDropdown
                printerType="Receipt"
                isSearchable={false}
                defaultPrinter={defaultReceiptPrinter}
                placeholder={"Select Receipt Printer..."}
                options={allSystemAvailablePrinters?.map((printer) => {
                  return {
                    value: printer?.id,
                    label: printer?.printerName,
                  };
                })}
                handleChange={(e) =>
                  handleAddPrinterSubmit(e, printerConstants.RECEIPT_PRINTER)
                }
              />
            </Col>
            <Col md={2} className="d-flex align-items-center">
              <Button
                className={"text-start"}
                label={buttonNameConstants.PRINT_TEST_RECEIPT}
                buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
                handleClick={handleReceiptTestPrint}
              />
            </Col>{" "}
            <Col md={2} className="d-flex align-items-center">
              <Button
                className={"text-start"}
                label={buttonNameConstants.TEST_CASH_DRWAER}
                buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
                handleClick={handleTestCashDrawerClick}
              />
            </Col>
          </Row>
        </Col>
        <Col md={9} className="d-flex align-items-center gap-3">
          <Row className="m-0 w-100">
            <Col md={2} className="d-flex align-items-center">
              <span className="text-nowrap">Label Printer</span>
            </Col>
            <Col md={6} className="d-flex align-items-center">
              <PirnterDropdown
                printerType="Label"
                isSearchable={false}
                defaultPrinter={defaultZebraPrinter}
                placeholder={"Select Label Printer..."}
                options={allSystemAvailablePrinters?.map((printer) => {
                  return {
                    value: printer?.id,
                    label: printer?.printerName,
                  };
                })}
                handleChange={(e) =>
                  handleAddPrinterSubmit(e, printerConstants.ZEBRA_PRINTER)
                }
              />
            </Col>
            <Col md={2} className="d-flex align-items-center">
              <Button
                className={"text-start"}
                label={buttonNameConstants.PRINT_TEST_LABEL}
                buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
                handleClick={handleZebraTestPrint}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  printer: state.printer,
  currentStore: state.store.currentStore,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  addNewPrinter: (data, storeId) =>
    dispatch(printerActions.addNewPrinter(data, storeId)),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(PrinterSettings);
