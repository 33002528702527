import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";

import { systemActions } from "../../redux/actions";
import { globalConstants } from "../../constants";

// this component enables us to use react-router useNavigation hook outside .jsx files
const PageRedirection = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (props.isRedirect) {
      navigate(props.pageToRedirect);
      props.pageRedireaction(globalConstants.EMPTY_STRING);
    } // eslint-disable-next-line
  }, [props.isRedirect]);
  return <> </>;
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  isRedirect: state.system.isRedirect,
  pageToRedirect: state.system.pageToRedirect,
  isLoading: state.system.isLoading,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  pageRedireaction: (value, path) =>
    dispatch(systemActions.pageRedireaction(value, path)),
});

//-------Export PageRedireaction Component
export default connect(mapStateToProps, mapDispatchToProps)(PageRedirection);
