export const testReceiptPrintDesign = () => {
  const dateTime = new Date().toLocaleString();
  return [
    "\x1B" + "\x40", // init
    "\x1B" + "\x61" + "\x31", // center align
    "\x1B" + "\x21" + "\x30", // em mode on
    `Test Print`,
    "\x1B" + "\x21" + "\x0A" + "\x1B" + "\x45" + "\x0A", // em mode off
    "\x0A", //Line Break
    "\x0A", //Line Break
    `${dateTime}` + "\x0A" + "\x0A",
    "\x1D\x68\x64", // Set barcode height (104 dots)
    "\x1D\x77\x02", // Set barcode width (module 2)
    `\x1D\x6B\x45\x0BTESTRECEIPT\x00` + "\x0A",
    `TEST RECEIPT` + "\x0A",
    "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A",
    "\x1B" + "\x69", // cut paper (old syntax)
    //"\x1D" + "\x56" + "\x01", // partial cut (new syntax)
  ];
};

export const testCashDrawer = () => {
  return [
    "\x10" + "\x14" + "\x01" + "\x00" + "\x05", // Generate Pulse to kick-out cash drawer**
  ];
};
