import { connect } from "react-redux";
import React from "react";
import { Row, Col } from "react-bootstrap";

import { storeService } from "../../services";
import {
  customToast,
  parseToThousandSeperatorDecimalNumber,
} from "../../shared/utility";
import Topbar from "../../shared/components/topbar/Topbar";
import Button from "../../shared/components/Buttons/Button";
import {
  buttonNameConstants,
  storeConstants,
  toastType,
} from "../../constants";
import PaymentMethods from "../../assets/images/paymentMethods.svg";
import { systemActions } from "../../redux/actions/system.action";

const Billing = ({ currentStore, activateSpinner, deactivateSpinner }) => {
  const handleSubscriptionButtonClick = async () => {
    activateSpinner(storeConstants.SUBSCRIBE);
    await storeService.storeSubscriptionPayment(currentStore?.id).then(
      (response) => {
        window.location.replace(response);
      },
      (error) => {
        customToast(error, toastType.ERROR);
      }
    );
    deactivateSpinner(storeConstants.SUBSCRIBE);
  };

  return (
    <>
      <Row className="m-0 d-flex justify-content-center w-100 ">
        <Col md={11}>
          <Topbar title="Billing (Subscription)"></Topbar>
        </Col>
      </Row>
      <Row className=" d-flex justify-content-center align-items-center">
        <Col md={11}>
          <Col
            md={4}
            className="d-flex flex-column justify-content-center align-items-center billing-card-wrapper gap-3"
          >
            <span>CollectPOS Subscription</span>
            <div className="billing-card-price-wrapper">
              <span>
                $
                {parseToThousandSeperatorDecimalNumber(
                  currentStore?.billingAmount || 0
                )}
              </span>
              <span>
                per <br />
                {currentStore?.billingType ===
                storeConstants.BILLING_TYPE[0].value
                  ? "month"
                  : "year"}
              </span>
            </div>
            <Button
              type="button"
              label={buttonNameConstants.SUBSCRIBE}
              className={"w-100 mt-2"}
              handleClick={() => handleSubscriptionButtonClick()}
              isDisabled={currentStore?.isSubscribed ?? false}
            />
            <div className="payments-methods-wrapper">
              <span className="paymnet-mmethod-heading">
                Supported payment methods:
              </span>
              <img src={PaymentMethods} alt="" />
            </div>
          </Col>
        </Col>
      </Row>
    </>
  );
};

// reducer
const mapStateToProps = (state) => ({
  currentStore: state.store.currentStore,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(Billing);
