import React from "react";
import { Row, Col } from "react-bootstrap";

import SimpleNumberField from "../../../../shared/components/inputNumberField/SimpleNumberField";
import Button from "../../../../shared/components/Buttons/Button";
import {
  transactionConstants,
  buttonNameConstants,
  buttonTypeConstants,
} from "../../../../constants";
import { DollarIcon } from "../../../../assets/icons/iconsProvider";
import { parseToThousandSeperatorDecimalNumber } from "../../../../shared/utility";

const CashPayment = ({
  changeOwed,
  amountToPay,
  setChangeOwed,
  amountTendered,
  transactionData,
  setPaymentMethod,
  setAmountTendered,
  handleCashPayment,
  handleAmountTenderedChange,
  handleFixedAmountButtonClick,
}) => {
  return (
    <div className="payment-cash-card-wrapper">
      <Row className="m-0">
        <Col
          md={12}
          className="d-flex justify-content-between align-items-center"
        >
          <span className="cash-heading">Cash</span>
        </Col>
        <Col md={12} className="mt-3">
          <div className="receipt-total-wrapper d-flex flex-column gap-1">
            <span>Payment Amount</span>
            <span>
              $
              {parseToThousandSeperatorDecimalNumber(
                transactionData.currentAmountToPay
              )}
            </span>
          </div>
        </Col>
        <Col md={12} className="mt-3">
          <div className="receipt-total-wrapper d-flex flex-column gap-1">
            <span>Amount Tendered</span>{" "}
            <SimpleNumberField
              className="discount-number-field"
              value={amountTendered}
              onChange={handleAmountTenderedChange}
              IconImage={DollarIcon}
              isConvertToDecimal={true}
            />
          </div>
        </Col>
        <Col md={12} className="mt-3">
          <div className="d-flex gap-2 align-items-center">
            {transactionConstants.FIXED_AMOUNT_BUTTONS.map((button) => (
              <Button
                label={`$${button}`}
                buttonType={buttonTypeConstants.GHOST_BUTTON}
                className="fixed-amount-button"
                handleClick={() => handleFixedAmountButtonClick(button)}
              />
            ))}
          </div>
        </Col>
        <Col md={12} className="mt-3">
          <div className="receipt-total-wrapper d-flex flex-column gap-1">
            <span>Change Owed</span>
            <span>${parseToThousandSeperatorDecimalNumber(changeOwed)}</span>
          </div>
        </Col>
        <Col md={12} className="mb-3 d-flex gap-2 mt-3">
          <Button
            type="button"
            label={buttonNameConstants.BACK}
            handleClick={() => {
              setPaymentMethod("");
              setAmountTendered(0);
              setChangeOwed(0);
            }}
            buttonType={buttonTypeConstants.GHOST_BUTTON}
            className="w-100"
          ></Button>
          <Button
            type="button"
            className="w-100"
            label={buttonNameConstants.COMPLETE}
            handleClick={() => handleCashPayment()}
            isDisabled={
              Number(amountTendered) >= Number(amountToPay) ? false : true
            }
          ></Button>
        </Col>
      </Row>
    </div>
  );
};

export default CashPayment;
