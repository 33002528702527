// NotificationDropdown.js
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useDetectClickOutside } from "react-detect-click-outside";

import {
  CopyIcon,
  NotificationIcon,
  TickIcon,
} from "../../../assets/icons/iconsProvider";
import { storeActions } from "../../../redux/actions/store.action";
import ProductImage from "../../../assets/images/productimage.png";
import { buttonNameConstants, systemConstants } from "../../../constants";
import {
  getProductLabelNotifications,
  getReceiptPrintDate,
  getShortestSku,
} from "../../utility";
import TradePrintLabelModal from "../../../views/Inventory/Items/printingLabelModal/TradePrintLabelModal";
import { NOTIFICATION_TYPES } from "../../../system/globalEnums";

const NotificationMenu = ({
  currentStore,
  userNotifications,
  getUserNotifications,
  markNotificationAsRead,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [copiedSku, setCopiedSku] = useState(null);
  const [customerNotifications, setCustomerNotifications] = useState([]);
  const [labelNotifications, setLabelNotifications] = useState([]);
  const [otherNotifications, setOtherNotifications] = useState([]);
  const [isPrintLabelModal, setIsPrintLabelModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const togglePrintLabelModal = () => {
    setIsPrintLabelModal(!isPrintLabelModal);
  };

  const handleClickOutsideElement = () => {
    setShowDropdown(false);
  };

  const ref = useDetectClickOutside({
    onTriggered: handleClickOutsideElement,
    allowAnyKey: true,
  });

  //-------copy sku number to clipboard
  const copyToClipboard = (skuNumber) => {
    const textArea = document.createElement("textarea");
    textArea.value = skuNumber;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setCopiedSku(skuNumber);
    setTimeout(() => {
      setCopiedSku(null);
    }, 3000);
  };

  const handleNotifcationItemClick = (userNotificationId) => {
    markNotificationAsRead(currentStore?.id, [userNotificationId]);
  };

  const handleMarkAllAsRead = (customerNotifications) => {
    if (customerNotifications?.length > 0) {
      const userNotificationId = customerNotifications.map((item) => item.id);
      markNotificationAsRead(currentStore?.id, userNotificationId);
    }
  };

  const handlePrintLabelClick = (data) => {
    setSelectedRows(data);
    togglePrintLabelModal();
  };

  const printAllLabel = () => {
    if (labelNotifications?.length > 0) {
      togglePrintLabelModal();
      setSelectedRows(
        labelNotifications?.length > 0
          ? labelNotifications.flatMap((obj) => obj.products)
          : []
      );
    }
  };

  const handleDismissAll = () => {
    if (tabIndex === 0) {
      handleMarkAllAsRead(customerNotifications);
    } else if (tabIndex === 1) {
      handleMarkAllAsRead(labelNotifications);
    } else if (tabIndex === 2) {
      handleMarkAllAsRead(otherNotifications);
    }
  };

  useEffect(() => {
    if (currentStore?.id) {
      getUserNotifications(currentStore?.id);
    }
  }, [currentStore]);

  useEffect(() => {
    setCustomerNotifications(
      userNotifications?.length > 0
        ? userNotifications?.filter(
            (notfication) => notfication.type === NOTIFICATION_TYPES.CUSTOMER
          )
        : []
    );

    const labelList = getProductLabelNotifications(userNotifications);

    setLabelNotifications(
      labelList.map((item) => ({
        ...item,
        products: item.products.map((product) => ({
          ...product,
          labelType: item.type,
        })),
      }))
    );

    setOtherNotifications(
      userNotifications?.length > 0
        ? userNotifications
            ?.filter(
              (notfication) =>
                notfication.type === NOTIFICATION_TYPES.PRODUCT_LABEL_IMPORT
            )
            .map((item) => ({
              ...item,
              products: item.products.map((product) => ({
                ...product,
                labelType: item.type,
              })),
            }))
        : []
    );
  }, [userNotifications]);
  return (
    <>
      <div className="notification-container" ref={ref}>
        <button
          onClick={toggleDropdown}
          className={`notification-toggle-button ${
            showDropdown && "active-notification-toggle-button "
          }`}
        >
          <NotificationIcon />
          {userNotifications.length > 0 && (
            <div
              className={`notification-count ${
                userNotifications?.length >
                  systemConstants.MAX_NOTIFICATION_NUMBER &&
                "max-notification-count"
              }`}
            >
              {userNotifications?.length >
              systemConstants.MAX_NOTIFICATION_NUMBER
                ? `${systemConstants.MAX_NOTIFICATION_NUMBER}+`
                : userNotifications?.length}
            </div>
          )}
        </button>

        {showDropdown && (
          <div className="notification-dropdown ">
            {" "}
            <div className="d-flex justify-content-between align-items-center w-100 py-2">
              <div className="py-2 notification-heading">Notifications</div>
              <button
                type="button"
                className="ms-auto mark-all-as-read-button"
                onClick={() => handleDismissAll()}
              >
                {buttonNameConstants.DISMISS_ALL}
              </button>
            </div>
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}
            >
              <TabList>
                <Tab>
                  Customers <span>{customerNotifications.length || 0}</span>
                </Tab>
                <Tab>
                  Labels <span>{labelNotifications.length || 0}</span>
                </Tab>
                <Tab>
                  Other <span>{otherNotifications.length || 0}</span>
                </Tab>
              </TabList>

              <TabPanel>
                <div className="d-flex justify-content-between align-items-center w-100 py-2">
                  <button
                    type="button"
                    className="ms-auto mark-all-as-read-button"
                    onClick={() => handleMarkAllAsRead(customerNotifications)}
                  >
                    {buttonNameConstants.MARK_ALL_AS_READ}
                  </button>
                </div>

                <div className="notifications-wrapper container-scrollbar">
                  {customerNotifications?.length > 0 ? (
                    customerNotifications.map((notification, index) => (
                      <div
                        key={index}
                        className={`notification-item ${
                          notification.isRead && "read-notification-item"
                        }`}
                      >
                        <img
                          src={notification.imgUrl || ProductImage}
                          alt="Product"
                        />
                        <div className="product-notification-content-container">
                          <p>{notification.product_name}</p>
                          <div className="sku-number-text">
                            <p>{getShortestSku(notification.sku)}</p>
                            <button
                              type="button"
                              onClick={() =>
                                copyToClipboard(
                                  getShortestSku(notification.sku)
                                )
                              }
                              className="copy-button"
                            >
                              <CopyIcon />
                            </button>
                            {copiedSku === getShortestSku(notification.sku) && (
                              <span>Copied!</span>
                            )}
                          </div>
                          <p>{notification.message}</p>
                        </div>
                        <button
                          type="button"
                          className="mb-auto mark-as-read-button"
                          onClick={() =>
                            handleNotifcationItemClick(notification.id)
                          }
                        >
                          <TickIcon className="read-icon" />
                        </button>
                      </div>
                    ))
                  ) : (
                    <div className="d-flex justify-content-center align-items-center p-3 bg-light rounded m-0 w-100">
                      No Unread Notifications
                    </div>
                  )}
                </div>
              </TabPanel>
              <TabPanel>
                <div className="d-flex justify-content-between align-items-center w-100 py-2">
                  <button
                    type="button"
                    className="ms-auto mark-all-as-read-button"
                    onClick={() => printAllLabel()}
                  >
                    {buttonNameConstants.PRINT_ALL}
                  </button>
                </div>

                <div className="notifications-wrapper container-scrollbar">
                  {labelNotifications?.length > 0 ? (
                    labelNotifications.map((notification, index) => (
                      <div
                        key={index}
                        className={`notification-item ${
                          notification.isRead && "read-notification-item"
                        }`}
                      >
                        <div className="product-notification-content-container w-100">
                          <div className="d-flex flex-column gap-2 align-items-start justify-content-between w-100">
                            <div className="d-flex justify-content-between align-items-start w-100">
                              <p className="print-message-text">
                                {notification.message}
                              </p>
                              <button
                                type="button"
                                className="mb-auto mark-as-read-button ms-auto"
                                onClick={() =>
                                  handleNotifcationItemClick(notification.id)
                                }
                              >
                                <TickIcon className="read-icon" />
                              </button>
                            </div>
                            <div className="d-flex justify-content-between align-items-end w-100">
                              <button
                                onClick={() =>
                                  handlePrintLabelClick(notification.products)
                                }
                                className="notification-print-button"
                              >
                                Print Labels
                              </button>
                              <span className="notification-date-time-text">
                                {getReceiptPrintDate(notification.createdOn)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="d-flex justify-content-center align-items-center p-3 bg-light rounded m-0 w-100">
                      No Unread Notifications
                    </div>
                  )}
                </div>
              </TabPanel>
              <TabPanel>
                <div className="d-flex justify-content-between align-items-center w-100 py-2">
                  <button
                    type="button"
                    className="ms-auto mark-all-as-read-button"
                    onClick={() => handleMarkAllAsRead(otherNotifications)}
                  >
                    {buttonNameConstants.MARK_ALL_AS_READ}
                  </button>
                </div>

                <div className="notifications-wrapper container-scrollbar">
                  {otherNotifications?.length > 0 ? (
                    otherNotifications.map((notification, index) => (
                      <div
                        key={index}
                        className={`notification-item ${
                          notification.isRead && "read-notification-item"
                        }`}
                      >
                        <div className="w-100">
                          <div className="d-flex flex-column gap-2 align-items-start justify-content-between w-100">
                            <div className="d-flex justify-content-between align-items-start w-100">
                              <p className="print-message-text other-print-message">
                                {notification.message}
                              </p>
                              <button
                                type="button"
                                className="mb-auto mark-as-read-button ms-auto"
                                onClick={() =>
                                  handleNotifcationItemClick(notification.id)
                                }
                              >
                                <TickIcon className="read-icon" />
                              </button>
                            </div>
                            <p className="notification-date-time-text mb-0">
                              {getReceiptPrintDate(notification.createdOn)}
                            </p>
                            {/* <button
                              onClick={() =>
                                handlePrintLabelClick(notification.products)
                              }
                              className="notification-print-button"
                            >
                              Print Labels
                            </button> */}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="d-flex justify-content-center align-items-center p-3 bg-light rounded m-0 w-100">
                      No Unread Notifications
                    </div>
                  )}
                </div>
              </TabPanel>
            </Tabs>
          </div>
        )}
      </div>
      <TradePrintLabelModal
        printLabelModal={isPrintLabelModal}
        togglePrintLabelModal={togglePrintLabelModal}
        selectedRows={selectedRows}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  userNotifications: state.store.userNotifications,
  currentStore: state.store.currentStore,
});
const mapDispatchToProps = (dispatch) => ({
  getUserNotifications: (storeId) =>
    dispatch(storeActions.getUserNotifications(storeId)),
  markNotificationAsRead: (storeId, data) =>
    dispatch(storeActions.markNotificationAsRead(storeId, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationMenu);
