import React from "react";
import { Field, ErrorMessage } from "formik";

import ErrorTextMessage from "../errorTextMessage/ErrorTextMessage";
import FormikInputNumberField from "./FormikInputNumberField";
import { MAXIMUM_VALUE_FOR_NUMBER_INPUT_FIELD } from "../../../constants";

//-------InputTextField component start here
const InputNumberField = (props) => {
  const {
    name,
    placeHolder,
    IconImage,
    type = "text",
    pattern,
    label,
    disabled = false,
    className,
    handleChange,
    step = "0.01",
    maxValue = MAXIMUM_VALUE_FOR_NUMBER_INPUT_FIELD,
    isConvertToDecimal = true,
    isHandleKeyDown = false,
    isBorder = false,
    outerWrapperClassName,
    min = 0,
  } = props;

  //-------Return InputTextField Component
  return (
    <div className={outerWrapperClassName && outerWrapperClassName}>
      {label && (
        <label className="field-label" htmlFor={name}>
          {label}
        </label>
      )}

      <Field
        min={min}
        className={className}
        id={name}
        isBorder={isBorder}
        step={step}
        name={name}
        placeholder={placeHolder}
        disabled={disabled}
        component={FormikInputNumberField}
        IconImage={IconImage}
        pattern={pattern}
        handleChange={handleChange}
        maxValue={maxValue}
        isConvertToDecimal={isConvertToDecimal}
        isHandleKeyDown={isHandleKeyDown}
      />
      <ErrorMessage name={name} component={ErrorTextMessage} />
    </div>
  ); //-------InputTextField component end here
};

//-------Export InputTextField Component
export default InputNumberField;
