import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useDebouncedCallback } from "use-debounce";
import React, { useRef, useMemo, useState, useCallback } from "react";

import AddCustomType from "./AddEditModals/AddCustomType";
import { LoadingIndicator } from "../../../shared/utility";
import Topbar from "../../../shared/components/topbar/Topbar";
import Button from "../../../shared/components/Buttons/Button";
import { itemOrganizationActions } from "../../../redux/actions";
import AddCustomCategory from "./AddEditModals/AddCustomCategory";
import AddCustomSubCategory from "./AddEditModals/AddCustomSubCategory";
import AGGridTable from "../../../shared/components/agGrid/AGGridTable";
import SearchField from "../../../shared/components/searchField/SearchField";
import CustomGridHeader from "../../../shared/components/agGrid/CustomGridHeader";
import AGGridToolTip from "../../../shared/components/toolTipComponent/AGGridToolTip";
import {
  buttonNameConstants,
  buttonTypeConstants,
  itemOrganizationConstants,
} from "../../../constants";
import {
  AddIcon,
  EditIcon,
  CrossIcon,
  DeleteIcon,
  SearchIcon,
  ActionDotsIcons,
} from "../../../assets/icons/iconsProvider";
import ClickableTooltip from "../../../shared/components/toolTipComponent/ClickableTooltip";

const ItemOrganization = (props) => {
  //-------Destructure Props
  const {
    currentStore,
    itemOrganization,
    addCustomCategories,
    addCustomProductTypes,
    addCustomSubCategories,
    deleteCustomCategories,
    updateCustomCategories,
    deleteCustomProductTypes,
    updateCustomProductTypes,
    deleteCustomSubCategories,
    updateCustomSubCategories,
  } = props;

  //-------UseRef
  const gridRef = useRef();
  const tooltipRef = useRef();

  //-------Reducer
  const { customTypes, customCategories, customSubCategories } =
    itemOrganization;

  //--------UseState

  const [typeSearchText, setTypeSearchText] = useState("");
  const [typeModalFormData, setTypeModalFormData] = useState("");
  const [categorySearchText, setCategorySearchText] = useState("");
  const [isCustomTypeModal, setIsCustomTypeModal] = useState(false);
  const [isTypeSearchLoading, setIsTypeSearchLoading] = useState(false);
  const [categoryModalFormData, setCategoryModalFormData] = useState("");
  const [subCategorySearchText, setSubCategorySearchText] = useState("");
  const [isCustomCategoryModal, setIsCustomCategoryModal] = useState(false);
  const [debouncedTypeSearchText, setDebouncedTypeSearchText] = useState("");
  const [subCategoryModalFormData, setSubCategoryModalFormData] = useState("");
  const [isCategorySearchLoading, setIsCategorySearchLoading] = useState(false);
  const [debouncedCategorySearchText, setDebouncedCategorySearchText] =
    useState("");
  const [debouncedSubCategorySearchText, setDebouncedSubCategorySearchText] =
    useState("");
  const [isCustomSubCategoryModal, setIsCustomSubCategoryModal] =
    useState(false);
  const [isSubCategorySearchLoading, setIsSubCategorySearchLoading] =
    useState(false);

  const toggleCustomTypeModal = () => {
    setIsCustomTypeModal(!isCustomTypeModal);
  };

  const toggleCustomCategoryModal = () => {
    setIsCustomCategoryModal(!isCustomCategoryModal);
  };

  const toggleCustomSubCategoryModal = () => {
    setIsCustomSubCategoryModal(!isCustomSubCategoryModal);
  };

  const clearTypeSearchText = () => {
    setTypeSearchText("");
    setDebouncedTypeSearchText("");
  };

  const clearCategorySearchText = () => {
    setCategorySearchText("");
    setDebouncedCategorySearchText("");
  };
  const clearSubCategorySearchText = () => {
    setSubCategorySearchText("");
    setDebouncedSubCategorySearchText("");
  };

  const handleEditClick = (editType, props) => {
    if (props.hideTooltipCallback) {
      props.hideTooltipCallback();
    }
    switch (editType) {
      case itemOrganizationConstants.UPDATE_CUSTOM_PRODUCT_TYPES:
        toggleCustomTypeModal();
        setTypeModalFormData(props.data);
        break;
      case itemOrganizationConstants.UPDATE_CUSTOM_CATEGORIES:
        toggleCustomCategoryModal();
        setCategoryModalFormData(props.data);
        break;
      case itemOrganizationConstants.UPDATE_CUSTOM_SUB_CATEGORIES:
        toggleCustomSubCategoryModal();
        setSubCategoryModalFormData(props.data);
        break;
      default:
        return;
    }
  };

  const handleDeleteClick = (deleteType, props) => {
    switch (deleteType) {
      case itemOrganizationConstants.UPDATE_CUSTOM_PRODUCT_TYPES:
        deleteCustomProductTypes(currentStore?.id, [props.data.id]);
        break;
      case itemOrganizationConstants.UPDATE_CUSTOM_CATEGORIES:
        deleteCustomCategories(currentStore?.id, props.data.id);
        break;
      case itemOrganizationConstants.UPDATE_CUSTOM_SUB_CATEGORIES:
        deleteCustomSubCategories(currentStore?.id, [props.data.id]);
        break;
      default:
        return;
    }
  };

  function actionCellRenderer(editType, params) {
    return (
      <div
        onMouseLeave={() => {
          tooltipRef.current?.close();
        }}
      >
        <>
          <ClickableTooltip
            anchorSelect={`#tool${params.data.id}`}
            openOnClick={true}
            tooltipRef={tooltipRef}
            offset={-10}
          >
            <Button
              className="w-100"
              IconImage={EditIcon}
              label={buttonNameConstants.EDIT}
              handleClick={() => handleEditClick(editType, params)}
              buttonType={buttonTypeConstants.ACTION_BUTTON}
            />
            <Button
              className="w-100"
              IconImage={DeleteIcon}
              label={buttonNameConstants.DELETE}
              handleClick={() => handleDeleteClick(editType, params)}
              buttonType={buttonTypeConstants.ACTION_BUTTON}
            />
          </ClickableTooltip>
          <button
            id={`tool${params.data.id}`}
            className="action-Button-Wrapper"
            onClick={() => {
              tooltipRef.current?.isOpen
                ? tooltipRef.current?.close()
                : tooltipRef.current?.open({
                    anchorSelect: `tool${params.data.id}`,
                  });
            }}
          >
            <ActionDotsIcons className="cursor-pointer" />
          </button>
        </>
      </div>
    );
  }

  const TypeColDefs = useMemo(
    () => [
      {
        headerName: "Custom Types",
        field: "productType",
      },
      {
        maxWidth: 100,
        headerName: "",
        field: "id",
        lockPosition: "right",
        cellRenderer: (params) =>
          actionCellRenderer(
            itemOrganizationConstants.UPDATE_CUSTOM_PRODUCT_TYPES,
            params
          ),
        // tooltipField: "id",
        cellStyle: {
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          overflow: "visible",
        },
        // tooltipComponentParams: {
        //   type: "actionTooltip",
        //   color: "#f4f4f4",
        //   className: "actionTooltip",
        //   ActionButtons: typesActionButtons,
        // },
        sortable: false,
      },
    ],
    // eslint-disable-next-line
    [customTypes]
  );

  const CategoriesColDefs = useMemo(
    () => [
      {
        headerName: "Custom Categories",
        field: "name",
        minWidth: 150,
      },
      {
        headerName: "Type",
        field: "productTypeName",
      },
      {
        maxWidth: 100,
        headerName: "",
        field: "id",
        lockPosition: "right",
        cellRenderer: (params) =>
          actionCellRenderer(
            itemOrganizationConstants.UPDATE_CUSTOM_CATEGORIES,
            params
          ),
        // tooltipField: "id",
        cellStyle: {
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          overflow: "visible",
        },
        // tooltipComponentParams: {
        //   type: "actionTooltip",
        //   color: "#f4f4f4",
        //   className: "actionTooltip",
        //   ActionButtons: categoriesActionButtons,
        // },
        sortable: false,
      },
    ], // eslint-disable-next-line
    [customCategories]
  );

  const SubCategoriesColDefs = useMemo(
    () => [
      {
        headerTooltip: "Custom Sub-Categories",
        headerName: "Custom Sub...",
        field: "name",
      },
      {
        headerName: "Category",
        field: "parentCategoryName",
      },
      {
        headerName: "Type",
        field: "parentProductTypeName",
      },
      {
        maxWidth: 100,
        headerName: "",
        field: "id",
        lockPosition: "right",
        cellRenderer: (params) =>
          actionCellRenderer(
            itemOrganizationConstants.UPDATE_CUSTOM_SUB_CATEGORIES,
            params
          ),
        // tooltipField: "id",
        cellStyle: {
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          overflow: "visible",
        },
        // tooltipComponentParams: {
        //   type: "actionTooltip",
        //   color: "#f4f4f4",
        //   className: "actionTooltip",
        //   ActionButtons: subCategoriesActionButtons,
        // },
        sortable: false,
      },
    ], // eslint-disable-next-line
    [customSubCategories]
  );

  const typeGetRowId = useCallback((params) => params.data.id, []);

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomGridHeader,
    };
  }, []);

  const getRowStyle = (params) => {
    if (params.node && params.node.editing) {
      return { backgroundColor: "#000000" };
    }
    return null;
  };

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { display: "flex", alignItems: "center" },
      resizable: false,
      tooltipComponent: AGGridToolTip,
    };
  }, []);

  const typesGridData = useMemo(() => {
    return customTypes.filter((type) => type.isCustom === true);
  }, [customTypes]);

  const categoriesGridData = useMemo(() => {
    return customCategories.filter((type) => type.isCustom === true);
  }, [customCategories]);

  const subCategoriesGridData = useMemo(() => {
    return customSubCategories.filter((type) => type.isCustom === true);
  }, [customSubCategories]);

  const handleTypeSearchChange = (value) => {
    setIsTypeSearchLoading(true);
    setDebouncedTypeSearchText(value);
    handleDebouncedTypeSearch(value);
  };

  const handleCategorySearchChange = (value) => {
    setIsCategorySearchLoading(true);
    setDebouncedCategorySearchText(value);
    handleDebouncedCategorySearch(value);
  };

  const handleSubCategorySearchChange = (value) => {
    setIsSubCategorySearchLoading(true);
    setDebouncedSubCategorySearchText(value);
    handleDebouncedSubCategorySearch(value);
  };

  const handleDebouncedTypeSearch = useDebouncedCallback((value) => {
    setTypeSearchText(value);
    setIsTypeSearchLoading(false);
  }, 1000);

  const handleDebouncedCategorySearch = useDebouncedCallback((value) => {
    setCategorySearchText(value);
    setIsCategorySearchLoading(false);
  }, 1000);

  const handleDebouncedSubCategorySearch = useDebouncedCallback((value) => {
    setSubCategorySearchText(value);
    setIsSubCategorySearchLoading(false);
  }, 1000);

  return (
    <>
      <Topbar title="Item Organization"></Topbar>
      {/* <Button
        className={"w-25 ms-auto me-4"}
        handleClick={() => addDefaultCategories(currentStore)}
        IconImage={AddIcon}
        label={"Add Default Types / Categories"}
      /> */}
      <Row className="m-0">
        <Col md={3}>
          <Row className="m-0">
            <Col md={12} className="mb-3">
              <SearchField
                value={debouncedTypeSearchText}
                IconImage={SearchIcon}
                label="Search Type"
                placeHolder="Type or Scan"
                clearSearchText={clearTypeSearchText}
                CrossIcon={typeSearchText ? CrossIcon : ""}
                onChange={(e) => handleTypeSearchChange(e.target.value)}
                LoadingIndicator={isTypeSearchLoading && LoadingIndicator}
              />
            </Col>
            <Col md={12} className="mb-3">
              <AGGridTable
                gridRef={gridRef}
                colDefs={TypeColDefs}
                components={components}
                getRowId={typeGetRowId}
                gridData={typesGridData}
                getRowStyle={getRowStyle}
                searchText={typeSearchText}
                defaultColDef={defaultColDef}
                pagination={false}
                tableHeight={`calc(100vh - 220px)`}
              />
            </Col>
            <Col md={12}>
              <Button
                className={"w-100"}
                handleClick={toggleCustomTypeModal}
                IconImage={AddIcon}
                label={buttonNameConstants.ADD_TYPE}
              />
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Row className="m-0">
            <Col md={12} className="mb-3">
              <SearchField
                value={debouncedCategorySearchText}
                IconImage={SearchIcon}
                label="Search Category"
                placeHolder="Type or Scan"
                clearSearchText={clearCategorySearchText}
                CrossIcon={categorySearchText ? CrossIcon : ""}
                onChange={(e) => handleCategorySearchChange(e.target.value)}
                LoadingIndicator={isCategorySearchLoading && LoadingIndicator}
              />
            </Col>
            <Col md={12} className="mb-3">
              <AGGridTable
                gridRef={gridRef}
                pagination={false}
                components={components}
                getRowId={typeGetRowId}
                getRowStyle={getRowStyle}
                colDefs={CategoriesColDefs}
                gridData={categoriesGridData}
                defaultColDef={defaultColDef}
                searchText={categorySearchText}
                tableHeight={`calc(100vh - 220px)`}
              />
            </Col>
            <Col md={12}>
              <Button
                className={"w-100"}
                handleClick={toggleCustomCategoryModal}
                IconImage={AddIcon}
                label={buttonNameConstants.ADD_CATEGORY}
              />
            </Col>
          </Row>
        </Col>
        <Col md={5}>
          <Row className="m-0">
            <Col md={12} className="mb-3">
              <SearchField
                value={debouncedSubCategorySearchText}
                IconImage={SearchIcon}
                label="Search Sub-Category"
                placeHolder="Type or Scan"
                clearSearchText={clearSubCategorySearchText}
                CrossIcon={subCategorySearchText ? CrossIcon : ""}
                onChange={(e) => handleSubCategorySearchChange(e.target.value)}
                LoadingIndicator={
                  isSubCategorySearchLoading && LoadingIndicator
                }
              />
            </Col>
            <Col md={12} className="mb-3">
              <AGGridTable
                gridRef={gridRef}
                pagination={false}
                components={components}
                getRowId={typeGetRowId}
                getRowStyle={getRowStyle}
                defaultColDef={defaultColDef}
                colDefs={SubCategoriesColDefs}
                gridData={subCategoriesGridData}
                searchText={subCategorySearchText}
                tableHeight={`calc(100vh - 220px)`}
              />
            </Col>
            <Col md={12}>
              <Button
                className={"w-100"}
                handleClick={toggleCustomSubCategoryModal}
                IconImage={AddIcon}
                label={buttonNameConstants.ADD_SUB_CATEGORY}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <AddCustomType
        storeId={currentStore?.id}
        AddCustomType={addCustomProductTypes}
        typeModalFormData={typeModalFormData}
        isCustomTypeModal={isCustomTypeModal}
        setTypeModalFormData={setTypeModalFormData}
        toggleCustomTypeModal={toggleCustomTypeModal}
        updateCustomProductTypes={updateCustomProductTypes}
      />
      <AddCustomCategory
        customTypes={customTypes}
        storeId={currentStore?.id}
        AddCustomCategory={addCustomCategories}
        categoryModalFormData={categoryModalFormData}
        isCustomCategoryModal={isCustomCategoryModal}
        updateCustomCategories={updateCustomCategories}
        setCategoryModalFormData={setCategoryModalFormData}
        toggleCustomCategoryModal={toggleCustomCategoryModal}
      />
      <AddCustomSubCategory
        customTypes={customTypes}
        storeId={currentStore?.id}
        customCategories={customCategories}
        AddCustomSubCategory={addCustomSubCategories}
        subCategoryModalFormData={subCategoryModalFormData}
        isCustomSubCategoryModal={isCustomSubCategoryModal}
        updateCustomSubCategories={updateCustomSubCategories}
        setSubCategoryModalFormData={setSubCategoryModalFormData}
        toggleCustomSubCategoryModal={toggleCustomSubCategoryModal}
      />
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  currentStore: state.store.currentStore,
  itemOrganization: state.itemOrganization,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  addCustomProductTypes: (storeId, data, handleHideModal) =>
    dispatch(
      itemOrganizationActions.addCustomProductTypes(
        storeId,
        data,
        handleHideModal
      )
    ),
  addCustomCategories: (storeId, data, handleHideModal) =>
    dispatch(
      itemOrganizationActions.addCustomCategories(
        storeId,
        data,
        handleHideModal
      )
    ),
  addCustomSubCategories: (storeId, data, handleHideModal) =>
    dispatch(
      itemOrganizationActions.addCustomSubCategories(
        storeId,
        data,
        handleHideModal
      )
    ),
  updateCustomProductTypes: (storeId, data, handleHideModal) =>
    dispatch(
      itemOrganizationActions.updateCustomProductTypes(
        storeId,
        data,
        handleHideModal
      )
    ),
  updateCustomCategories: (storeId, data, handleHideModal) =>
    dispatch(
      itemOrganizationActions.updateCustomCategories(
        storeId,
        data,
        handleHideModal
      )
    ),
  updateCustomSubCategories: (storeId, data, handleHideModal) =>
    dispatch(
      itemOrganizationActions.updateCustomSubCategories(
        storeId,
        data,
        handleHideModal
      )
    ),
  deleteCustomProductTypes: (storeId, data) =>
    dispatch(itemOrganizationActions.deleteCustomProductTypes(storeId, data)),
  deleteCustomCategories: (storeId, categoryId) =>
    dispatch(
      itemOrganizationActions.deleteCustomCategories(storeId, categoryId)
    ),
  deleteCustomSubCategories: (storeId, data) =>
    dispatch(itemOrganizationActions.deleteCustomSubCategories(storeId, data)),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(ItemOrganization);
