import { connect } from "react-redux";
import React, { Fragment, useEffect, useRef } from "react";
import LoadingBar from "react-top-loading-bar";

import DraftConfirmationModal from "../../../views/SellAndTrade/Shared/DraftConfirmationModal";
import ToastPrintLabelModal from "../../../shared/components/ToastPrintLabel/ToastPrintLabelModal";
import IdleUserModal from "../../../shared/hooks/IdleUserModal";

const MainWrapper = ({
  children,
  spinnerArray,
  isAuthenticated,
  isPrintLabelModal,
  isDraftConfirmationModal,
}) => {
  const loadingBarRef = useRef(null);
  const prevLengthRef = useRef(spinnerArray.length);
  // const { reset } = useIdleUserClose(10000);

  useEffect(() => {
    const prevLength = prevLengthRef.current;
    const currentLength = spinnerArray.length;

    if (prevLength === 0 && currentLength > 0) {
      loadingBarRef.current.continuousStart();
    } else if (currentLength === 0 && prevLength > 0) {
      loadingBarRef.current.complete();
    }

    // Update the previous length value
    prevLengthRef.current = currentLength;
  }, [spinnerArray]);

  return (
    <Fragment>
      <LoadingBar
        color="#6a18ed"
        ref={loadingBarRef}
        height={3}
        shadow={true}
      />
      {isDraftConfirmationModal ? <DraftConfirmationModal /> : ""}
      {isPrintLabelModal ? <ToastPrintLabelModal /> : ""}

      <div>
        <div>
          {isAuthenticated && <IdleUserModal />}
          {children}
        </div>
      </div>
    </Fragment>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  isAuthenticated: state.authentication.isAuthenticated,
  spinnerArray: state.system.spinnerArray,
  isDraftConfirmationModal: state.transaction.isDraftConfirmationModal,
  isPrintLabelModal: state.printer.isPrintLabelModal,
});

export default connect(mapStateToProps, null)(MainWrapper);
