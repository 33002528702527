import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import moment, { duration } from "moment";

import { customToast } from "../../utility";
import { datetimePickerDropdown } from "../../../assets/scss/style/datetimePickerDropdown";
import {
  ClockIcon,
  LeftArrowIcon,
  RightArrowIcon,
} from "../../../assets/icons/iconsProvider";
import {
  toastType,
  toastMessages,
  DATEPICKER_OPTIONS,
  datepickerConstants,
  EMPTY_STRING,
} from "../../../constants";
import CustomDatePicker from "./CustomDatePicker";

const DashboardDatePicker = ({
  label,
  getTimeFrame,
  timeframeDateFormat = "",
  displayDateFormat = "MM/DD/YYYY",
}) => {
  const [selectedDatetimePickerOption, setSelectedDatetimePickerOption] =
    useState(DATEPICKER_OPTIONS[0]);
  const [customStartDate, setCustomStartDate] = useState(new Date());
  const [customEndDate, setCustomEndDate] = useState(new Date());

  const getBiweeklyTimeFrame = (dateFormat) => {
    const today = moment();
    const startOfBiweek = today.clone().startOf(datepickerConstants.WEEK);
    const endOfBiweek = startOfBiweek
      .clone()
      .add(1, datepickerConstants.WEEK)
      .subtract(1, datepickerConstants.DAY);

    const startOfPreviousBiweek = startOfBiweek
      .clone()
      .subtract(1, datepickerConstants.WEEK);

    return {
      start: startOfPreviousBiweek.format(dateFormat),
      end: endOfBiweek.format(dateFormat),
      duration: datepickerConstants.BIWEEKLY,
    };
  };

  const [selectedYear, setSelectedYear] = useState(
    moment().format(datepickerConstants.YYYY)
  );

  const getDefaultTimeFrame = () => {
    const start = moment()
      .startOf(datepickerConstants.DAY)
      .format(timeframeDateFormat);
    const end = moment()
      .endOf(datepickerConstants.DAY)
      .format(timeframeDateFormat);
    setCustomStartDate(start);
    setCustomEndDate(end);
    return {
      start: start,
      end: end,
      duration: datepickerConstants.DAILY,
    };
  };

  const getMonthlyTimeFrame = (dateFormat) => {
    const today = moment();
    const startOfMonth = today.clone().startOf(datepickerConstants.MONTH);
    const endOfMonth = today.clone().endOf(datepickerConstants.MONTH);

    return {
      start: startOfMonth.format(dateFormat),
      end: endOfMonth.format(dateFormat),
      duration: datepickerConstants.MONTHLY,
    };
  };

  const getYearlyTimeFrame = (selectedYear, dateFormat) => {
    const startOfYear = moment(selectedYear, datepickerConstants.YYYY).startOf(
      datepickerConstants.YEAR
    );
    const endOfYear = moment(selectedYear, datepickerConstants.YYYY).endOf(
      datepickerConstants.YEAR
    );

    return {
      start: startOfYear.format(dateFormat),
      end: endOfYear.format(dateFormat),
      duration: datepickerConstants.YEARLY,
    };
  };

  const getCustomTimeFrame = (dateFormat) => {
    // Assuming customStartDate and customEndDate are already defined elsewhere
    const startOfDay = moment(customStartDate)
      .startOf(datepickerConstants.DAY)
      .format(dateFormat);
    const endOfDay = moment(customEndDate)
      .endOf(datepickerConstants.DAY)
      .format(dateFormat);

    return {
      start: startOfDay,
      end: endOfDay,
      duration: selectedDatetimePickerOption?.value,
    };
  };

  const handleCustomStartDateChange = (date) => {
    if (date <= customEndDate) {
      setCustomStartDate(date);
    } else {
      customToast(
        toastMessages.START_DATE_MUST_BE_LESS_THAN_END_DATE,
        toastType.ERROR
      );
    }
  };

  const handleCustomEndDateChange = (date) => {
    if (customStartDate <= date) {
      setCustomEndDate(date);
    } else {
      customToast(
        toastMessages.END_DATE_MUST_BE_GREATER_THAN_START_DATE,
        toastType.ERROR
      );
    }
  };

  const handleDatetimePickerOptionChange = (selectedOption) => {
    setSelectedDatetimePickerOption(selectedOption);

    if (selectedOption) {
      switch (selectedOption.value) {
        case datepickerConstants.DAILY:
          getTimeFrame(getDefaultTimeFrame());
          break;
        case datepickerConstants.WEEKLY:
          const startDate = moment()
            .startOf(datepickerConstants.WEEK)
            .format(displayDateFormat);
          const endDate = moment()
            .endOf(datepickerConstants.WEEK)
            .format(displayDateFormat);

          setCustomStartDate(startDate);
          setCustomEndDate(endDate);

          break;
        case datepickerConstants.BIWEEKLY:
          setCustomStartDate(getBiweeklyTimeFrame(displayDateFormat).start);
          setCustomEndDate(getBiweeklyTimeFrame(displayDateFormat).end);
          break;
        case datepickerConstants.MONTHLY:
          setCustomStartDate(getMonthlyTimeFrame(timeframeDateFormat).start);
          setCustomEndDate(getMonthlyTimeFrame(timeframeDateFormat).end);
          break;
        case datepickerConstants.YEARLY:
          setCustomStartDate(
            getYearlyTimeFrame(selectedYear, timeframeDateFormat).start
          );
          setCustomEndDate(
            getYearlyTimeFrame(selectedYear, timeframeDateFormat).end
          );
          break;
        case datepickerConstants.CUSTOM:
          const CustomDate = new Date();
          getRange(CustomDate);
          break;
        default:
          return;
      }
    }
  };

  const CustomInput = (props) => (
    <div>
      <components.Input {...props} />
      <ClockIcon className="dropdown-icon" />
    </div>
  );
  useEffect(() => {
    getTimeFrame(getCustomTimeFrame());
  }, [customStartDate, customEndDate]);

  useEffect(() => {
    getTimeFrame(getDefaultTimeFrame());
  }, [timeframeDateFormat]);

  const getDaily = (date) => {
    setCustomStartDate(date);
    setCustomEndDate(date);
  };
  const getRange = async (date) => {
    setCustomStartDate(date);
    setCustomEndDate(date);
  };

  const handleChangeWeek = (week) => {
    const startDate = new Date(week);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);
    setCustomStartDate(startDate);
    setCustomEndDate(endDate);
  };
  const handleChangeBiWeek = (week) => {
    const startDate = new Date(week);
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 13);
    setCustomStartDate(startDate);
    setCustomEndDate(endDate);
  };
  const handleChangeMonth = (week) => {
    const startDate = new Date(week);
    const endDate = moment(week).add(1, "month").subtract(1, "day").toDate();
    setCustomStartDate(startDate);
    setCustomEndDate(endDate);
  };

  const handleChangeYear = (week) => {
    const incomingDate = moment(week);
    const startDate = incomingDate.clone().startOf("year"); // Start of the incoming date's year
    const customEndDate = incomingDate.clone().endOf("year"); // End of the incoming date's year

    setCustomStartDate(startDate.toDate());
    setCustomEndDate(customEndDate.toDate());
  };

  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `${longMonth} ${fullYear}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const updateDates = (
    startDate,
    endDate,
    unit,
    value,
    isStartOfPeriod = false,
    isEndOfPeriod = false
  ) => {
    const updatedStartDate = isStartOfPeriod
      ? moment(startDate)
          .add(value, unit)
          .startOf(unit)
          .format(datepickerConstants.DATE_TIME_FORMAT)
      : moment(startDate)
          .add(value, unit)
          .format(datepickerConstants.DATE_TIME_FORMAT);

    const updatedEndDate = isEndOfPeriod
      ? moment(endDate)
          .add(value, unit)
          .endOf(unit)
          .format(datepickerConstants.DATE_TIME_FORMAT)
      : moment(endDate)
          .add(value, unit)
          .format(datepickerConstants.DATE_TIME_FORMAT);

    setCustomStartDate(updatedStartDate);
    setCustomEndDate(updatedEndDate);
  };

  const handleNextOrPreviousClick = (value) => {
    switch (value) {
      case datepickerConstants.DAILY_NEXT_BUTTON:
        updateDates(customStartDate, customEndDate, datepickerConstants.DAY, 1);
        break;
      case datepickerConstants.DAILY_PREVIOUS_BUTTON:
        updateDates(
          customStartDate,
          customEndDate,
          datepickerConstants.DAY,
          -1
        );
        break;
      case datepickerConstants.MONTH_NEXT_BUTTON:
        updateDates(
          customStartDate,
          customEndDate,
          datepickerConstants.MONTH,
          1,
          true,
          true
        );
        break;
      case datepickerConstants.MONTH_PREVIOUS_BUTTON:
        updateDates(
          customStartDate,
          customEndDate,
          datepickerConstants.MONTH,
          -1,
          true,
          true
        );
        break;
      case datepickerConstants.YEARLY_NEXT_BUTTON:
        updateDates(
          customStartDate,
          customEndDate,
          datepickerConstants.YEAR,
          1,
          true,
          true
        );
        break;
      case datepickerConstants.YEARLY_PREVIOUS_BUTTON:
        updateDates(
          customStartDate,
          customEndDate,
          datepickerConstants.YEAR,
          -1,
          true,
          true
        );
        break;
      case datepickerConstants.WEEKLY_NEXT_BUTTON:
        updateDates(
          customStartDate,
          customEndDate,
          datepickerConstants.WEEK,
          1,
          true,
          true
        );
        break;
      case datepickerConstants.WEEKLY_PREVIOUS_BUTTON:
        updateDates(
          customStartDate,
          customEndDate,
          datepickerConstants.WEEK,
          -1,
          true,
          true
        );
        break;
      case datepickerConstants.BIWEEKLY_NEXT_BUTTON:
        updateDates(
          customStartDate,
          customEndDate,
          datepickerConstants.WEEK,
          2,
          true,
          true
        );
        break;
      case datepickerConstants.BIWEEKLY_PREVIOUS_BUTTON:
        updateDates(
          customStartDate,
          customEndDate,
          datepickerConstants.WEEK,
          -2,
          true,
          true
        );
        break;
      default:
    }
  };
  return (
    <div className="d-flex flex-column">
      {label && <label className="field-label">{label}</label>}
      <div className="d-flex justify-content-center align-items-center gap-2 datetimepicker-container dashboard-datepicker">
        <Select
          options={DATEPICKER_OPTIONS}
          value={selectedDatetimePickerOption}
          onChange={handleDatetimePickerOptionChange}
          styles={datetimePickerDropdown}
          className="date-time-picker-dropdown"
          components={{
            IndicatorSeparator: () => null,
            Input: CustomInput,
          }}
          menuPortalTarget={document.querySelector("body")}
        />

        {selectedDatetimePickerOption.value === datepickerConstants.DAILY ? (
          <CustomDatePicker
            onChange={getDaily}
            customEndDate={customEndDate}
            nextKey={datepickerConstants.DAILY_NEXT_BUTTON}
            previousKey={datepickerConstants.DAILY_PREVIOUS_BUTTON}
            handleNextOrPreviousClick={handleNextOrPreviousClick}
          />
        ) : selectedDatetimePickerOption.value ===
          datepickerConstants.WEEKLY ? (
          <CustomDatePicker
            onChange={handleChangeWeek}
            className={EMPTY_STRING}
            selectsRange={true}
            isStartDateSelected={true}
            customEndDate={customEndDate}
            customStartDate={customStartDate}
            nextKey={datepickerConstants.WEEKLY_NEXT_BUTTON}
            handleNextOrPreviousClick={handleNextOrPreviousClick}
            previousKey={datepickerConstants.WEEKLY_PREVIOUS_BUTTON}
          />
        ) : selectedDatetimePickerOption.value ===
          datepickerConstants.BIWEEKLY ? (
          <CustomDatePicker
            onChange={handleChangeBiWeek}
            className={EMPTY_STRING}
            selectsRange={true}
            isStartDateSelected={true}
            customEndDate={customEndDate}
            customStartDate={customStartDate}
            nextKey={datepickerConstants.BIWEEKLY_NEXT_BUTTON}
            handleNextOrPreviousClick={handleNextOrPreviousClick}
            previousKey={datepickerConstants.BIWEEKLY_PREVIOUS_BUTTON}
          />
        ) : selectedDatetimePickerOption.value ===
          datepickerConstants.MONTHLY ? (
          <CustomDatePicker
            onChange={handleChangeMonth}
            className={"daily-datepicker monthly-datepicker"}
            dateFormat={"MMMM"}
            isStartDateSelected={true}
            customEndDate={customEndDate}
            customStartDate={customStartDate}
            nextKey={datepickerConstants.MONTH_NEXT_BUTTON}
            handleNextOrPreviousClick={handleNextOrPreviousClick}
            previousKey={datepickerConstants.MONTH_PREVIOUS_BUTTON}
            showMonthYearPicker={true}
          />
        ) : selectedDatetimePickerOption.value ===
          datepickerConstants.YEARLY ? (
          <CustomDatePicker
            onChange={handleChangeYear}
            className={"daily-datepicker monthly-datepicker"}
            dateFormat={"yyyy"}
            isStartDateSelected={true}
            customEndDate={customEndDate}
            customStartDate={customStartDate}
            showYearPicker={true}
            nextKey={datepickerConstants.YEARLY_NEXT_BUTTON}
            handleNextOrPreviousClick={handleNextOrPreviousClick}
            previousKey={datepickerConstants.YEARLY_PREVIOUS_BUTTON}
          />
        ) : selectedDatetimePickerOption.value ===
          datepickerConstants.CUSTOM ? (
          <div className="d-flex justify-content-center align-items-center gap-2 daily-datepicker monthly-datepicker ">
            <DatePicker
              selected={customStartDate}
              onChange={handleCustomStartDateChange}
            />
            {"-"}
            <DatePicker
              selected={customEndDate}
              onChange={handleCustomEndDateChange}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default DashboardDatePicker;
