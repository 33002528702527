import { updateObject } from "../../shared/utility";
import { storeConstants, globalConstants } from "../../constants";

//-------InitialState of the reducer
const initialState = {
  stores: [],
  currentStore: "",
  storeOwners: [],
  editStore: {},
  userNotifications: [],
  payouts: {},
};

//-------Export Authentication Reducer
export const store = (state = initialState, action) => {
  switch (action.type) {
    //-------get stores
    case storeConstants.GET_STORES + globalConstants.SUCCESS:
      return updateObject(state, {
        stores: action.data,
      });
    // Add store reducer
    case storeConstants.ADD_STORE + globalConstants.SUCCESS:
      return updateObject(state, {
        stores: action.data,
      });
    case storeConstants.GET_STORE_OWNERS + globalConstants.SUCCESS:
      return updateObject(state, {
        storeOwners: action.data,
      });
    case storeConstants.SET_CURRENT_STORE + globalConstants.SUCCESS:
      return updateObject(state, {
        currentStore: action.data,
      });
    case storeConstants.GET_USER_NOTIFICATIONS + globalConstants.SUCCESS:
      return updateObject(state, {
        userNotifications: action.data,
      });
    case storeConstants.CREATE_NOTIFICATION + globalConstants.SUCCESS:
      return updateObject(state, {
        userNotifications: [action.data, ...state.userNotifications],
      });
    case storeConstants.PAYOUT_INFORMATION + globalConstants.SUCCESS:
      return updateObject(state, {
        payouts: action.data,
      });
    //-------Default State
    default:
      return state;
  }
};
