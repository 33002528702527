import React, { useCallback } from "react";
import { AgGridReact } from "ag-grid-react";

import Button from "../Buttons/Button";
import {
  DeleteIcon,
  EditIcon,
  PrintIcon,
} from "../../../assets/icons/iconsProvider";
import { buttonNameConstants, buttonTypeConstants } from "../../../constants";
import AgGridPaginationComponent from "./AgGridPaginationComponent";

const ServerPaginationTable = ({
  colDefs,
  gridRef,
  gridData,
  gridState,
  components,
  getRowStyle,
  tableHeight,
  selectedRows,
  defaultColDef,
  onCellClicked,
  updateGridState,
  rowClassRules = {},
  pagination = true,
  onSelectionChanged,
  animateRows = true,
  getRowId = () => {},
  editType = "fullRow",
  tooltipShowDelay = 0,
  handleDeleteAllClick,
  handlePrintLabelClick,
  isDataLoading = false,
  singleClickEdit = true,
  tooltipHideDelay = 1000,
  tooltipInteraction = true,
  suppressCellFocus = true,
  rowSelection = "multiple",
  isDisablePaginationActions,
  alwaysShowVerticalScroll = false,
  suppressRowClickSelection = true,
  suppressRowHoverHighlight = true,
  alwaysShowHorizontalScroll = false,
  suppressDragLeaveHidesColumns = true,
  defaultGridSizeStrategy = {
    type: "fitGridWidth",
    defaultMinWidth: 100,
  },
}) => {
  const onFirstDataRendered = useCallback((params) => {
    params.api.sizeColumnsToFit();
  }, []);

  const onGridSizeChanged = useCallback(
    (params) => {
      var gridWidth = document.querySelector(".ag-body-viewport").clientWidth;
      var columnsToShow = [];
      var columnsToHide = [];
      var totalColsWidth = 0;
      var allColumns = params.api.getColumns();
      if (allColumns && allColumns.length > 0) {
        for (var i = 0; i < allColumns.length; i++) {
          var column = allColumns[i];
          totalColsWidth += column.getMinWidth() || 0;
          if (totalColsWidth > gridWidth) {
            columnsToHide.push(column.getColId());
          } else {
            columnsToShow.push(column.getColId());
          }
        }
      }
      params.api.setColumnsVisible(columnsToShow, true);
      params.api.setColumnsVisible(columnsToHide, false);
      window.setTimeout(() => {
        params.api.sizeColumnsToFit();
      }, 10);
    },
    [window]
  );

  return (
    <div
      className="ag-theme-quartz" // applying the grid theme
      style={{
        height: tableHeight,
        paddingBottom: "5px",
      }} // the grid will fill the size of the parent container
    >
      {selectedRows?.length > 0 && (
        <div className="d-flex justify-content-end gap-2 py-2">
          <Button
            label={buttonNameConstants.DELETE}
            buttonType={buttonTypeConstants.RED_BUTTON}
            IconImage={DeleteIcon}
            type="button"
            handleClick={() => handleDeleteAllClick()}
          />
          <Button
            label={buttonNameConstants.PRINT_LABEL}
            type="button"
            IconImage={PrintIcon}
            buttonType={buttonTypeConstants.LIGHT_PURPLE}
            handleClick={() => handlePrintLabelClick()}
          />
        </div>
      )}
      <AgGridReact
        ref={gridRef}
        rowData={gridData}
        getRowId={getRowId}
        editType={editType}
        columnDefs={colDefs}
        components={components}
        animateRows={animateRows}
        getRowStyle={getRowStyle}
        rowSelection={rowSelection}
        rowClassRules={rowClassRules}
        onCellClicked={onCellClicked}
        defaultColDef={defaultColDef}
        singleClickEdit={singleClickEdit}
        tooltipShowDelay={tooltipShowDelay}
        tooltipHideDelay={tooltipHideDelay}
        suppressCellFocus={suppressCellFocus}
        onGridSizeChanged={onGridSizeChanged}
        onSelectionChanged={onSelectionChanged}
        tooltipInteraction={tooltipInteraction}
        onFirstDataRendered={onFirstDataRendered}
        autoSizeStrategy={defaultGridSizeStrategy}
        alwaysShowVerticalScroll={alwaysShowVerticalScroll}
        suppressRowClickSelection={suppressRowClickSelection}
        suppressRowHoverHighlight={suppressRowHoverHighlight}
        alwaysShowHorizontalScroll={alwaysShowHorizontalScroll}
        suppressDragLeaveHidesColumns={suppressDragLeaveHidesColumns}
      />
      {pagination && (
        <AgGridPaginationComponent
          gridData={gridData}
          gridState={gridState}
          isDataLoading={isDataLoading}
          updateGridState={updateGridState}
          isDisablePaginationActions={isDisablePaginationActions}
        />
      )}
    </div>
  );
};

export default ServerPaginationTable;
