import React from "react";

const ProgressBar = ({ min, max, current, percentage, label = "" }) => {
  return (
    <div class="w-100 progress category-bar">
      <div
        class="progress-bar"
        style={{
          width: `${percentage}%`,
        }}
        role="progressbar"
        aria-valuenow={current}
        aria-valuemin={min}
        aria-valuemax={max}
      ></div>
      <span>{label && label}</span>
    </div>
  );
};

export default ProgressBar;
