import React, { useRef } from "react";
import Select, { components } from "react-select";

import { selectFieldStyle } from "../../../assets/scss/style";

const SelectDropDown = ({
  options,
  handleChange,
  defaultPageSize,
  className,
  menuPlacement = "top",
  noOptionsMessage = "No Data Found",
  placeHolder = "",
  isClearable = false,
  styles = "",
  isSearchable = false,
  menuPortalTarget = document.querySelector("body"),
  value,
  label = "",
  isDisabled = false,
  isSingleValue = false,
  outerWrapperClassname = "",
}) => {
  const selectRef = useRef(null);

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <div className="d-flex align-items-center gap-4">
        {isSingleValue ? isSingleValue : children}
      </div>
    </components.SingleValue>
  );

  return (
    <div
      className={`${outerWrapperClassname} ${
        isDisabled && "disable-dropdown-icon"
      }`}
    >
      {label && <label className="field-label">{label}</label>}
      <Select
        ref={selectRef}
        className={className}
        options={options}
        getOptionLabel={(e) => e.label}
        getOptionValue={(e) => e.value}
        isSearchable={isSearchable}
        components={{
          IndicatorSeparator: () => null,
          SingleValue,
        }}
        styles={styles || selectFieldStyle}
        onChange={(option) => {
          handleChange(option);
          selectRef.current?.blur();
        }}
        menuPlacement={menuPlacement}
        defaultValue={defaultPageSize}
        noOptionsMessage={() => {
          return noOptionsMessage;
        }}
        placeholder={placeHolder}
        isClearable={isClearable}
        menuPortalTarget={menuPortalTarget}
        value={value}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default SelectDropDown;
