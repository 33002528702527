import React from "react";

import IconSelectField from "../../../shared/components/selectField/IconSelectField";
import { PRODUCT_TYPES_ENUMS } from "../../../system/globalEnums";
import { getCustomTypesOptionList } from "../../../shared/utility";
import { globalConstants } from "../../../constants";

const CustomItemProductTypeSelectField = ({
  setFieldValue,
  customTypes,
  ...props
}) => {
  const handleTypeChange = (option, setFieldValue) => {
    setFieldValue("type", option.value);
    setFieldValue("category", globalConstants.EMPTY_STRING);
    setFieldValue("condition", globalConstants.EMPTY_STRING);
    setFieldValue("subCategory", globalConstants.EMPTY_STRING);
  };

  return (
    <IconSelectField
      label="Type"
      options={getCustomTypesOptionList(customTypes).filter(
        (item) => item.label !== PRODUCT_TYPES_ENUMS.ALL
      )}
      placeHolder="Type..."
      name="type"
      customOnChange={(option) => handleTypeChange(option, setFieldValue)}
      isClearable={false}
    />
  );
};

export default CustomItemProductTypeSelectField;
