import React from "react";

//-------SearchField component start here
const SearchField = (props) => {
  const {
    ref,
    onChange,
    IconImage,
    placeHolder,
    className,
    BlueBoxIcon,
    LoadingIndicator,
    value,
    isFocus = false,
    CrossIcon,
    clearSearchText,
    isDisabled = false,
    onClick,
    onBlur,
    label,
  } = props;
  return (
    <div className="w-100">
      {label && <label className="field-label"> {label}</label>}
      <div className="input-field-wrapper w-100">
        {IconImage && <IconImage className="icon-image" />}
        <input
          ref={ref}
          className={`input-textfield ${className} ${
            IconImage || "input-textfield-without-icon"
          } ${className && className}`}
          type="text"
          onChange={onChange}
          onClick={onClick}
          onBlur={onBlur}
          value={value}
          placeholder={placeHolder}
          autoComplete="off"
          autoFocus={isFocus}
          disabled={isDisabled}
        />
        {LoadingIndicator && <LoadingIndicator />}

        {value
          ? CrossIcon && (
              <span className="icon-image" onClick={clearSearchText}>
                <CrossIcon />
              </span>
            )
          : BlueBoxIcon && <BlueBoxIcon className="icon-image" />}
      </div>
    </div>
  );
}; //-------SearchField component end here

//-------Export SearchField Component
export default SearchField;
