import moment from "moment";
import { connect } from "react-redux";
import { Form, Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";

import { inventoryService } from "../../../../services";
import SearchInventory from "../SearchInventory/SearchInventory";
import Button from "../../../../shared/components/Buttons/Button";
import ProductImage from "../../../../assets/images/productimage.png";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import ConsolidationModal from "../ConfirmationModal/ConsolidationModal";
import ProductTagList from "../../../../shared/components/tags/ProductTagList";
import CheckboxField from "../../../../shared/components/checkBox/CheckboxField";
import InputTextArea from "../../../../shared/components/inputTextArea/InputTextArea";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import CategoriesDropdown from "../../../../shared/components/selectDropdown/CategoriesDropdown";
import {
  batchPricechartingProductImage,
  calculateOfferPrices,
  findExistingStockProduct,
  findInventoryProductDetail,
  getMarginDropdownValue,
  getTotalNumberOfInStockProducts,
  handleProductConditionUpdate,
  handleRaritiesChangeInSingleAddEdit as handleRaritiesChange,
  handleUploadProductImage,
  isMarketPriceHigh,
  isProductsExistInStock,
  isSerialNumberUnique,
  isSystemProduct,
  onSelectTagsChange,
  parseToDecimalNumber,
} from "../../../../shared/utility";
import {
  systemActions,
  inventoryActions,
  tradeInDiscountActions,
  itemOrganizationActions,
} from "../../../../redux/actions";
import {
  addCustomItemValidationSchema,
  addNewIventoryValidationSchema,
} from "../../../../shared/validationSchema/validationSchema";
import {
  PRODUCT_TYPES_ENUMS,
  PRODUCT_CONDITIONS_ENUMS,
  TRADING_CARD_CATEGORIES_ENUMS,
} from "../../../../system/globalEnums";
import {
  customToast,
  isTagsEqual,
  convertToUsd,
  stringToSlug,
  getShortestSku,
  handleViewTCGPlayer,
  handleViewEbaySales,
  changePriceBaseOnTags,
  convertPricesToNumbers,
  handleViewPriceCharting,
  uploadCustomProductImage,
  handleConditionsAndTags,
  getPresetStockPrice,
  handleConditionChange,
  getProductSubCategory,
  getTypeOrCategoryObject,
  getCustomTypesOptionList,
  getCustomCategoriesOptionList,
  getCustomSubCategoriesOptionList,
  isSpinnerEnabled,
  parseToThousandSeperatorDecimalNumber,
  getSystemMarketPrice,
} from "../../../../shared/utility";
import {
  toastType,
  toastMessages,
  globalConstants,
  inventoryConstants,
  buttonNameConstants,
  buttonTypeConstants,
  categoryDropdownConstants,
  INVENTORY_PRODUCT_DEFAULT_DATA_OBJECT,
  EMPTY_STRING,
  toggleSwitchButtonConstants,
} from "../../../../constants";
import {
  DollarIcon,
  ExpandTabIcon,
  SidebarToggleIcon,
  LogoImageReplaceIcon,
} from "../../../../assets/icons/iconsProvider";
import CustomItemProductTypeSelectField from "../../Shared/CustomItemProductTypeSelectField";
import CustomItemCategorySelectField from "../../Shared/CustomItemCategorySelectField";
import CustomItemSubcategorySelectField from "../../Shared/CustomItemSubcategorySelectField";
import CustomItemConditionSelectField from "../../Shared/CustomItemConditionSelectField";

const AddInventoryItem = (props) => {
  //-------Destructure props
  const {
    inventory,
    customTypes,
    currentStore,
    addInventory,
    spinnerArray,
    tradeDiscount,
    addCustomItem,
    isAddModalOpen,
    toggleAddModal,
    activateSpinner,
    customCategories,
    deactivateSpinner,
    customSubCategories,
    addInventoryItemModal,
    toggleAddInventoryItemModal,
    getInventoryByPricechartingId,
    getInventoryByProductMetaDataAndConsolidation,
    emptyPriceChartingProductObject,
    isConsolidationModal,
    handleClearFilter,
  } = props;

  //-------inventory reducer
  const {
    productTags,
    allCategories,
    inventoryProducts,
    priceChartingProductInfo,
  } = inventory;

  //-------preset prices
  const { markupPresets, tradeinMargin } = tradeDiscount;
  //------UseRef
  const hiddenFileInput = useRef(null);

  //-------useState
  const [productUrl, setProductUrl] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [customProductSelectedTags, setCustomProductSelectedTags] = useState(
    []
  );
  const [conditionList, setConditionList] = useState([]);
  const [productCondition, setProductCondition] = useState();
  const [productTagsList, setProductTagsList] = useState([]);
  const [isCustomItemTab, setIsCustomItemTab] = useState(false);
  const [rarities, setRarities] = useState(globalConstants.EMPTY_STRING);
  const [productType, setProductType] = useState(PRODUCT_TYPES_ENUMS.ALL);
  const [isTradiingCardWarningText, setIsTradiingCardWarningText] =
    useState(false);
  const [selectedRarity, setSelectedRarity] = useState(
    globalConstants.EMPTY_STRING
  );
  const [productDataObject, setProductDataObject] = useState(
    INVENTORY_PRODUCT_DEFAULT_DATA_OBJECT
  );

  //-------useref
  const asyncSelectRef = useRef();

  //-------inital values for customItem
  const customItemInitialValues = {
    quantity: 1,
    stockPrice: 0,
    costOfGoods: 0,
    upc: globalConstants.EMPTY_STRING,
    type: globalConstants.EMPTY_STRING,
    title: globalConstants.EMPTY_STRING,
    condition: globalConstants.EMPTY_STRING,
    category: globalConstants.EMPTY_STRING,
    subCategory: globalConstants.EMPTY_STRING,
    description: globalConstants.EMPTY_STRING,
  };

  //-------initial values
  const initialValues = {
    quantity: 1,
    box:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE &&
      selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.WITH_BOX)
        ? true
        : false,
    manual:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE
        ? selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.MISSING_MANUAL)
          ? false
          : true
        : productCondition?.value === PRODUCT_CONDITIONS_ENUMS.LOOSE
        ? selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.WITH_MANUAL)
          ? true
          : false
        : false,
    cables:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.LOOSE
        ? selectedTags?.includes(inventoryConstants.PRODUCT_TAGS.MISSING_WIRES)
          ? false
          : true
        : false,
    controller:
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.LOOSE
        ? selectedTags?.includes(
            inventoryConstants.PRODUCT_TAGS.MISSING_CONTROLLER
          )
          ? false
          : true
        : false,
    costOfGoods: productDataObject.costOfGoods || globalConstants.EMPTY_STRING,
    inStockPrice:
      productDataObject.inStorePrice || productDataObject.inStockPrice || 0,
    serialNumber: productDataObject?.serialNumber || EMPTY_STRING,
  };

  //-------get Sku Number
  const getSkuNumber = async () => {
    return inventoryService.getSkuNumber(currentStore?.id).then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        customToast(error, toastType.ERROR);
      }
    );
  };

  //-------onselectedtagchange
  const onCustomProductSelectTagsChange = (selectedTags) => {
    const selectedTagList = selectedTags.map((tag) => tag.label);
    setCustomProductSelectedTags(selectedTagList);
  };

  const toggleCustomItemTab = () => {
    setIsCustomItemTab(!isCustomItemTab);
  };

  //-------handle inventory submit
  const handleAddInvetorySubmit = async (values) => {
    if (!productDataObject?.productName) {
      customToast(toastMessages.PLEASE_SELECT_SOME_PRODUCT, toastType.ERROR);
    } else if (
      isSystemProduct(getProductSubCategory(productDataObject?.genre)) &&
      !isSerialNumberUnique(values.serialNumber, inventoryProducts || [])
    ) {
      customToast(
        toastMessages.PRODUCT_SERIAL_NUMBER_SHOULD_BE_UNIQUE,
        toastType.ERROR
      );
      return;
    } else if (values?.serialNumber && values.quantity > 1) {
      customToast(toastMessages.SYSTEM_QUANTITY_MUST_BE_1, toastType.ERROR);
      return;
    } else {
      // const skuNumber = isSystemProduct(
      //   getProductSubCategory(productDataObject?.genre)
      // )
      //   ? [await getSkuNumber()]
      //   : (productDataObject.skuNumber?.length &&
      //       productDataObject.skuNumber) || [await getSkuNumber()];
      addInventory(
        {
          product_id: productDataObject.productId,
          serialNumber: values.serialNumber,
          product_name: productDataObject.productName,
          category_name: productDataObject.consoleName,
          subcategory:
            productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME
              ? getProductSubCategory(productDataObject.genre)
              : globalConstants.EMPTY_STRING,
          description: "",
          sku: [inventoryConstants.AUTO_GENERATED],
          store: {
            id: currentStore?.id,
            name: currentStore?.storeName,
          },
          date_added: new Date(),
          price: {
            unit_purchase_price: values.costOfGoods,
            unit_sell_price: values.inStockPrice,
            quantity: values.quantity,
            type: productCondition?.value,
            marketPrice: convertToUsd(productDataObject?.marketPrice) || 0,
          },
          productType: productType,
          upc: productDataObject.upc,
          additionalCheckList: [],
          tags: selectedTags?.map((tag) => tag),
          cardRarity: selectedRarity?.label || "",
          cardNumber: productDataObject?.cardNumber || "",
          apiSource: priceChartingProductInfo?.productMetaData?.sourceApi,
          imgUrl: productDataObject?.imgUrl,
          epid: productDataObject.epid || globalConstants.EMPTY_STRING,
          tcgPlayerUrl:
            productDataObject.tcgPlayerUrl || globalConstants.EMPTY_STRING,
        },
        handleClearFilter
      );
    }
  };

  //-------hide modal
  const handleHideModal = () => {
    toggleAddInventoryItemModal();
    toggleAddModal(false);
    clearAddInventoryForm();
    setIsCustomItemTab(false);
  };

  //-------empty product data object
  const emptyProductDataObject = () => {
    setProductDataObject(INVENTORY_PRODUCT_DEFAULT_DATA_OBJECT);
  };

  //-------clear form
  const clearAddInventoryForm = () => {
    setConditionList([]);
    setProductTagsList([]);
    emptyProductDataObject();
    emptyPriceChartingProductObject();
    setProductUrl(globalConstants.EMPTY_STRING);
    setSelectedRarity(globalConstants.EMPTY_STRING);
    setProductCondition(globalConstants.EMPTY_STRING);
  };

  //-------Change Logo image button
  const handleReplaceButtonClick = () => {
    hiddenFileInput.current.click();
  };

  const handleCustomItemSubmit = async (values) => {
    const sendToData = {
      upc: values.upc ?? EMPTY_STRING,
      tags: [
        ...(customProductSelectedTags || []),
        inventoryConstants.CUSTOM_ITEM_TAG,
      ],
      cardRarity: "",
      cardNumber: "",
      description: "",
      tcgPlayerUrl: "",
      serialNumber: "",
      additionalCheckList: [],
      product_name: values.title,
      sku: [inventoryConstants.AUTO_GENERATED],
      apiSource: inventoryConstants.CUSTOM_ITEM,
      product_id: inventoryConstants.CUSTOM_ITEM,
      date_added: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      category_name: getTypeOrCategoryObject(customCategories, values.category)
        ?.name,
      subcategory:
        getTypeOrCategoryObject(customSubCategories, values.subCategory)
          ?.name || "",
      store: {
        id: currentStore.id,
        name: currentStore.storeName,
      },
      price: {
        unit_purchase_price: values.costOfGoods,
        unit_sell_price: values.stockPrice,
        quantity: values.quantity,
        type: values.condition,
        marketPrice: 0,
      },
      productType: getTypeOrCategoryObject(customTypes, values.type)
        ?.productType,
    };
    getInventoryByProductMetaDataAndConsolidation(
      currentStore?.id,
      {
        product_name: sendToData.product_name,
        category_name: sendToData.category_name,
        subcategory: sendToData.subcategory,
        type: sendToData.price.type,
        tags: sendToData.tags,
        cardRarity: sendToData.cardRarity,
        productType: sendToData.productType,
      },
      {
        functionAttributes: [
          [sendToData],
          toastMessages.PORDUCT_ADDED_SUCCESSFULLY,
          handleHideModal,
        ],
        functionToCall: addCustomItem,
      }
    );
    return;
  };

  //-------UseEffect
  useEffect(() => {
    if (currentStore) {
      emptyPriceChartingProductObject();
    }
  }, [currentStore]);

  useEffect(() => {
    clearAddInventoryForm();
  }, [isAddModalOpen]);

  //-------update product condition and type
  useEffect(() => {
    if (addInventoryItemModal) {
      handleProductConditionUpdate(
        currentStore,
        priceChartingProductInfo,
        getInventoryByPricechartingId,
        setProductType,
        setProductUrl,
        productDataObject,
        setProductDataObject,
        productTags,
        setProductTagsList,
        setRarities,
        setSelectedRarity,
        setConditionList,
        setProductCondition
      );
    }
  }, [priceChartingProductInfo.productObject]);

  const updateProductImageUrl = async () => {
    const findProduct = isProductsExistInStock(
      productCondition?.value || "",
      selectedTags,
      selectedRarity,
      inventoryProducts,
      priceChartingProductInfo
    );

    const newImgUrl = findProduct?.length
      ? findProduct[0]?.imgUrl
      : priceChartingProductInfo &&
        productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME
      ? await batchPricechartingProductImage(priceChartingProductInfo)
      : EMPTY_STRING;

    setProductDataObject((prevState) => ({
      ...prevState,
      imgUrl: prevState?.imgUrl || newImgUrl,
    }));
  };

  useEffect(() => {
    if (
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
      productCondition?.value === PRODUCT_CONDITIONS_ENUMS.LOOSE
    ) {
      findInventoryProductDetail(
        isSystemProduct(getProductSubCategory(productDataObject?.genre))
          ? PRODUCT_CONDITIONS_ENUMS.LOOSE
          : PRODUCT_CONDITIONS_ENUMS.COMPLETE,
        productType,
        markupPresets,
        tradeinMargin,
        selectedTags,
        selectedRarity,
        productDataObject,
        inventoryProducts,
        setProductDataObject,
        priceChartingProductInfo
      );
    } else if (productCondition?.value === PRODUCT_CONDITIONS_ENUMS.NEAR_MINT) {
      findInventoryProductDetail(
        PRODUCT_CONDITIONS_ENUMS.NEAR_MINT,
        productType,
        markupPresets,
        tradeinMargin,
        selectedTags,
        selectedRarity,
        productDataObject,
        inventoryProducts,
        setProductDataObject,
        priceChartingProductInfo
      );
    }
    updateProductImageUrl();
  }, [inventoryProducts]);

  useEffect(() => {
    if (productCondition?.value) {
      findInventoryProductDetail(
        productCondition?.value,
        productType,
        markupPresets,
        tradeinMargin,
        selectedTags,
        selectedRarity,
        productDataObject,
        inventoryProducts,
        setProductDataObject,
        priceChartingProductInfo
      );
    }
  }, [selectedTags, selectedRarity, productCondition]);

  useEffect(() => {
    const tradingCardConditions = inventoryConstants.TRADING_CARD_CONDITION.map(
      (item) => item.value
    );

    if (
      tradingCardConditions.includes(productCondition?.value) &&
      productCondition?.value !== PRODUCT_CONDITIONS_ENUMS.NEAR_MINT
    ) {
      setIsTradiingCardWarningText(true);
    } else {
      setIsTradiingCardWarningText(false);
    }
  }, [productCondition]);

  return (
    <>
      <Modal
        show={addInventoryItemModal}
        size="xl"
        onHide={
          isSpinnerEnabled(spinnerArray, inventoryConstants.ADD_INVENTORY)
            ? ""
            : handleHideModal
        }
        animation={true}
        centered
        backdrop="static"
        className={`add-inventory-modal ${productUrl ? "iframe-opened" : ""}`}
      >
        <Modal.Header closeButton className="add-inventory-modal-header">
          <Modal.Title>
            <span className="add-inventory-modal-header-name">
              Add Inventory
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-inventory-modal-body d-flex ">
          <Row className={`m-0 ${productUrl ? "w-50" : "w-100"}`}>
            <Col md={12} className="add-inventory-heading text-muted px-0 pb-2">
              Search from Marketplace
            </Col>
            <Col md={12} className="mb-3 ps-md-0">
              <SearchInventory
                customTypes={customTypes}
                asyncSelectRef={asyncSelectRef}
                customCategories={customCategories}
              />
            </Col>
            {priceChartingProductInfo && (
              <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={handleAddInvetorySubmit}
                validate={(values) =>
                  addNewIventoryValidationSchema(
                    values,
                    currentStore?.[
                      toggleSwitchButtonConstants
                        .IS_SERIAL_NUMBER_REQUIRED_INVENTORY
                    ] &&
                      isSystemProduct(
                        getProductSubCategory(productDataObject?.genre)
                      )
                  )
                }
              >
                {({ values }) => (
                  <Form>
                    <>
                      <Col xs={12} className="add-inventory-hero-wrapper px-0">
                        <Row className="m-0">
                          <Col md={8} className="d-flex gap-3">
                            <div className="add-inventory-product-image">
                              <img
                                src={productDataObject?.imgUrl || ProductImage}
                              />
                              <input
                                type="file"
                                className="d-none"
                                accept=".png, .jpg, .jpeg"
                                onChange={(e) =>
                                  handleUploadProductImage(
                                    e,
                                    activateSpinner,
                                    deactivateSpinner,
                                    productDataObject,
                                    setProductDataObject
                                  )
                                }
                                ref={hiddenFileInput}
                              />
                              <button
                                onClick={handleReplaceButtonClick}
                                type="button"
                                className="replace-logo-button"
                              >
                                <LogoImageReplaceIcon className="replace-icon" />
                              </button>
                            </div>

                            <div className="d-flex flex-column justify-content-between align-items-start product-content-wrapper">
                              <span>
                                {productDataObject.productName ||
                                  "Product Name"}
                              </span>
                              <span>
                                {productDataObject.consoleName ||
                                  "Console Name"}
                              </span>
                              <span>
                                {productDataObject?.skuNumber?.length
                                  ? `#${getShortestSku(
                                      productDataObject?.skuNumber
                                    )}`
                                  : "#Auto Generated"}
                              </span>
                              <span>{`In Stock: ${productDataObject.inStockQuantity}`}</span>
                            </div>
                          </Col>

                          {priceChartingProductInfo?.productMetaData
                            ?.sourceApi ===
                          inventoryConstants.PRICECHARTING_API ? (
                            <Col
                              md={4}
                              className="d-flex flex-column align-items-center gap-2"
                            >
                              <Button
                                type="button"
                                label={buttonNameConstants.VIEW_EBAY_SALES}
                                className="w-100"
                                handleClick={() =>
                                  handleViewEbaySales(
                                    productDataObject?.productName,
                                    productDataObject?.consoleName,
                                    productDataObject?.genre
                                  )
                                }
                                buttonType={buttonTypeConstants.LIGHT_PURPLE}
                              />
                              <Button
                                type="button"
                                label={buttonNameConstants.VIEW_PRICE_CHARTING}
                                className="w-100"
                                handleClick={() =>
                                  handleViewPriceCharting(
                                    priceChartingProductInfo,
                                    setProductUrl
                                  )
                                }
                                buttonType={buttonTypeConstants.LIGHT_PURPLE}
                              />
                            </Col>
                          ) : priceChartingProductInfo?.productMetaData
                              ?.sourceApi === inventoryConstants.SCRYFALL_API ||
                            priceChartingProductInfo?.productMetaData
                              ?.sourceApi === inventoryConstants.POKEMON_API ? (
                            <Col
                              md={4}
                              className="d-flex flex-column align-items-center gap-2"
                            >
                              <Button
                                type="button"
                                label={buttonNameConstants.VIEW_TCG_PLAYER}
                                className="w-100"
                                handleClick={() =>
                                  handleViewTCGPlayer(productDataObject)
                                }
                                buttonType={buttonTypeConstants.LIGHT_PURPLE}
                                isDisabled={!productDataObject?.tcgPlayerUrl}
                              />
                            </Col>
                          ) : null}

                          <Col
                            md={12}
                            className="d-flex justify-content-start flex-wrap gap-3 mt-3 pt-2"
                          >
                            <span className="quantity-top-margin">
                              Quantity:
                            </span>
                            <InputNumberField
                              name="quantity"
                              placeHolder="0"
                              className="add-inventory-quantity-field"
                              isConvertToDecimal={false}
                              step={"1"}
                              isHandleKeyDown={true}
                              disabled={
                                isSystemProduct(
                                  getProductSubCategory(
                                    productDataObject?.genre
                                  )
                                ) &&
                                currentStore[
                                  toggleSwitchButtonConstants
                                    .IS_SERIAL_NUMBER_REQUIRED_INVENTORY
                                ]
                              }
                            />
                            <CategoriesDropdown
                              options={conditionList}
                              handleChange={(selectedOption) =>
                                handleConditionChange(
                                  selectedOption,
                                  setProductCondition,
                                  priceChartingProductInfo,
                                  productTags,
                                  setProductTagsList,
                                  setProductDataObject,
                                  productDataObject,
                                  setSelectedTags
                                )
                              }
                              noOptionsMessage="No Condition Found"
                              placeholder="Condition"
                              SelectIconImage={
                                categoryDropdownConstants.INVENTORY_LIST_ICON
                              }
                              isClearable={false}
                              className="add-inventory-dropdowns-category condition-dropdown"
                              value={productCondition}
                            />
                            {isSystemProduct(
                              getProductSubCategory(productDataObject?.genre)
                            ) && (
                              <InputTextField
                                name="serialNumber"
                                maxLength={100}
                                placeHolder="Enter Serial Number"
                                className="border add-inventory-serial-number-field"
                              />
                            )}
                            {productDataObject?.genre ===
                              TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD ||
                            productDataObject?.genre ===
                              TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD ? (
                              <CategoriesDropdown
                                options={rarities || []}
                                handleChange={(selectedOption) =>
                                  handleRaritiesChange(
                                    selectedOption,
                                    setSelectedRarity,
                                    setProductDataObject,
                                    productDataObject,
                                    tradeinMargin,
                                    markupPresets
                                  )
                                }
                                noOptionsMessage="No Printing Found"
                                placeholder="Printings"
                                SelectIconImage={
                                  categoryDropdownConstants.INVENTORY_LIST_ICON
                                }
                                isClearable={false}
                                className="add-inventory-dropdowns-category condition-dropdown"
                                value={selectedRarity}
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                          {productCondition?.value ===
                            PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
                          productCondition?.value ===
                            PRODUCT_CONDITIONS_ENUMS.LOOSE ? (
                            <Col
                              md={12}
                              className="d-flex align-items-center gap-3 my-2"
                            >
                              {productDataObject.genre ===
                              TRADING_CARD_CATEGORIES_ENUMS.SYSTEM ? (
                                productCondition?.value ===
                                PRODUCT_CONDITIONS_ENUMS.COMPLETE ? (
                                  <>
                                    <CheckboxField
                                      name="manual"
                                      label="Manual"
                                    />
                                    {/* <CheckboxField name="box" label="Box" /> */}
                                    <CheckboxField
                                      name="cables"
                                      label="Cables"
                                    />
                                    <CheckboxField
                                      name="controller"
                                      label="Controller"
                                    />
                                  </>
                                ) : productCondition?.value ===
                                  PRODUCT_CONDITIONS_ENUMS.LOOSE ? (
                                  <>
                                    <CheckboxField
                                      name="manual"
                                      label="Manual"
                                    />
                                    <CheckboxField
                                      name="cables"
                                      label="Cables"
                                    />
                                    <CheckboxField
                                      name="controller"
                                      label="Controller"
                                    />
                                  </>
                                ) : (
                                  ""
                                )
                              ) : [
                                  ...inventoryConstants.NINTENDO_CATEGORIES,
                                  ...inventoryConstants.SKYLANDER_CATEGORIES,
                                ].includes(productDataObject.consoleName) ? (
                                ""
                              ) : (
                                <CheckboxField name="manual" label="Manual" />
                              )}
                            </Col>
                          ) : (
                            ""
                          )}

                          <Col
                            md={12}
                            className="my-2 d-flex align-items-center gap-4"
                          >
                            <ProductTagList
                              tagsList={productTagsList || []}
                              suggestedArrayList={productTags}
                              onSelectTagsChange={(selectedTags) => {
                                onSelectTagsChange(
                                  selectedTags,
                                  setSelectedTags,
                                  productDataObject,
                                  setProductDataObject,
                                  priceChartingProductInfo,
                                  productCondition,
                                  productType
                                );
                              }}
                              selectedManual={{
                                isChecked: values.manual,
                                value:
                                  productCondition?.value ===
                                  PRODUCT_CONDITIONS_ENUMS.COMPLETE
                                    ? inventoryConstants.PRODUCT_TAGS
                                        .MISSING_MANUAL
                                    : productCondition?.value ===
                                      PRODUCT_CONDITIONS_ENUMS.LOOSE
                                    ? inventoryConstants.PRODUCT_TAGS
                                        .WITH_MANUAL
                                    : "",
                              }}
                              selectedWires={{
                                isChecked: values.cables,
                                value:
                                  productCondition?.value ===
                                    PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
                                  productCondition?.value ===
                                    PRODUCT_CONDITIONS_ENUMS.LOOSE
                                    ? inventoryConstants.PRODUCT_TAGS
                                        .MISSING_WIRES
                                    : "",
                              }}
                              selectedController={{
                                isChecked: values.controller,
                                value:
                                  productCondition?.value ===
                                    PRODUCT_CONDITIONS_ENUMS.COMPLETE ||
                                  productCondition?.value ===
                                    PRODUCT_CONDITIONS_ENUMS.LOOSE
                                    ? inventoryConstants.PRODUCT_TAGS
                                        .MISSING_CONTROLLER
                                    : "",
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col xs={12} className="m-0 p-0 mt-4">
                        <Row className="m-0">
                          <Col
                            md={4}
                            className="add-inventory-prices-detail-wrapper d-flex flex-column align-items-lg-stretch gap-3"
                          >
                            <div>
                              <span>GameStop Buy Price</span>
                              <span>{`$${parseToThousandSeperatorDecimalNumber(
                                convertToUsd(productDataObject.gameStopBuyPrice)
                              )}`}</span>
                            </div>
                            <div>
                              <span>Average Buy Price</span>
                              <span>{`$${parseToThousandSeperatorDecimalNumber(
                                productDataObject.averageBuyPrice || 0
                              )}`}</span>
                            </div>
                            <div>
                              <span>Max Buy Price</span>
                              <span>{`$${parseToThousandSeperatorDecimalNumber(
                                productDataObject.maxBuyPrice || 0
                              )}`}</span>
                            </div>
                            <div>
                              <span>Average Sell Price</span>
                              <span>{`$${parseToThousandSeperatorDecimalNumber(
                                productDataObject.averageSellPrice || 0
                              )}`}</span>
                            </div>
                          </Col>
                          <Col md={8}>
                            <Row className="m-0 h-100">
                              <Col xs={12} className="d-flex flex-column gap-1">
                                <div className="w-100 d-flex justify-content-between add-inventory-price-heading">
                                  <span>Market Price: </span>

                                  <span
                                    className={
                                      isMarketPriceHigh(
                                        selectedRarity
                                          ? productDataObject?.marketPrice &&
                                            productDataObject?.marketPrice !==
                                              null
                                            ? productDataObject?.marketPrice
                                            : 0
                                          : convertToUsd(
                                              productDataObject.marketPrice
                                            ),
                                        values.inStockPrice,
                                        productTags,
                                        selectedTags
                                      ) && values.inStockPrice
                                        ? "text-danger"
                                        : ""
                                    }
                                  >
                                    {`$${parseToThousandSeperatorDecimalNumber(
                                      selectedRarity
                                        ? productDataObject?.marketPrice &&
                                          productDataObject?.marketPrice !==
                                            null
                                          ? productDataObject?.marketPrice
                                          : 0
                                        : convertToUsd(
                                            productDataObject.marketPrice
                                          )
                                    )}`}
                                  </span>
                                </div>
                                {isTradiingCardWarningText && (
                                  <div className="w-100 d-flex justify-content-end trading-card-warning-text">
                                    <span>
                                      Price is only for Near-Mint condition
                                    </span>
                                  </div>
                                )}
                                <div className="w-100 d-flex justify-content-between add-inventory-price-sub-heading">
                                  <span>Suggested sell price: </span>
                                  <span>
                                    {`$${parseToThousandSeperatorDecimalNumber(
                                      convertToUsd(
                                        productDataObject.suggestedSellPrice
                                      )
                                    )}`}
                                  </span>
                                </div>
                                <div className="w-100 d-flex justify-content-between add-inventory-price-sub-heading">
                                  <span>In-Stock Price: </span>
                                  <span>
                                    {`$${parseToThousandSeperatorDecimalNumber(
                                      productDataObject.inStorePrice || 0
                                    )}`}
                                  </span>
                                </div>
                              </Col>
                              <Col xs={12} className="d-flex flex-column gap-1">
                                <div className="w-100 d-flex justify-content-between align-items-center add-inventory-price-heading">
                                  <span>Cost of Goods: </span>
                                  <InputNumberField
                                    name="costOfGoods"
                                    placeHolder="0.00"
                                    className="add-inventory-price-field"
                                    IconImage={DollarIcon}
                                  />
                                </div>
                              </Col>
                              <Col xs={12} className="d-flex flex-column gap-3">
                                <div className="add-inventory-stock-price-wrapper">
                                  <div className="w-100 d-flex justify-content-between add-inventory-price-sub-heading">
                                    <span>Stock Price </span>
                                  </div>
                                  <div className="w-100 d-flex justify-content-between align-items-center add-inventory-price-heading">
                                    <span>In-Store: </span>
                                    <InputNumberField
                                      name="inStockPrice"
                                      placeHolder="0.00"
                                      className={`add-inventory-price-field ${
                                        isMarketPriceHigh(
                                          selectedRarity
                                            ? productDataObject?.marketPrice &&
                                              productDataObject?.marketPrice !==
                                                null
                                              ? productDataObject?.marketPrice
                                              : 0
                                            : convertToUsd(
                                                productDataObject.marketPrice
                                              ),
                                          values.inStockPrice,
                                          productTags,
                                          selectedTags
                                        ) && values.inStockPrice
                                          ? "border-danger"
                                          : ""
                                      }`}
                                      IconImage={DollarIcon}
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col
                                md={12}
                                className="d-flex align-items-end gap-2"
                              >
                                <Button
                                  label={buttonNameConstants.CANCEL}
                                  className="w-100"
                                  type="button"
                                  handleClick={() => handleHideModal()}
                                  buttonType={buttonTypeConstants.GHOST_BUTTON}
                                  isDisabled={isSpinnerEnabled(
                                    spinnerArray,
                                    inventoryConstants.ADD_INVENTORY
                                  )}
                                />
                                <Button
                                  label={buttonNameConstants.ADD_TO_INVENTORY}
                                  className="w-100"
                                  type="submit"
                                  isDisabled={isSpinnerEnabled(
                                    spinnerArray,
                                    inventoryConstants.ADD_INVENTORY
                                  )}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  </Form>
                )}
              </Formik>
            )}
            {!priceChartingProductInfo && (
              <>
                <Col md={12} className="px-0 d-flex justify-content-center">
                  <hr className="w-75" />
                </Col>
                <Formik
                  initialValues={customItemInitialValues}
                  validate={addCustomItemValidationSchema}
                  onSubmit={handleCustomItemSubmit}
                >
                  {({ values, setFieldValue }) => (
                    <Form className="px-0">
                      <Col
                        md={12}
                        className="add-inventory-hero-wrapper my-2 py-4 px"
                      >
                        <Row className="m-0">
                          <Col
                            md={9}
                            className="mb-3"
                            onClick={() => {
                              setIsCustomItemTab(true);
                            }}
                          >
                            <InputTextField
                              name="title"
                              maxLength={100}
                              label="Title"
                              placeHolder="Enter Title"
                              className="border"
                            />
                          </Col>
                          <Col md={3} className="mb-3">
                            <InputNumberField
                              label="Quantity"
                              name="quantity"
                              placeHolder="0"
                              className="add-inventory-quantity-field"
                              isConvertToDecimal={false}
                              step={"1"}
                              isHandleKeyDown={true}
                              isBorder={true}
                            />
                          </Col>
                          <Col md={12} className="mb-3">
                            <InputTextArea
                              maxLength={1500}
                              name="description"
                              label="Description (Optional)"
                              placeHolder="Enter Description"
                              className={"border"}
                            />
                          </Col>
                        </Row>
                      </Col>
                      {!isCustomItemTab && (
                        <Col
                          md={12}
                          className="d-flex justify-content-center align-items-center py-2"
                          onClick={toggleCustomItemTab}
                        >
                          <button type="button" className="expand-tab-button">
                            <ExpandTabIcon />
                          </button>
                        </Col>
                      )}
                      {isCustomItemTab && (
                        <>
                          <Col
                            md={12}
                            className="add-inventory-hero-wrapper my-3"
                          >
                            <Row className="m-0">
                              <Col
                                md={12}
                                className="mb-2 tab-heading text-muted"
                              >
                                Product Categorization
                              </Col>

                              <Col md={3} className="mb-2">
                                <CustomItemProductTypeSelectField
                                  setFieldValue={setFieldValue}
                                  customTypes={customTypes}
                                />
                              </Col>
                              <Col md={3} className="mb-2">
                                <CustomItemCategorySelectField
                                  setFieldValue={setFieldValue}
                                  customCategories={customCategories}
                                  values={values}
                                />
                              </Col>
                              <Col md={3} className="mb-2">
                                <CustomItemSubcategorySelectField
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  customTypes={customTypes}
                                  customCategories={customCategories}
                                  customSubCategories={customSubCategories}
                                />
                              </Col>
                              <Col md={3} className="mb-2">
                                <CustomItemConditionSelectField
                                  customTypes={customTypes}
                                  values={values}
                                />
                              </Col>
                              <Col md={12} className="mb-3">
                                <InputTextField
                                  name="upc"
                                  label="UPC (Optional)"
                                  placeHolder="111000294854"
                                  className="border"
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            md={12}
                            className="add-inventory-hero-wrapper my-3"
                          >
                            <Row className="m-0">
                              <Col
                                md={12}
                                className="mb-2 tab-heading text-muted"
                              >
                                Product Tags
                              </Col>
                              <Col md={12}>
                                <ProductTagList
                                  tagsList={[]}
                                  suggestedArrayList={productTags}
                                  onSelectTagsChange={
                                    onCustomProductSelectTagsChange
                                  }
                                  customProductTags={true}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            md={12}
                            className="add-inventory-hero-wrapper my-3"
                          >
                            <Row className="m-0">
                              <Col
                                md={12}
                                className="mb-2 tab-heading text-muted"
                              >
                                Pricing
                              </Col>
                              <Col md={6} className="mb-2">
                                <InputNumberField
                                  label="Stock Price"
                                  name="stockPrice"
                                  placeHolder="0.00"
                                  className="add-inventory-quantity-field"
                                  isBorder={true}
                                  IconImage={DollarIcon}
                                />
                              </Col>
                              <Col md={6} className="mb-2">
                                <InputNumberField
                                  label="Cost of Goods"
                                  name="costOfGoods"
                                  placeHolder="0.00"
                                  className="add-inventory-quantity-field"
                                  isBorder={true}
                                  IconImage={DollarIcon}
                                />
                              </Col>
                            </Row>
                          </Col>

                          <Col
                            md={6}
                            className="d-flex align-items-end gap-2 ms-auto"
                          >
                            <Button
                              label={buttonNameConstants.CANCEL}
                              className="w-100"
                              type="button"
                              handleClick={() => handleHideModal()}
                              buttonType={buttonTypeConstants.GHOST_BUTTON}
                              isDisabled={isSpinnerEnabled(
                                spinnerArray,
                                inventoryConstants.ADD_INVENTORY
                              )}
                            />
                            <Button
                              label={buttonNameConstants.ADD_TO_INVENTORY}
                              className="w-100"
                              type="submit"
                              isDisabled={isSpinnerEnabled(
                                spinnerArray,
                                inventoryConstants.ADD_INVENTORY
                              )}
                            />
                          </Col>
                        </>
                      )}
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </Row>
          {productUrl && (
            <Row className="m-0 w-50">
              <Col md={12}>
                {productUrl && (
                  <iframe
                    title="Product Iframe"
                    src={productUrl}
                    className="iframe-website"
                  />
                )}
              </Col>
            </Row>
          )}
        </Modal.Body>

        {productUrl && (
          <button
            type="button"
            onClick={() => {
              setProductUrl("");
            }}
            className="iframe-toogle-button"
          >
            <SidebarToggleIcon className={"iframe-toggle-icon"} />
          </button>
        )}
      </Modal>
      <ConsolidationModal />
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  inventory: state.inventory,
  tradeDiscount: state.tradeDiscount,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
  isAddModalOpen: state.inventory.isAddModalOpen,
  isConsolidationModal: state.inventory.consolidationModal.isConsolidationModal,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  addInventory: (data, handleClearFilter) =>
    dispatch(inventoryActions.addInventory(data, handleClearFilter)),
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  toggleAddModal: (value) => dispatch(inventoryActions.toggleAddModal(value)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  emptyPriceChartingProductObject: () =>
    dispatch(inventoryActions.emptyPriceChartingProductObject()),
  getInventoryByPricechartingId: (storeId, data) =>
    dispatch(inventoryActions.getInventoryByPricechartingId(storeId, data)),
  getInventoryByProductMetaDataAndConsolidation: (
    storeId,
    productMetaData,
    handleYesButtonClick,
    handleNoButtonClick
  ) =>
    dispatch(
      inventoryActions.getInventoryByProductMetaDataAndConsolidation(
        storeId,
        productMetaData,
        handleYesButtonClick,
        handleNoButtonClick
      )
    ),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(AddInventoryItem);
