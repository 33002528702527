import React, { useRef } from "react";
import { connect } from "react-redux";

import ConfirmationModal from "./ConfirmationModal";
import { globalConstants, inventoryConstants } from "../../../../constants";

const ConsolidationModal = ({
  formRef,
  handleYesButtonClick,
  isConsolidationModal,
  setIsConsolidationModal,
  setConsolidationModal,
  ...props
}) => {
  return (
    <>
      {isConsolidationModal && (
        <ConfirmationModal
          isConfirmationModal={isConsolidationModal}
          handleNoButtonClick={() => {
            setConsolidationModal(
              inventoryConstants.CONSOLIDATION_MODAL_DEFAULT_STATE
            );
          }}
          handleYesButtonClick={() => {
            handleYesButtonClick.functionToCall(
              ...handleYesButtonClick.functionAttributes
            );
            setIsConsolidationModal(false);
          }}
          message="An item with these details already exists. Saving/Adding will update its quantity and delete this version. Proceed?"
        />
      )}
      ;
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  isConsolidationModal: state.inventory.consolidationModal.isConsolidationModal,
  handleYesButtonClick: state.inventory.consolidationModal.handleYesButtonClick,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  setIsConsolidationModal: (data) =>
    dispatch({
      type:
        inventoryConstants.SET_IS_CONSOLIDATION_MODAL + globalConstants.SUCCESS,
      data: data,
    }),
  setConsolidationModal: (data) =>
    dispatch({
      type:
        inventoryConstants.SET_CONSOLIDATION_MODAL + globalConstants.SUCCESS,
      data: data,
    }),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(ConsolidationModal);
