import _ from "lodash"; // Import lodash here
import pokemon from "pokemontcgsdk";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import BatchProductEdit from "./BatchProductEdit";
import AddBatchInventoryFooter from "./AddBatchInventoryFooter";
import SearchInventory from "../SearchInventory/SearchInventory";
import Button from "../../../../shared/components/Buttons/Button";
import {
  PRODUCT_TYPES_ENUMS,
  TRADING_CARD_CATEGORIES_ENUMS,
} from "../../../../system/globalEnums";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { systemActions, inventoryActions } from "../../../../redux/actions";
import { DeleteIcon, EditIcon } from "../../../../assets/icons/iconsProvider";
import TableCustomHeader from "../../../../shared/components/table/TableCustomHeader";
import SelectDropDown from "../../../../shared/components/selectDropdown/SelectDropdown";
import SimpleNumberField from "../../../../shared/components/inputNumberField/SimpleNumberField";
import EditableReactDataTableComponent from "../../../../shared/components/table/EditableReactDataTableComponent";
import {
  toastType,
  toastMessages,
  globalConstants,
  inventoryConstants,
  buttonNameConstants,
  buttonTypeConstants,
  transactionConstants,
  BATCH_INVENTORY_DEFAULT_PRODUCT_OBJECT,
  EMPTY_STRING,
  toggleSwitchButtonConstants,
} from "../../../../constants";
import {
  customToast,
  isSpinnerEnabled,
  handleResetPrices,
  getProductIfExist,
  handleEmptyOnFocus,
  parseToDecimalNumber,
  handleRaritiesChange,
  getProductSubCategory,
  convertToFixedPrecision,
  onChangeProductCondition,
  handleBulkConditionChange,
  setDefaultProductDataObject,
  handleShowNintendoCondition,
  handleAddBatchInventoryPark,
  parseToThousandSeperatorDecimalNumber,
  getInStockProducts,
  isAllSystemHasSerialNumber,
  isSystemProduct,
  isMarketPriceHigh,
} from "../../../../shared/utility";

const AddBatchInventory = (props) => {
  //-------Destructure props
  const {
    customTypes,
    currentStore,
    productTags,
    tradeDiscount,
    spinnerArray,
    activateSpinner,
    handleDraftModal,
    customCategories,
    handleClearFilter,
    deactivateSpinner,
    addBatchInventory,
    setAddBatchInventory,
    isBatchInventoryModal,
    addBatchInventoryDraft,
    addBatchInventorySubmit,
    setIsBatchInventoryModal,
    priceChartingProductInfo,
    emptyPriceChartingProductObject,
  } = props;
  const { markupPresets, tradeinMargin } = tradeDiscount;
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);

  //-------Toggle confirmation modal
  const toggleConfirmationModal = () => {
    setIsConfirmationModal(!isConfirmationModal);
  };

  const handleConfirmNoButtonClick = () => {
    toggleConfirmationModal();
  };

  //-------handle conformation actions
  const handleConfirmYesButtonClick = () => {
    handleClearBatch();
    toggleConfirmationModal();
  };

  //-------column headers for batch inventory table
  const columnHeaders = useMemo(
    () => [
      {
        id: "title",
        name: "Title",
        selector: (row) => row.productName,
        cell: (row) => {
          return (
            <div className="d-flex flex-column">
              <TableCustomHeader
                id={`tool${row.id}`}
                label={
                  <span className="inventory-cell-max-size product-title-max-width">
                    {row.productName}
                  </span>
                }
                tooltopText={row.productName}
              />
              {getProductSubCategory(row.genre) ===
                TRADING_CARD_CATEGORIES_ENUMS.SYSTEM &&
                (currentStore[
                  toggleSwitchButtonConstants
                    .IS_SERIAL_NUMBER_REQUIRED_INVENTORY
                ] ||
                  row?.serialNumber) && (
                  <span
                    className={`${
                      row.serialNumber ? EMPTY_STRING : "text-danger"
                    } batch-seial-number-label`}
                  >
                    S/N : {row?.serialNumber || "Required"}
                  </span>
                )}
            </div>
          );
        },
        sortable: false,
        sortDirection: null,
      },
      {
        id: "condition",
        name: "Condition",
        selector: (row) => row.productCondition,
        cell: (row) => {
          return (
            <SelectDropDown
              menuPlacement="bottom"
              options={row.productConditionList}
              noOptionsMessage="No Type Found"
              placeHolder="Type"
              defaultPageSize={{
                value: row?.productCondition,
                label: handleShowNintendoCondition(
                  row.consoleName,
                  row?.productCondition
                ),
              }}
              handleChange={(e) =>
                onChangeProductCondition(
                  markupPresets,
                  row.id,
                  e.value,
                  false,
                  addBatchInventory,
                  setAddBatchInventory,
                  tradeinMargin
                )
              }
              menuPortalTarget=""
              value={{
                value: row?.productCondition,
                label: handleShowNintendoCondition(
                  row.consoleName,
                  row?.productCondition
                ),
              }}
            />
          );
        },
        sortable: false,
        sortDirection: null,
      },
      {
        id: "rarity",
        name: "Printing",
        selector: (row) => row.rarity,
        cell: (row) => {
          return (
            <SelectDropDown
              menuPlacement="bottom"
              options={row.raritiesList || []}
              noOptionsMessage="No Printing Found"
              placeHolder="Printings"
              defaultPageSize={row.rarity}
              handleChange={(selectedOption) =>
                handleRaritiesChange(
                  row.id,
                  selectedOption,
                  addBatchInventory,
                  setAddBatchInventory,
                  tradeinMargin,
                  false,
                  markupPresets
                )
              }
              value={row.rarity}
              menuPortalTarget=""
            />
          );
        },
        sortable: false,
        sortDirection: null,
      },

      {
        id: "type",
        name: "Type",
        selector: (row) => row.productType,
        cell: (row) => {
          return (
            <span className="inventory-cell-max-size">{row.productType}</span>
          );
        },
        sortable: false,
        sortDirection: null,
        width: "8%",
      },
      {
        id: "category",
        name: "Category",
        selector: (row) => row.consoleName,
        cell: (row) => {
          return (
            <span className="inventory-cell-max-size">{row.consoleName}</span>
          );
        },
        sortable: false,
        sortDirection: null,
      },
      {
        id: "marketPrice",
        name: "Market Price",
        selector: (row) => row.marketPrice,
        cell: (row) => {
          return (
            <span
              className={`inventory-cell-max-size ${
                isMarketPriceHigh(
                  row.marketPrice,
                  row.inStockPrice,
                  productTags,
                  row.tags.map((tag) => tag.label),
                  row.isDataFetching
                )
                  ? "text-danger"
                  : ""
              }`}
            >
              {parseToThousandSeperatorDecimalNumber(row.marketPrice)}
            </span>
          );
        },
        sortable: false,
        sortDirection: null,
        width: "9%",
      },
      {
        id: "quantity",
        name: "Quantity",
        selector: (row) => row?.inStockQuantity ?? 0,
        cell: (row) => {
          return (
            <SimpleNumberField
              step={1}
              minimumValue={1}
              isConvertToDecimal={false}
              value={row?.inStockQuantity ?? 0}
              className={`minimum-number-field max-input-width w-100 `}
              maxValue={inventoryConstants.THRESHOLD_MAX_CASH_VALUE}
              disabled={
                (isSystemProduct(getProductSubCategory(row?.genre)) &&
                  currentStore[
                    toggleSwitchButtonConstants
                      .IS_SERIAL_NUMBER_REQUIRED_INVENTORY
                  ]) ||
                row?.serialNumber
              }
              onChange={(value) => {
                if (value !== 0) {
                  onChangeQuantity(value, row.id);
                }
              }}
              isHandleKeyDown={true}
            />
          );
        },
        sortable: false,
        sortDirection: null,
        width: "8%",
      },
      {
        id: "cogs",
        name: "COGS",
        selector: (row) => row.costOfGoods,
        cell: (row) => {
          return (
            <input
              key={row.costOfGoods}
              min={0}
              type="number"
              defaultValue={row.costOfGoods}
              // value={row.costOfGoods}
              onFocus={handleEmptyOnFocus}
              className="input-textfield editable-inventory-cell-max-size "
              // onChange={(e) => onChangeCostOfGoods(e.target.value, row.id)}
              onBlur={(e) => {
                const inputValue = parseToDecimalNumber(e.target.value); // Convert to number
                onChangeCostOfGoods(inputValue, row.id);
              }}
            ></input>
          );
        },
        sortable: false,
        sortDirection: null,
        width: "8%",
      },
      {
        id: "stockPrice",
        name: "Stock Price",
        selector: (row) => row.inStockPrice,
        cell: (row) => {
          return (
            <input
              key={row.inStockPrice}
              min={0}
              type="number"
              // value={row.inStockPrice}
              defaultValue={row.inStockPrice}
              onFocus={handleEmptyOnFocus}
              className={`input-textfield editable-inventory-cell-max-size ${
                isMarketPriceHigh(
                  row.marketPrice,
                  row.inStockPrice,
                  productTags,
                  row.tags.map((tag) => tag.label),
                  row.isDataFetching
                )
                  ? "border-danger"
                  : ""
              }`}
              // onChange={(e) => onChangeStockPrice(e.target.value, row.id)}
              onBlur={(e) => {
                const inputValue = parseToDecimalNumber(e.target.value); // Convert to number
                onChangeStockPrice(inputValue, row.id);
              }}
            ></input>
          );
        },
        sortable: false,
        sortDirection: null,
        width: "8%",
      },
      {
        id: "inStockPriceAnd",
        name: "",
        cell: (row) => {
          return getProductIfExist(row, onChangeStockPrice);
        },
        sortable: false,
        width: "7%",
      },
      {
        id: "action",
        name: "",
        cell: (row) => {
          return row?.isDataFetching ? (
            <div className="d-flex justify-content-center align-items-center">
              <RotatingLines
                visible={true}
                height="20"
                width="20"
                color="grey"
                strokeColor="#6a18ed"
                strokeWidth="4"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
              />
            </div>
          ) : (
            <>
              <button
                id={`modalTool${row.id}`}
                className="action-Button-Wrapper"
                onClick={() => onDeleteItem(row.id)}
              >
                <DeleteIcon className="cursor-pointer text-primary" />
              </button>
              <button
                id={`modalTool${row.id}`}
                className="action-Button-Wrapper ps-0 pe-0"
                onClick={() => {
                  handleEditCardClick(row);
                }}
              >
                <EditIcon className="cursor-pointer text-primary" />
              </button>
            </>
          );
        },
        sortable: false,
        width: "5%",
      },
    ],
    [addBatchInventory]
  );

  //-------useref
  const asyncSelectRef = useRef();

  //-------useStates
  const [totalCost, setTotalCost] = useState(0);
  const [isModal, setIsModal] = useState(false);
  const [editTradeObject, setEditTradeObject] = useState({});
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();
  const [rowsToUpdateCondition, setRowsToUpdateCondition] = useState([]);
  const [rarities, setRarities] = useState(globalConstants.EMPTY_STRING);
  const [isBulkModal, setIsBulkModal] = useState(false);
  const [bulkUpdateCondition, setBulkUpdateCondition] = useState("");
  const [addInventoryItemModal, setAddInventoryItemModal] = useState(false);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [selectedRarity, setSelectedRarity] = useState(
    globalConstants.EMPTY_STRING
  );
  const [gridState, setGridState] = useState({
    pageSize: 500,
    pageIndex: 0,
    sortBy: [],
  });
  const [productDataObject, setProductDataObject] = useState(
    BATCH_INVENTORY_DEFAULT_PRODUCT_OBJECT
  );

  //------- Show inStock Quantity and Price according
  //------- to condition

  //-------Toggle Add Inventoty Item Modal
  const toggleAddInventoryItemModal = () =>
    setAddInventoryItemModal(!addInventoryItemModal);

  const scrollToTop = () => {
    var tableElement = document.getElementsByClassName("rdt_TableBody")[0];
    if (tableElement) {
      tableElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  //-------handle onchange product quantity
  const onChangeQuantity = (newQuantity, Id) => {
    newQuantity = Number(newQuantity);

    // Find the item to update
    const itemToUpdate = addBatchInventory.find((item) => item.id === Id);

    if (itemToUpdate) {
      // Update the quantity directly
      itemToUpdate.inStockQuantity = newQuantity;

      // Create a new array to trigger React's re-render
      setAddBatchInventory(handlePercentageFlag([...addBatchInventory]));
    }
  };

  //-------handle onchange product cost of goods
  const onChangeCostOfGoods = (newOffer, Id) => {
    const updatedInventory = addBatchInventory.map((item) => {
      if (item.id === Id) {
        return {
          ...item,
          costOfGoods: newOffer,
        };
      }
      return item;
    });
    setAddBatchInventory(handlePercentageFlag(updatedInventory));
  };

  //-------handle onchange product stock price
  const onChangeStockPrice = (newPrice, Id) => {
    const updatedInventory = addBatchInventory.map((item) => {
      if (item.id === Id) {
        return { ...item, inStockPrice: newPrice };
      }
      return item;
    });

    setAddBatchInventory(updatedInventory);
  };

  //-------handle delete item
  const onDeleteItem = (Id) => {
    const updatedInventory = addBatchInventory.filter((item) => item.id !== Id);

    setAddBatchInventory(
      handlePercentageFlag(
        updatedInventory?.map((item, index) => ({ ...item, id: index })) || []
      )
    );
  };

  //-------handle submit add batch inventory
  const handleSubmitAddBatchInventory = async () => {
    activateSpinner(transactionConstants.ADD_TRADE_BATCH_ITEM);
    // validations
    let isInvalid = addBatchInventory.some((inv) => {
      return (
        Number(inv.inStockQuantity) === 0 || Number(inv.inStockPrice) === 0
      );
    });

    if (isInvalid) {
      customToast("Quantity & Stock Price Cannot be 0", toastType.ERROR);
      deactivateSpinner(transactionConstants.ADD_TRADE_BATCH_ITEM);
      return;
    }

    let data = [];
    // const margedBacthInventory = mergeBatchProducts(addBatchInventory);
    const margedBacthInventory = addBatchInventory;
    for (let index = 0; index < margedBacthInventory.length; index++) {
      const product = margedBacthInventory[index];
      const productImage = product.imgUrl;
      data.push({
        // CAUTION SKU VALUE
        sku: [inventoryConstants.AUTO_GENERATED],
        product_id: product.productId,
        product_name: product.productName,
        category_name: product.consoleName,
        store: {
          id: currentStore.id,
          name: currentStore.storeName,
        },
        date_added: new Date(),
        price: {
          unit_purchase_price: product.costOfGoods,
          unit_sell_price: product.inStockPrice,
          quantity: product.inStockQuantity,
          type: product.productCondition,
          marketPrice: product.marketPrice,
        },
        productType: product.productType,
        upc: product.upc,
        additionalCheckList: [],
        tags: product?.tags ? product.tags.map((tag) => tag.label) : [],
        cardRarity: product?.rarity?.label || "",
        cardNumber: "",
        apiSource: product.apiSource,
        imgUrl: product?.imgUrl,
        epid: product.epid,
        tcgPlayerUrl: product.tcgPlayerUrl,
        imgUrl: productImage,
        subcategory:
          product.productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME
            ? getProductSubCategory(product.genre)
            : globalConstants.EMPTY_STRING,
        description: "",
        serialNumber: product.serialNumber,
      });
    }

    deactivateSpinner(transactionConstants.ADD_TRADE_BATCH_ITEM);
    addBatchInventorySubmit(data, handleCloseModal, handleClearFilter);
  };

  //------handle close batch inventory modal
  const handleResetMiscStates = () => {
    emptyPriceChartingProductObject();
    setSelectedRarity("");
    setRarities([]);
    setRowsToUpdateCondition([]);
  };

  const handleCloseModal = (isModalSubmit = false) => {
    setIsBatchInventoryModal(false);
    if (!isModalSubmit && addBatchInventory.length) {
      handleDraftModal(transactionConstants.ADD_BATCH_INVENTORY_DRAFT_MODAL);
    } else {
      setAddBatchInventory([]);
    }
    handleResetMiscStates();
  };

  const handleHideBulkModal = () => {
    setIsBulkModal(!isBulkModal);
  };

  //-------Handle table sort
  const onTableSort = (column, sortDirection) => {
    columnHeaders(
      columnHeaders.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );

    setDefaultSortFieldId(column.id);
  };

  //-------handle edit button click
  const handleEditCardClick = (productDetail) => {
    setEditTradeObject(productDetail);
    toggleAddInventoryItemModal();
  };

  //-------handle add to cart batch tarde
  const addToCartInBatchTrade = (editProduct) => {
    // Find the index of the item to be edited
    const index = addBatchInventory.findIndex(
      (item) => item.id === editProduct.id
    );

    if (index !== -1) {
      // Remove the item from its original position
      const updatedInventory = [
        ...addBatchInventory.slice(0, index),
        ...addBatchInventory.slice(index + 1),
      ];

      // Insert the edited product at the same index
      updatedInventory.splice(index, 0, editProduct);

      // Update the state with the updated inventory and reset other states
      setAddBatchInventory(handlePercentageFlag(updatedInventory));
      setEditTradeObject("");
    }
  };

  //-------handle onchnage cogs
  const handleCogsValueChange = (value) => {
    if (Number(value) !== Number(totalCost)) {
      let isInvalid = addBatchInventory.some((inv) => {
        return (
          Number(inv.inStockQuantity) === 0 ||
          Number(inv.costOfGoods) === 0 ||
          inv.costOfGoods === ""
        );
      });
      if (isInvalid) {
        customToast("Quantity , Cost Of Goods Cannot be 0", toastType.ERROR);
        return;
      }
      const inputValue = convertToFixedPrecision(value);
      setTotalCost(inputValue);
      const updatedBatchInventory = addBatchInventory.map((item) => {
        const { cogsPercentage, inStockQuantity } = item;
        const costOfGoods =
          (inputValue * cogsPercentage) / 100 / inStockQuantity;
        return {
          ...item,
          costOfGoods: convertToFixedPrecision(costOfGoods),
        };
      });
      setAddBatchInventory(handlePercentageFlag(updatedBatchInventory));
    }
  };
  const handleRowSelectionChange = useCallback((state) => {
    setRowsToUpdateCondition(state.selectedRows);
  }, []);

  const handleBulkUpdateCondition = () => {
    const sameProductType = rowsToUpdateCondition.every(
      (item, index, array) => {
        return index === 0 || item["productType"] === array[0]["productType"];
      }
    );

    if (sameProductType) {
      setIsBulkModal(!isBulkModal);
    } else {
      customToast("Selected rows has not same product Type", toastType.ERROR);
    }
  };

  const handleBulkDelete = () => {
    const itemToDeleteId = rowsToUpdateCondition.map((item) => item.id);

    const updatedInventory = addBatchInventory.filter(
      (item) => !itemToDeleteId.includes(item.id)
    );
    setAddBatchInventory(
      handlePercentageFlag(
        updatedInventory?.map((item, index) => ({ ...item, id: index })) || []
      )
    );
    setToggleClearRows(!toggledClearRows);
    setRowsToUpdateCondition([]);
    // Additional actions, such as showing a success message or triggering further logic
  };

  const handleBulkSubmit = () => {
    if (bulkUpdateCondition) {
      const bulkUpdateItemIds = rowsToUpdateCondition.map((item) => item.id);
      activateSpinner(inventoryConstants.BULK_UPDATE);

      handleBulkConditionChange(
        markupPresets,
        bulkUpdateCondition?.value,
        bulkUpdateItemIds,
        false,
        addBatchInventory,
        setAddBatchInventory,
        tradeinMargin,
        handlePercentageFlag
      );

      setIsBulkModal(false);
      setRowsToUpdateCondition([]);
      setBulkUpdateCondition("");
      setToggleClearRows(!toggledClearRows);
      deactivateSpinner(inventoryConstants.BULK_UPDATE);
    } else {
      customToast("Condition not selected", toastType.ERROR);
    }
  };

  //-------UseEffect

  //-------update product condition and type
  useEffect(() => {
    if (priceChartingProductInfo && isBatchInventoryModal) {
      activateSpinner(inventoryConstants.ADD_ITEM_IN_BATCH);
      setDefaultProductDataObject(
        markupPresets,
        setRarities,
        currentStore,
        inventoryActions,
        productDataObject,
        addBatchInventory,
        setSelectedRarity,
        tradeinMargin,
        true,
        setProductDataObject,
        setAddBatchInventory,
        priceChartingProductInfo,
        handlePercentageFlag,
        scrollToTop,
        emptyPriceChartingProductObject
      );
      deactivateSpinner(inventoryConstants.ADD_ITEM_IN_BATCH);
    }
  }, [priceChartingProductInfo]);

  // for Calculate each product percentage
  const handlePercentageFlag = (percentageFlagBatchInv) => {
    const totalCost = percentageFlagBatchInv.reduce((acc, currentInv) => {
      return Number(currentInv.costOfGoods * currentInv.inStockQuantity) + acc;
    }, 0);

    return percentageFlagBatchInv.map((item) => {
      const itemTotalCostOfGoods = Number(
        item.costOfGoods * item.inStockQuantity
      );
      const cogsPercentage =
        totalCost > 0 ? (itemTotalCostOfGoods / totalCost) * 100 : 0;

      return {
        ...item,
        cogsPercentage: convertToFixedPrecision(cogsPercentage),
        costOfGoods:
          cogsPercentage === 0
            ? inventoryConstants.DEFAULT_OFFER_VALUE
            : item.costOfGoods,
      };
    });
  };

  const handleResetBatch = () => {
    handleResetPrices(
      markupPresets,
      false,
      addBatchInventory,
      setAddBatchInventory,
      tradeinMargin,
      handlePercentageFlag
    );
  };

  //------ handle clear batch
  const handleClearBatch = () => {
    setAddBatchInventory([]);
  };
  const prevLength = useRef(addBatchInventory?.length);
  useEffect(() => {
    if (addBatchInventory?.length > prevLength.current) {
      getInStockProducts(
        currentStore,
        inventoryActions,
        addBatchInventory,
        setAddBatchInventory,
        markupPresets,
        handlePercentageFlag,
        tradeinMargin
      );
    }
    prevLength.current = addBatchInventory?.length;
  }, [addBatchInventory?.length]);

  useEffect(() => {
    const totalCost = addBatchInventory.reduce((acc, currentInv) => {
      return convertToFixedPrecision(
        Number(currentInv.costOfGoods * currentInv.inStockQuantity) + acc
      );
    }, 0);
    setTotalCost(totalCost);
  }, [addBatchInventory, productDataObject]);

  return (
    <>
      <Modal
        size="lg"
        centered
        animation={true}
        backdrop="static"
        onHide={
          isSpinnerEnabled(spinnerArray, inventoryConstants.ADD_INVENTORY)
            ? ""
            : handleCloseModal
        }
        dialogClassName="modal-90w"
        show={isBatchInventoryModal}
        className={`add-inventory-modal ${isModal && "d-none"}`}
      >
        <Modal.Header closeButton className="add-inventory-modal-header">
          <Modal.Title>
            <span className="add-inventory-modal-header-name">
              Add Batch Inventory
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-inventory-modal-body py-0">
          {/* search inventory */}
          <Row className="m-0">
            <Col md={9} className="mb-0 ps-md-0">
              <SearchInventory
                customTypes={customTypes}
                asyncSelectRef={asyncSelectRef}
                customCategories={customCategories}
              />
            </Col>
            {rowsToUpdateCondition?.length ? (
              <Col md={3} className="d-flex justify-content-end pe-md-0 gap-2">
                <Button
                  label={buttonNameConstants.DELETE}
                  type="button"
                  buttonType={buttonTypeConstants.RED_BUTTON}
                  handleClick={handleBulkDelete}
                  isDisabled={isSpinnerEnabled(spinnerArray, [
                    inventoryConstants.ADD_INVENTORY,
                    transactionConstants.ADD_TRADE_BATCH_ITEM,
                  ])}
                />
                <Button
                  label={buttonNameConstants.BULK_UPDATE}
                  type="button"
                  handleClick={handleBulkUpdateCondition}
                  isDisabled={isSpinnerEnabled(spinnerArray, [
                    inventoryConstants.ADD_INVENTORY,
                    transactionConstants.ADD_TRADE_BATCH_ITEM,
                  ])}
                />
              </Col>
            ) : addBatchInventory?.length ? (
              <Col md={3} className="d-flex justify-content-end pe-md-0 gap-2">
                {!rowsToUpdateCondition?.length && (
                  <Button
                    label={buttonNameConstants.CLEAR}
                    type="button"
                    buttonType={buttonTypeConstants.LIGHT_PURPLE}
                    handleClick={() => {
                      toggleConfirmationModal();
                    }}
                    isDisabled={isSpinnerEnabled(spinnerArray, [
                      inventoryConstants.ADD_INVENTORY,
                      transactionConstants.ADD_TRADE_BATCH_ITEM,
                    ])}
                  />
                )}
                <Button
                  label={buttonNameConstants.SAVE}
                  type="button"
                  handleClick={() => {
                    handleAddBatchInventoryPark(
                      addBatchInventory,
                      currentStore,
                      addBatchInventoryDraft
                    );
                    handleResetMiscStates();
                  }}
                  isDisabled={isSpinnerEnabled(spinnerArray, [
                    inventoryConstants.ADD_INVENTORY,
                    transactionConstants.ADD_TRADE_BATCH_ITEM,
                  ])}
                />
              </Col>
            ) : (
              ""
            )}
          </Row>
          {/* main body */}
          <Row>
            <EditableReactDataTableComponent
              selectableRows
              columns={columnHeaders}
              onSort={onTableSort}
              gridState={gridState}
              data={[...addBatchInventory].reverse()}
              setGridState={setGridState}
              clearSelectedRows={toggledClearRows}
              total={addBatchInventory?.length}
              defaultSortFieldId={defaultSortFieldId}
              handleRowSelectionChange={handleRowSelectionChange}
            />
          </Row>
          {/*Modal footer */}
          <AddBatchInventoryFooter
            handleCogsValueChange={handleCogsValueChange}
            closeModal={handleCloseModal}
            spinnerArray={spinnerArray}
            handleResetBatch={handleResetBatch}
            handleSubmitAddBatchInventory={handleSubmitAddBatchInventory}
            currentStore={currentStore}
            addBatchInventory={addBatchInventory}
            data={{
              totalItems: addBatchInventory.reduce((acc, currentInv) => {
                return Number(currentInv.inStockQuantity) + acc;
              }, 0),
              totalCost: totalCost,
              totalPrice: addBatchInventory.reduce((acc, currentInv) => {
                return (
                  Number(currentInv.inStockPrice * currentInv.inStockQuantity) +
                  acc
                );
              }, 0),
              totalMarketPrice: addBatchInventory.reduce((acc, currentInv) => {
                return (
                  Number(currentInv.marketPrice * currentInv.inStockQuantity) +
                  acc
                );
              }, 0),
            }}
          />
        </Modal.Body>
      </Modal>
      {addInventoryItemModal && (
        <BatchProductEdit
          addToCart={addToCartInBatchTrade}
          editProductObject={editTradeObject}
          isBatchProductEditModal={addInventoryItemModal}
          toggleIsBatchProductEditModal={toggleAddInventoryItemModal}
        />
      )}
      <Modal
        show={isBulkModal}
        size="sm"
        onHide={handleHideBulkModal}
        animation={true}
        centered
        backdrop="static"
        className={"add-inventory-modal bulk-modal"}
      >
        <Modal.Header closeButton className="add-inventory-modal-header">
          <Modal.Title>
            <span className="add-inventory-modal-header-name">Bulk Update</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-inventory-modal-body d-flex ">
          <Row className="m-0">
            <Col md={4} className="ps-md-0 d-flex align-items-center mb-3">
              Condition
            </Col>
            <Col md={8} className="pe-md-0  mb-3">
              <SelectDropDown
                menuPortalTarget=""
                menuPlacement="bottom"
                handleChange={(e) => {
                  setBulkUpdateCondition(e);
                }}
                options={
                  rowsToUpdateCondition[0]?.productType ===
                  PRODUCT_TYPES_ENUMS.VIDEO_GAME
                    ? inventoryConstants.VIDEO_GAME_CONDITION
                    : rowsToUpdateCondition[0]?.productType ===
                      PRODUCT_TYPES_ENUMS.TRADING_CARD
                    ? inventoryConstants.TRADING_CARD_CONDITION
                    : inventoryConstants.OTHER_CONDITION
                }
                value={bulkUpdateCondition}
              />
            </Col>
            <Col md={6} className="ps-md-0">
              <Button
                label={buttonNameConstants.CANCEL}
                className="w-50"
                type="button"
                handleClick={() => {
                  setIsBulkModal(false);
                }}
                buttonType={buttonTypeConstants.GHOST_BUTTON}
              />
            </Col>
            <Col md={6}>
              <Button
                label={buttonNameConstants.UPDATE}
                className="w-50"
                type="button"
                handleClick={handleBulkSubmit}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>{" "}
      <ConfirmationModal
        message={toastMessages.CLEAR_BATCH_WARNING}
        isConfirmationModal={isConfirmationModal}
        handleNoButtonClick={handleConfirmNoButtonClick}
        handleYesButtonClick={handleConfirmYesButtonClick}
        toggleConfirmationModal={toggleConfirmationModal}
      />
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  inventory: state.inventory,
  tradeDiscount: state.tradeDiscount,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
  productTags: state.inventory.productTags,
  allCategories: state.inventory.allCategories,
  addBatchInventory: state.inventory.addBatchInventory,
  priceChartingProductInfo: state.inventory.priceChartingProductInfo,
  isBatchInventoryModal: state.inventory.isBatchInventoryModal,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  addBatchInventorySubmit: (data, handleCloseModal, handleClearFilter) =>
    dispatch(
      inventoryActions.addBatchInventory(
        data,
        handleCloseModal,
        handleClearFilter
      )
    ),
  emptyPriceChartingProductObject: () =>
    dispatch(inventoryActions.emptyPriceChartingProductObject()),
  getPricechartingProductById: (productMetaData, productId) =>
    dispatch(
      inventoryActions.getPricechartingProductById(productMetaData, productId)
    ),
  setPokemonProductInReducer: (productMetaData, value) =>
    dispatch(
      inventoryActions.setPokemonProductInReducer(productMetaData, value)
    ),
  getScryFallProductById: (productMetaData, productId) =>
    dispatch(
      inventoryActions.getScryFallProductById(productMetaData, productId)
    ),
  setAddBatchInventory: (data) =>
    dispatch({
      type:
        inventoryConstants.SET_ADD_BATCH_INVENTORY + globalConstants.SUCCESS,
      data: data,
    }),
  handleDraftModal: (isToggle) =>
    dispatch({
      type: transactionConstants.SET_DRAFT_CONFIRMATION,
      data: isToggle,
    }),
  setIsBatchInventoryModal: (isModalOpen) =>
    dispatch({
      type: inventoryConstants.SET_IS_BATCH_INVENTORY + globalConstants.SUCCESS,
      data: isModalOpen,
    }),
  addBatchInventoryDraft: (data) =>
    dispatch(inventoryActions.addBatchInventoryDraft(data)),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(AddBatchInventory);
