import {
  printerConstants,
  transactionConstants,
  EMPTY_STRING,
} from "../../../constants";
import {
  isSystemProduct,
  parseToThousandSeperatorDecimalNumber,
} from "../../utility";

const sliceItemName = (itemName) => {
  return itemName.length >= printerConstants.RECEIPT_ITEM_MAX_LENGTH
    ? `${itemName.slice(0, printerConstants.RECEIPT_ITEM_MAX_LENGTH)}...`
    : itemName +
        printerConstants.SPACE.repeat(
          printerConstants.RECEIPT_ITEM_MAX_LENGTH + 3 - itemName.length
        );
};

const prepareItemRow = (itemName, quantity, price) => {
  const formatteditemName = sliceItemName(itemName);
  const formattedString = `${formatteditemName.padEnd(
    printerConstants.RECEIPT_ITEM_MAX_LENGTH +
      printerConstants.DOUBLE_SPACE_AFTER_ITEM,
    " "
  )}  ${quantity
    .slice(0, printerConstants.RECEIPT_QTY_MAX_LENGTH)
    .padEnd(printerConstants.RECEIPT_QTY_MAX_LENGTH, " ")}  ${price
    .slice(-printerConstants.RECEIPT_PRICE_MAX_LENGTH)
    .padStart(printerConstants.RECEIPT_PRICE_MAX_LENGTH, " ")}`;
  const itemRow = formattedString.slice(0, printerConstants.RECEIPT_ROW_LENGTH);
  return itemRow;
};

const getItemsList = (receiptItems, giftReceipt) => {
  let itemsList = receiptItems.map(
    (item) =>
      `${prepareItemRow(
        item.itemName,
        item.quantity.toString(),
        `${
          !giftReceipt ? `$${Number(item.priceAfterDiscount).toFixed(2)}` : ""
        }`
      )}\x0A${
        isSystemProduct(item?.subcategory) && item?.serialNumber
          ? `${prepareItemRow(
              `S/N:${item.serialNumber}`,
              EMPTY_STRING,
              EMPTY_STRING
            )}\x0A`
          : ""
      }`
  );
  return itemsList;
};
// const getItemsList = (receiptItems) => {
//   let itemsList = receiptItems.map(
//     (item) =>
//       `${prepareItemRow(
//         item.itemName,
//         item.quantity.toString(),
//         `$${Number(item.priceAfterDiscount).toFixed(2)}`
//       )}\x0A`
//   );
//   return itemsList;
// };

const alignValueLeftAndRight = (leftItem, rightItem) => {
  const lenVal1 = Math.min(leftItem.length, printerConstants.LEFT_ITEM_LENGTH); // Maximum length for val1
  const lenVal2 = Math.min(
    rightItem.length,
    printerConstants.RIGHT_ITEM_LENGTH
  ); // Maximum length for val2

  const formattedVal1 = leftItem
    .slice(0, lenVal1)
    .padEnd(printerConstants.LEFT_ITEM_LENGTH, " ");
  const formattedVal2 = rightItem
    .slice(0, lenVal2)
    .padStart(printerConstants.RIGHT_ITEM_LENGTH, " ");

  const result = formattedVal1 + "  " + formattedVal2;
  return result.slice(0, printerConstants.RECEIPT_ROW_LENGTH); // Ensure the total length is 42
};

export const prepareReceiptForPrinterTrade = (
  user,
  id,
  type,
  storeDetails,
  selectedCustomer,
  receiptItems,
  discountAmount,
  subTotal,
  taxAmount,
  totalPrice,
  cashTenderedAmount,
  cashOwedAmount,
  openCashDrawer,
  partialPayment,
  giftReceipt,
  printTime
) => {
  const dateTime = printTime;
  const currency = "$";
  const getTotalItemsQuantity = () => {
    let totalQuantity = 0;
    receiptItems.map((item) => {
      totalQuantity += Number(item.quantity);
    });

    return totalQuantity;
  };
  const storeLogoImage = storeDetails?.showOnReceipt
    ? {
        type: "raw",
        format: "image",
        flavor: "file",
        data: storeDetails?.logo,
        options: {
          language: "ESCPOS",
          dotDensity: "double",
          width: 20,
        },
      }
    : "";

  const receiptData = [
    `${
      type?.totalPaidAmount[transactionConstants.CASH] > 0 && openCashDrawer
        ? "\x10" + "\x14" + "\x01" + "\x00" + "\x05"
        : "" // Generate Pulse to kick-out cash drawer**
    }`,
    storeLogoImage,
    `${storeDetails?.showOnReceipt ? "\x0A" : ""}`,
    "\x1B" + "\x40", // init
    "\x1B" + "\x61" + "\x31", // center align
    "\x1B" + "\x21" + "\x30", // em mode on
    //Store Name
    `${storeDetails?.storeName}`,
    "\x1B" + "\x21" + "\x0A" + "\x1B" + "\x45" + "\x0A", // em mode off
    "\x0A", //Line Break
    "\x0A", //Line Break
    //Store Address
    `${storeDetails?.phoneNumber}` + "\x0A",
    `${`${storeDetails.line1} ${storeDetails.line2}`}` + "\x0A",
    `${`${storeDetails.city} ${storeDetails.state} ${storeDetails.zip}`}` +
      "\x0A",
    "\x0A", // line break
    "\x1B" + "\x4D" + "\x30", // normal text
    "__________________________________________" + "\x0A",
    "\x0A", // line break
    "\x1B" + "\x61" + "\x30", // left align
    //Receipt Details
    `Sale ID : ${id}` + "\x0A",
    `Associate : ${`${user.firstName} ${user.lastName}`}` + "\x0A",
    `Date : ${dateTime}` + "\x0A",
    "\x0A", // line break

    "__________________________________________" + "\x0A",

    "\x0A", // line break
    `${
      selectedCustomer?.id && selectedCustomer?.firstName
        ? `Customer : ${selectedCustomer?.firstName} ${selectedCustomer?.lastName}` +
          "\x0A" +
          `Store Credit: ${parseToThousandSeperatorDecimalNumber(
            selectedCustomer?.currentBalance
          )}` +
          "\x0A"
        : selectedCustomer?.name
        ? `Customer : ${selectedCustomer?.name} ` + "\x0A"
        : ""
    }`,
    `${
      selectedCustomer?.id && selectedCustomer?.firstName
        ? "__________________________________________" + "\x0A" + "\x0A"
        : selectedCustomer?.name
        ? "__________________________________________" + "\x0A" + "\x0A"
        : ""
    }`,

    "\x1B" + "\x45" + "\x0D", // bold on
    "\x1B" + "\x61" + "\x30", // left align
    //Items List
    `${prepareItemRow("Item", "Qty", !giftReceipt ? "Price" : "")}`,
    "\x1B" + "\x45" + "\x0A", // bold off
    "\x0A", // line break
    `${getItemsList(receiptItems, giftReceipt).join("")}`,
    // `${getItemsList(receiptItems).join("")}`, // line break
    "__________________________________________" + "\x0A",
    "\x0A", // line break
    //Total Items
    giftReceipt
      ? ""
      : `${alignValueLeftAndRight(
          "Total Items :",
          `${getTotalItemsQuantity()}`
        )}` +
        "\x0A" +
        // Subtotal
        `${
          !partialPayment
            ? alignValueLeftAndRight(
                "Subtotal :",
                `${currency}${Number(subTotal).toFixed(2)}`
              )
            : ""
        }` +
        "\x0A" +
        // Discount
        `${alignValueLeftAndRight(
          `Discount :`,
          `${currency}${Number(discountAmount).toFixed(2)}`
        )}` +
        "\x0A" +
        // Tax
        `${alignValueLeftAndRight(
          `Tax(${storeDetails.defaultTax}%) :`,
          `${currency}${Number(taxAmount).toFixed(2)}`
        )}` +
        "\x0A" +
        // Total Amount
        `${alignValueLeftAndRight(
          "Total Amount :",
          `${currency}${Number(totalPrice).toFixed(2)}`
        )}` +
        "\x0A" +
        "__________________________________________" +
        "\x0A",

    //Paymnet History
    giftReceipt
      ? ""
      : `Payment :` +
        "\x0A" +
        `${
          type?.totalPaidAmount[transactionConstants.CREDIT_CARD] > 0
            ? `${alignValueLeftAndRight(
                "Paid Credit Card:",
                `${currency}${
                  type?.totalPaidAmount[transactionConstants.CREDIT_CARD]
                }`
              )}` + "\x0A"
            : ""
        }` +
        `${
          type?.totalPaidAmount[transactionConstants.GIFT_CARD] > 0
            ? `${alignValueLeftAndRight(
                "Paid Gift Card:",
                `${currency}${
                  type?.totalPaidAmount[transactionConstants.GIFT_CARD]
                }`
              )}` + "\x0A"
            : ""
        }` +
        `${
          type?.totalPaidAmount[transactionConstants.STORE_CREDIT] > 0
            ? `${alignValueLeftAndRight(
                "Paid Store Credit:",
                `${currency}${
                  type?.totalPaidAmount[transactionConstants.STORE_CREDIT]
                }`
              )}` + "\x0A"
            : ""
        }` +
        `${
          type?.totalPaidAmount[transactionConstants.CASH] > 0
            ? `${alignValueLeftAndRight(
                "Paid Cash:",
                `${currency}${type?.totalPaidAmount[transactionConstants.CASH]}`
              )}` + "\x0A"
            : ""
        }` +
        "__________________________________________" +
        "\x0A",

    "\x0A", // line break

    "\x1B" + "\x61" + "\x31", // center align
    "\x1D\x68\x64", // Set barcode height (104 dots)
    "\x1D\x77\x02", // Set barcode width (module 2)
    `\x1D\x6B\x45\x0B${id}\x00`,
    "\x0A", // line break
    `${id}` + "\x0A" + "\x0A",
    //Return Policy
    // `${storeDetails?.returnPolicy ? storeDetails.returnPolicy : ""}`,
    "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A",
    "\x1B" + "\x69", // cut paper (old syntax)
    //"\x1D" + "\x56" + "\x01", // partial cut (new syntax)
  ];

  return receiptData;
};
