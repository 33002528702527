import { stringToBase64 } from "../shared/utility";
import {
  deleteRequestWithData,
  getRequest,
  postRequest,
  putRequest,
} from "../system/serverCall";
import serverResponseHandler from "../system/serverResponseHandler";

//-------Get all Transaction from server
const getAllTransaction = (storeId, type, start, end, gridState) => {
  return getRequest(
    `get-transaction/${storeId}?type=${
      type == "Sale" ? "Transaction" : type
    }&startDate=${start}&endDate=${end}&search=${
      gridState.searchQuery
    }&pageNumber=${gridState.pageIndex}&pageSize=${
      gridState.pageSize
    }&filters=${stringToBase64(gridState.filters)}&sortFilter=${stringToBase64(
      gridState.sortFilter
    )}`,
    true
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//-------Get all gDraft from server
const getAllDraft = (storeId, type, start, end, cat) => {
  return getRequest(
    `get-drafts?store=${storeId}&TransactionType=${type}&startDate=${start}&endDate=${end}${
      cat ? `&category=${cat}` : ""
    }`,
    true
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//-------Get search Transaction from server
const getSearchTransaction = (storeId, id) => {
  return getRequest(`transaction/${storeId}/${id}`, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//------addReturn
const addReturn = (returnData, storeId) => {
  return postRequest(`add-return/${storeId}`, returnData, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//------getReturnId
const getReturnId = () => {
  return getRequest(`get-return-id`, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get Gift Card
const getGiftCardDetails = (storeId, barcode) => {
  return getRequest(`gift-cards/${storeId}?giftCardBarcode=${barcode}`).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

// gettransaction id
const getTransactionId = () => {
  return getRequest(`get-transaction-id`).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------make transaction
const newSaleTransaction = (data, isUndoTransaction) => {
  return postRequest(
    `add-transaction${isUndoTransaction ? "?isUndoTransaction=true" : ""}`,
    data
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------update customer in transaction history
const updateCustomerInTransactionHistory = (data, gridState, endPoint) => {
  return putRequest(
    `${endPoint}/${gridState.storeId}/${gridState.id}`,
    data
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------make transaction
const cancelCardTransaction = (data) => {
  return postRequest("abort-card-payment", data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//-------bulk upload history
const bulkUploadTransactionHistory = (storeId, data) => {
  return postRequest(`bulk-upload-transaction-history/${storeId}`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------make newTradeTransaction
const newTradeTransaction = (data, storeId) => {
  return postRequest(`add-trade/${storeId}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

// gettransaction id
const getTradeId = () => {
  return getRequest(`get-trade-id`).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------make newTradeTransaction
const newTradePark = (data, storeId) => {
  return postRequest(`add-trade/${storeId}`, data).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//-------bulk upload history
const sendEmailOrSms = (data) => {
  return postRequest(`send-email-and-sms`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Delete deleteDraft
const deleteDraft = (transactionId) => {
  return deleteRequestWithData(`get-drafts`, transactionId).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Export Transaction Services
export const transactionService = {
  addReturn,
  getTradeId,
  deleteDraft,
  getAllDraft,
  getReturnId,
  newTradePark,
  sendEmailOrSms,
  getTransactionId,
  getAllTransaction,
  newSaleTransaction,
  getGiftCardDetails,
  newTradeTransaction,
  getSearchTransaction,
  cancelCardTransaction,
  bulkUploadTransactionHistory,
  updateCustomerInTransactionHistory,
};
