import React from "react";
import { useNavigate } from "react-router";
import { useErrorBoundary } from "react-error-boundary";

import Button from "../../../shared/components/Buttons/Button";
import {
  buttonNameConstants,
  buttonTypeConstants,
  routesPathConstants,
} from "../../../constants";
import ErrorIcon from "../../../assets/images/ErrorIcon.svg";
import { clearStorage } from "../../../system/storage";

const ErrorScreen = () => {
  const { resetBoundary } = useErrorBoundary();
  const navigate = useNavigate();

  const handleButtonClick = (isReport) => {
    resetBoundary();
    clearStorage(false, true, false);
    if (isReport) {
      navigate(routesPathConstants.REPORT_ISSUE, {
        state: { errorScreenUrl: window.location.href },
      });
    } else {
      navigate(routesPathConstants.DEFAULT_PATH);
    }
  };

  return (
    <div className="vw-100 vh-100 d-flex flex-column gap-3 justify-content-center align-items-center">
      <img src={ErrorIcon} className="error-icon" />
      <h2> Something went wrong! </h2>
      <h5 className="d-flex gap-2">
        Report your problem at
        <a
          href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
          className="text-decoration-underline cursor-pointer"
        >
          <h5>{process.env.REACT_APP_SUPPORT_EMAIL}</h5>
        </a>
      </h5>
      <span className="d-flex align-items-center justify-content-center gap-2"></span>
      <div className="d-flex  justify-content-center align-items-center gap-2">
        <Button
          label={buttonNameConstants.REPORT_PROBLEM}
          handleClick={() => handleButtonClick(true)}
        />
        <Button
          label={buttonNameConstants.BACK}
          handleClick={() => handleButtonClick(false)}
          buttonType={buttonTypeConstants.GHOST_BUTTON}
        />
      </div>
    </div>
  );
};

export default ErrorScreen;
