import { custom } from "./actionsUtilities";
import { success } from "./actionsUtilities";
import { storeService } from "../../services";
import {
  customToast,
  getProductLabelNotifications,
} from "../../shared/utility";
import ToastPrintLabel from "../../shared/components/ToastPrintLabel/ToastPrintLabel";
import {
  toastType,
  toastMessages,
  storeConstants,
  globalConstants,
  systemConstants,
  dashboardConstants,
  routesPathConstants,
  sessionStorageConstants,
  EMPTY_STRING,
} from "../../constants";
import {
  isItemInStorage,
  setItemToStorage,
  getItemFromStorage,
} from "../../system/storage";
import { NOTIFICATION_TYPES } from "../../system/globalEnums";

const getStores = (userId) => {
  return (dispatch) => {
    dispatch(
      custom(storeConstants.GET_STORES, systemConstants.SPINNER_ACTIVATE)
    );
    storeService
      .getStores(userId)
      .then(
        (response) => {
          dispatch(success(response, storeConstants.GET_STORES));
          if (isItemInStorage(sessionStorageConstants.STORE_INDEX)) {
            // if invalid store index then assign 0 and set store index to 0
            let validStoreIndex = 0;
            if (
              Number(getItemFromStorage(sessionStorageConstants.STORE_INDEX)) >
              Number(response.length) - 1
            ) {
              validStoreIndex = 0;
              setItemToStorage(
                sessionStorageConstants.STORE_INDEX,
                validStoreIndex
              );
            } else {
              validStoreIndex = getItemFromStorage(
                sessionStorageConstants.STORE_INDEX
              );
            }
            dispatch(
              success(
                response[validStoreIndex],
                storeConstants.SET_CURRENT_STORE
              )
            );
          } else {
            setItemToStorage(sessionStorageConstants.STORE_INDEX, 0);
            dispatch(success(response[0], storeConstants.SET_CURRENT_STORE));
          }
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(storeConstants.GET_STORES, systemConstants.SPINNER_DEACTIVATE)
        );
      });
  };
};

const addStore = (data) => {
  return (dispatch) => {
    dispatch(
      custom(storeConstants.ADD_STORE, systemConstants.SPINNER_ACTIVATE)
    );
    storeService
      .addStore(data)
      .then(
        (response) => {
          dispatch(getStores(globalConstants.EMPTY_STRING));

          dispatch(
            custom(
              routesPathConstants.ADMIN_STORES,
              systemConstants.TOGGLE_REDIRECT_PAGE
            )
          );
          customToast(
            toastMessages.STORE_ADDED_SUCCESSFULLY,
            toastType.SUCCESS
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(storeConstants.ADD_STORE, systemConstants.SPINNER_DEACTIVATE)
        );
      });
  };
};

const editStore = (data) => {
  return (dispatch) => {
    dispatch(
      custom(storeConstants.ADD_STORE, systemConstants.SPINNER_ACTIVATE)
    );
    storeService
      .editStore(data)
      .then(
        (response) => {
          dispatch(
            custom(
              routesPathConstants.ADMIN_STORES,
              systemConstants.TOGGLE_REDIRECT_PAGE
            )
          );
          customToast(
            toastMessages.STORE_UPDATED_SUCCESSFULLY,
            toastType.SUCCESS
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(storeConstants.ADD_STORE, systemConstants.SPINNER_DEACTIVATE)
        );
      });
  };
};

const getStoreOwners = (userId) => {
  return (dispatch) => {
    dispatch(custom(null, systemConstants.SPINNER_ACTIVATE));
    storeService
      .getStoreOwners(userId)
      .then(
        (response) => {
          dispatch(success(response, storeConstants.GET_STORE_OWNERS));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(custom(null, systemConstants.SPINNER_DEACTIVATE));
      });
  };
};

const deleteStore = (storeId) => {
  return (dispatch) => {
    dispatch(custom(null, systemConstants.SPINNER_ACTIVATE));
    storeService
      .deleteStore(storeId)
      .then(
        (response) => {
          setItemToStorage(sessionStorageConstants.STORE_INDEX, 0);
          dispatch(getStores(globalConstants.EMPTY_STRING));
          customToast(
            toastMessages.STORE_DELETED_SUCCESSFULLY,
            toastType.SUCCESS
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(custom(null, systemConstants.SPINNER_DEACTIVATE));
      });
  };
};

const uploadLogo = (id, logoPath, data) => {
  return (dispatch) => {
    dispatch(
      custom(storeConstants.UPLOAD_LOGO, systemConstants.SPINNER_ACTIVATE)
    );
    storeService
      .uploadLogo(logoPath)
      .then(
        (response) => {
          const filePath = response?.data?.data;
          const receiptData = { ...data, logo: filePath };
          dispatch(updateReceiptDetails(id, receiptData));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(storeConstants.UPLOAD_LOGO, systemConstants.SPINNER_DEACTIVATE)
        );
      });
  };
};

const updateReceiptDetails = (id, data) => {
  return (dispatch) => {
    dispatch(
      custom(
        storeConstants.UPDATE_RECEIPT_DETAILS,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    storeService
      .updateReceiptDetails(id, data)
      .then(
        (response) => {
          dispatch(getStores(globalConstants.EMPTY_STRING));
          customToast(
            toastMessages.RECEIPT_DETAILS_UPDATED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(getStores(globalConstants.EMPTY_STRING));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            storeConstants.UPDATE_RECEIPT_DETAILS,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const getUserNotifications = (
  storeId,
  inventoryUpdateNotification = false,
  message = "",
  isUpdate = false,
  isPrintLabel = true
) => {
  return (dispatch) => {
    storeService.getUserNotifications(storeId).then(
      (response) => {
        dispatch(success(response, storeConstants.GET_USER_NOTIFICATIONS));
        if (inventoryUpdateNotification) {
          const notificationMessage =
            getProductLabelNotifications(response)[0]?.message ?? EMPTY_STRING;
          customToast(
            <span className="d-flex flex-column gap-2">
              {isUpdate || !isPrintLabel ? message : notificationMessage}
              {isPrintLabel ? <ToastPrintLabel /> : ""}
            </span>,
            toastType.SUCCESS,
            7000
          );
        }
      },
      (error) => {
        customToast(error, toastType.ERROR);
        dispatch(success([], storeConstants.GET_USER_NOTIFICATIONS));
      }
    );
  };
};

const markNotificationAsRead = (storeId, data) => {
  return (dispatch) => {
    dispatch(
      custom(
        storeConstants.GET_USER_NOTIFICATIONS,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    storeService
      .markNotificationAsRead(storeId, data)
      .then(
        (response) => {
          dispatch(getUserNotifications(storeId));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            storeConstants.GET_USER_NOTIFICATIONS,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const createNotification = (storeId, data) => {
  return (dispatch) => {
    dispatch(
      custom(
        storeConstants.CREATE_NOTIFICATION,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    storeService
      .createNotification(storeId, data)
      .then(
        (response) => {
          // dispatch(getUserNotifications(storeId));
          dispatch(
            success(response?.data?.data, storeConstants.CREATE_NOTIFICATION)
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            storeConstants.CREATE_NOTIFICATION,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const getPayoutsInformation = (storeId, startDate, endDate) => {
  return (dispatch) => {
    dispatch(
      custom(
        storeConstants.PAYOUT_INFORMATION,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    storeService
      .getPayoutsInformation(storeId, startDate, endDate)
      .then(
        (response) => {
          dispatch(success(response, storeConstants.PAYOUT_INFORMATION));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            storeConstants.PAYOUT_INFORMATION,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const editSaleTax = (ObjectDetail, data) => {
  return (dispatch) => {
    dispatch(
      custom(
        dashboardConstants.UPDATE_SALE_TAX,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    storeService
      .editSaleTax(ObjectDetail, data)
      .then(
        (response) => {
          customToast(toastMessages.SALE_TAX_UPDATED, toastType.SUCCESS);
          dispatch(
            storeActions.getPayoutsInformation(
              ObjectDetail.storeId,
              ObjectDetail.startDate,
              ObjectDetail.endDate
            )
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            dashboardConstants.UPDATE_SALE_TAX,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const cancelStripeSubscription = (data) => {
  return (dispatch) => {
    dispatch(
      custom(
        dashboardConstants.UPDATE_SALE_TAX,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    storeService
      .cancelStripeSubscription(data)
      .then(
        (response) => {
          dispatch(getStores(globalConstants.EMPTY_STRING));
          customToast(response.message, toastType.SUCCESS);
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            dashboardConstants.UPDATE_SALE_TAX,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Export Authentication Actions
export const storeActions = {
  getStores,
  addStore,
  editStore,
  getStoreOwners,
  deleteStore,
  uploadLogo,
  updateReceiptDetails,
  getUserNotifications,
  getPayoutsInformation,
  markNotificationAsRead,
  editSaleTax,
  cancelStripeSubscription,
  createNotification,
};
