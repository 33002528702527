import React, { Suspense } from "react";
import RouterWrapper from "./router/RouterWrapper";
import { BrowserRouter as Router } from "react-router-dom";

import PageRedirection from "../../shared/hooks/PageRedirection";
import ErrorBoundaryContainer from "./ErrorBoundary/ErrorBoundaryContainer";

const App = () => {
  return (
    <Suspense fallback={null}>
      <Router>
        <ErrorBoundaryContainer>
          <PageRedirection />
          <RouterWrapper />
        </ErrorBoundaryContainer>
      </Router>
    </Suspense>
  );
};

export default App;
