import React from "react";

import {
  PRODUCT_CONDITIONS_ENUMS,
  PRODUCT_TYPES_ENUMS,
} from "../../../system/globalEnums";
import { inventoryConstants } from "../../../constants";
import { getTypeOrCategoryObject } from "../../../shared/utility";
import IconSelectField from "../../../shared/components/selectField/IconSelectField";

const CustomItemConditionSelectField = ({ customTypes, values, ...props }) => {
  return (
    <IconSelectField
      label="Condition"
      options={
        getTypeOrCategoryObject(customTypes, values.type)?.productType ===
        PRODUCT_TYPES_ENUMS.VIDEO_GAME
          ? [
              ...inventoryConstants.VIDEO_GAME_CONDITION,
              {
                value: PRODUCT_CONDITIONS_ENUMS.USED,
                label: PRODUCT_CONDITIONS_ENUMS.USED,
              },
            ]
          : getTypeOrCategoryObject(customTypes, values.type)?.productType ===
            PRODUCT_TYPES_ENUMS.TRADING_CARD
          ? [
              ...inventoryConstants.TRADING_CARD_CONDITION,
              ...inventoryConstants.OTHER_CONDITION,
            ]
          : inventoryConstants.OTHER_CONDITION
      }
      placeHolder="Condition..."
      name="condition"
      isClearable={false}
    />
  );
};

export default CustomItemConditionSelectField;
