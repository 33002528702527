import React from "react";
import {
  StepUpNumberIcon,
  StepDownNumberIcon,
} from "../../../assets/icons/iconsProvider";
import {
  handleEmptyOnFocus,
  handleKeyDown,
  parseToDecimalNumber,
  parseToNumber,
} from "../../utility";

const FormikInputNumberField = ({
  form,
  step,
  field,
  min,
  pattern,
  isBorder,
  disabled,
  maxValue,
  IconImage,
  className,
  placeholder,
  handleChange,
  isHandleKeyDown,
  isConvertToDecimal,
}) => {
  const handleIncrement = () => {
    const value = parseToNumber(field.value);
    form.setFieldValue(
      field.name,
      !value ? 1 : value <= maxValue ? value + 1 : value
    );
    handleChange &&
      handleChange(!field.value ? 1 : value <= maxValue ? value + 1 : value);
  };

  const handleDecrement = () => {
    const value = parseToNumber(field.value);
    form.setFieldValue(field.name, value - 1 >= min ? value - 1 : 0);
    handleChange && handleChange(value - 1 >= min ? value - 1 : 0);
  };

  const handleChangeValue = (value) => {
    if (value <= maxValue) {
      form.setFieldValue(field.name, value);
      handleChange && handleChange(value);
    }
  };

  return (
    <div className={`input-field-wrapper input-number-field ${className}`}>
      {IconImage && <IconImage className="icon-image" />}
      <input
        {...field}
        min={min}
        step={step}
        type="number"
        max={maxValue}
        pattern={pattern}
        autoComplete="off"
        disabled={disabled}
        onKeyDown={isHandleKeyDown ? handleKeyDown : (e) => {}}
        placeholder={placeholder}
        onFocus={handleEmptyOnFocus}
        onChange={(e) => handleChangeValue(e.target.value)}
        className={`input-textfield input-number-field w-100 ${
          IconImage || "input-textfield-without-icon"
        } ${className && className} ${isBorder && "border"}`}
        onBlur={(e) => {
          field.onBlur(e);
          if (isConvertToDecimal) {
            const inputValue = parseToDecimalNumber(e.target.value); // Convert to number
            handleChangeValue(inputValue);
          }
        }}
      />
      <div className="number-field-wrapper">
        <button type="button" disabled={disabled} onClick={handleIncrement}>
          <StepUpNumberIcon className="p-0" />
        </button>
        <button type="button" disabled={disabled} onClick={handleDecrement}>
          <StepDownNumberIcon className="p-0" />
        </button>
      </div>
    </div>
  );
};

export default FormikInputNumberField;
