import moment from "moment";
import { datepickerConstants } from "../../constants";

//------- Sale By Daily
export const aggregateSalesByHour = (data, timeFrame) => {
  // Check if data is empty or timeFrame is missing start or end dates
  if (!data.length || !timeFrame.start || !timeFrame.end) {
    return Array.from({ length: 24 }, (_, i) => ({
      x: moment.utc(timeFrame.start).startOf("day").add(i, "hours").valueOf(),
      y: 0,
    }));
  }

  // Parse the start and end dates using Moment.js
  const startDate = moment(timeFrame.start).startOf("day");
  const endDate = moment(timeFrame.end).endOf("day");

  // Initialize an array to hold sales data for each hour (24 points)
  const hourlySales = Array.from({ length: 24 }, (_, i) => ({
    x: startDate.clone().add(i, "hours").valueOf(),
    y: 0,
  }));

  // Loop through each transaction and sum sales for the corresponding hour.
  data.forEach((transaction) => {
    const transactionDate = moment(transaction.createdOn);

    // Check if the transaction date falls within the start and end dates
    if (transactionDate.isBetween(startDate, endDate, undefined, "[]")) {
      const hour = transactionDate.hour();

      // Ensure no decimal numbers by using Math.floor()
      const amount = Math.floor(Number(transaction?.totalAmountToPay ?? 0));

      // Sum the sales amount for the corresponding hour
      hourlySales[hour].y += amount;
    }
  });

  // Return the hourly sales data, ensuring all 24 points are shown
  return hourlySales;
};

//------- Sale By Weekly
export const aggregateSalesByDay = (data, timeFrame) => {
  // Check if data is empty or timeFrame is missing start or end dates
  if (!data.length || !timeFrame.start || !timeFrame.end) {
    return Array.from({ length: 7 }, (_, i) => ({
      x: moment.utc(timeFrame.start).startOf("day").add(i, "days").valueOf(),
      y: 0,
    }));
  }

  // Parse the start and end dates using Moment.js
  const startDate = moment(timeFrame.start).startOf("day");
  const endDate = moment(timeFrame.end).endOf("day");

  // Initialize an array to hold sales data for each day of the week
  const dailySales = Array.from({ length: 7 }, (_, i) => ({
    x: startDate.clone().add(i, "days").valueOf(),
    y: 0,
  }));

  // Loop through each transaction and sum sales for the corresponding day.
  data.forEach((transaction) => {
    const transactionDate = moment.utc(transaction.createdOn).startOf("day");

    // Check if the transaction date falls within the start and end dates
    if (transactionDate.isBetween(startDate, endDate, undefined, "[]")) {
      // Calculate the day index based on the difference from the start date.
      const dayIndex = transactionDate.diff(startDate, "days");

      if (dayIndex >= 0 && dayIndex < 7) {
        // Sum sales, flooring the total amount to avoid decimals
        dailySales[dayIndex].y += Math.floor(
          Number(transaction?.totalAmountToPay ?? 0)
        );
      }
    }
  });

  // Return the daily sales data, ensuring all 7 points are shown
  return dailySales;
};

//------- Sale By BiWeekly
export const aggregateSalesByBiWeek = (data, timeFrame) => {
  // Check if data is empty or timeFrame is missing start or end dates
  if (!data.length || !timeFrame.start || !timeFrame.end) {
    return Array.from({ length: 14 }, (_, i) => ({ x: 0, y: 0 }));
  }

  // Parse the start and end dates using Moment.js
  const startDate = moment(timeFrame.start);
  const endDate = moment(timeFrame.end);

  // Initialize an array to hold sales data for each day (14 points for 14 days).
  const biWeeklySales = Array.from({ length: 14 }, (_, i) => {
    const day = startDate.clone().add(i, "days");
    return {
      x: day.valueOf(),
      y: 0,
    };
  });

  // Loop through each transaction and sum sales for the corresponding day.
  data.forEach((transaction) => {
    const transactionDate = moment.utc(transaction.createdOn);

    // Check if the transaction date falls within the start and end dates
    if (transactionDate.isBetween(startDate, endDate, undefined, "[]")) {
      const dayIndex = Math.floor(
        moment.duration(transactionDate.diff(startDate)).asDays()
      );

      if (dayIndex >= 0 && dayIndex < 14) {
        biWeeklySales[dayIndex].y += Math.floor(
          Number(transaction?.totalAmountToPay ?? 0)
        );
      }
    }
  });

  // Return the bi-weekly sales data with all 14 points included
  return biWeeklySales;
};

//------- Sale By Month
export const aggregateSalesByDayInMonth = (data, timeFrame) => {
  // Check if data is empty or timeFrame is missing start or end dates
  if (!data.length || !timeFrame.start || !timeFrame.end) {
    return [];
  }

  // Parse start and end dates from the timeFrame in UTC using Moment.js
  const startDate = moment(timeFrame.start).startOf("day");
  const endDate = moment(timeFrame.end).endOf("day");

  // Get the year and month from the end date in UTC (use end date for consistency)
  const year = endDate.year();
  const month = endDate.month();
  const daysInMonth = endDate.daysInMonth();

  // Initialize an array to hold sales data for each day of the month in UTC
  const monthlySales = Array.from({ length: daysInMonth }, (_, i) => ({
    x: moment([year, month, i + 1]).valueOf(), // Date for the day in UTC
    y: 0, // Initialize sales to 0 for each day
  }));

  // Loop through each transaction and sum sales for the corresponding day in UTC
  data.forEach((transaction) => {
    const transactionDate = moment(transaction.createdOn).startOf("day");

    // Check if the transaction date falls within the start and end dates in UTC
    if (
      transactionDate.isBetween(startDate, endDate, undefined, "[]") &&
      transactionDate.year() === year &&
      transactionDate.month() === month
    ) {
      const dayIndex = transactionDate.date() - 1; // Adjust for zero-based index

      // Ensure the dayIndex is within the bounds of the monthlySales array
      if (dayIndex >= 0 && dayIndex < daysInMonth) {
        monthlySales[dayIndex].y += Math.floor(
          Number(transaction?.totalAmountToPay ?? 0)
        );
      }
    }
  });

  // Return the monthly sales data with all days of the month included
  return monthlySales; // Return the full array of monthlySales, including days with zero sales
};

//------- Sale By Year
export const aggregateSalesByMonth = (data, timeFrame) => {
  // Check if data is empty or timeFrame is missing start or end dates
  if (!data.length || !timeFrame.start || !timeFrame.end) {
    // Return an array with 12 months, each with 0 sales if data is empty
    return Array.from({ length: 12 }, (_, i) => ({
      x: moment([new Date().getFullYear(), i]).valueOf(),
      y: 0,
    }));
  }

  // Parse the start and end dates from the timeFrame in UTC using Moment.js
  const startDate = moment(timeFrame.start).startOf("month");
  const endDate = moment(timeFrame.end).endOf("month");
  const year = endDate.year();

  // Initialize an array to hold sales data for each month (12 points).
  const yearlySales = Array.from({ length: 12 }, (_, i) => {
    const month = moment([year, i]).startOf("month");
    return {
      x: month.valueOf(),
      y: 0,
    };
  });

  // Loop through each transaction and sum sales for the corresponding month.
  data.forEach((transaction) => {
    const transactionDate = moment(transaction.createdOn);

    // Check if the transaction date falls within the start and end dates
    if (transactionDate.isBetween(startDate, endDate, undefined, "[]")) {
      const monthIndex = transactionDate.month();
      yearlySales[monthIndex].y += Math.floor(
        Number(transaction?.totalAmountToPay ?? 0)
      );
    }
  });

  return yearlySales;
};

//------- Sale By Custom
export const aggregateSalesCustom = (data, timeFrame, numberOfDays) => {
  if (numberOfDays < 7) {
    return aggregateSalesByHour(data, timeFrame);
  } else if (numberOfDays < 31) {
    return aggregateSalesByDay(data, timeFrame);
  } else if (numberOfDays < 180) {
    return aggregateSalesByWeek(data, timeFrame);
  } else if (numberOfDays < 730) {
    return aggregateSalesByMonth(data, timeFrame);
  } else {
    return aggregateSalesByQuarter(data, timeFrame);
  }
};

export const aggregateSalesByWeek = (data, timeFrame) => {
  // Check if the data is empty or if the timeFrame has missing start or end dates
  if (!data.length || !timeFrame.start || !timeFrame.end) {
    return [];
  }

  // Initialize an array to hold sales data for each week.
  const weeklySales = [];

  // Parse start and end dates from the timeFrame using Moment.js
  const startDate = moment(timeFrame.start).utc().startOf("day");
  const endDate = moment(timeFrame.end).utc().endOf("day");

  // Ensure the startDate is adjusted to the beginning of the week (Monday)
  const adjustedStartDate = startDate.clone().day(1); // Adjust to Monday

  // Iterate through each week until the end date
  while (adjustedStartDate.isSameOrBefore(endDate)) {
    // Calculate the end date of the week (6 days after the start date)
    const weekEndDate = adjustedStartDate.clone().add(6, "days");

    const weeklyData = {
      x: adjustedStartDate.valueOf(), // Get timestamp for the start of the week
      y: 0,
    };

    // Sum sales for the entire week, considering the timeFrame
    data.forEach((transaction) => {
      const transactionDate = moment(transaction.createdOn).utc();
      if (
        transactionDate.isBetween(adjustedStartDate, weekEndDate, null, "[]") // Include boundaries
      ) {
        weeklyData.y += Math.floor(Number(transaction?.totalAmountToPay ?? 0));
      }
    });

    // Add the week data, even if sales are zero
    weeklySales.push(weeklyData);

    // Move to the next week (7 days ahead of the current adjustedStartDate)
    adjustedStartDate.add(7, "days");
  }

  return weeklySales;
};

export const aggregateSalesByQuarter = (data, timeFrame) => {
  // Check if the data is empty or if the timeFrame has missing start or end dates
  if (!data.length || !timeFrame.start || !timeFrame.end) {
    return [];
  }

  // Parse start and end dates from the timeFrame using Moment.js
  const startDate = moment(timeFrame.start).utc().startOf("day");
  const endDate = moment(timeFrame.end).utc().endOf("day");

  // Initialize an array to hold sales data for each quarter
  const quarterlySales = [];

  // Set the quarter start to the beginning of the quarter of the start date
  let quarterStart = startDate.clone().startOf("quarter");

  // Loop until the quarter start exceeds the end of the timeframe
  while (quarterStart.isSameOrBefore(endDate)) {
    // Set the quarter end to the last day of the third month of the quarter
    const quarterEnd = quarterStart.clone().endOf("quarter");

    const quarterlyData = {
      x: quarterStart.valueOf(), // Get timestamp for the start of the quarter
      y: 0,
    };

    // Sum sales for the entire quarter if they fall within the timeFrame
    data.forEach((transaction) => {
      const transactionDate = moment(transaction.createdOn).utc();
      if (
        transactionDate.isBetween(quarterStart, quarterEnd, null, "[]") // Include boundaries
      ) {
        quarterlyData.y += Math.floor(
          Number(transaction?.totalAmountToPay ?? 0)
        );
      }
    });

    // Add the quarter data, even if sales are zero
    quarterlySales.push(quarterlyData);

    // Move to the next quarter
    quarterStart.add(1, "quarter");
  }

  return quarterlySales;
};
export const calculateNumberOfDays = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = endDate.getTime() - startDate.getTime();

  // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 milliseconds)
  const numberOfDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  // Return the whole number of days using Math.floor() or Math.round()
  return Math.floor(numberOfDays);
};

export const getLabel = (timestamp, timeFrame) => {
  const date = new Date(timestamp);

  switch (timeFrame?.duration) {
    case datepickerConstants.DAILY:
      // Format as Hourly (e.g., 10 AM)
      return date.toLocaleTimeString("en-US", {
        hour: datepickerConstants.NUMERIC,
        minute: datepickerConstants.NUMERIC,
        hour12: true,
      });
    case datepickerConstants.WEEKLY:
      // Format as 'Mon, Oct 9'
      return date.toLocaleDateString("en-US", {
        weekday: datepickerConstants.SHORT,
        month: datepickerConstants.SHORT,
        day: datepickerConstants.NUMERIC,
      });
    case datepickerConstants.MONTHLY:
      // Format as 'Oct 9'
      return date.toLocaleDateString("en-US", {
        month: datepickerConstants.SHORT,
        year: datepickerConstants.NUMERIC,
        day: datepickerConstants.NUMERIC,
      });
    case datepickerConstants.YEARLY:
      // Format as 'Jan 2024'
      return date.toLocaleDateString("en-US", {
        month: datepickerConstants.SHORT,
        year: datepickerConstants.NUMERIC,
      });
    case datepickerConstants.CUSTOM:
      // Format based on the number of days in custom timeframe
      const startMonth = date.toLocaleString("en-US", {
        month: datepickerConstants.SHORT,
      });
      const startYear = date.getUTCFullYear();
      const endDate = new Date(date);

      const days = calculateNumberOfDays(timeFrame.start, timeFrame.end);

      if (days <= 7) {
        return date.toLocaleTimeString("en-US", {
          hour: datepickerConstants.NUMERIC,
          minute: datepickerConstants.NUMERIC,
          hour12: true,
        });
      } else if (days <= 31) {
        return `${startMonth} ${startYear}`;
      } else if (days <= 180) {
        const startMonth = date.toLocaleString("en-US", {
          month: datepickerConstants.NUMERIC,
        });
        const startDay = date.getUTCDate();

        // Calculate the end date of the week (6 days after the start date)
        endDate.setUTCDate(date.getUTCDate() + 6);
        const endMonth = endDate.toLocaleString("en-US", {
          month: datepickerConstants.NUMERIC,
        });
        const endDay = endDate.getUTCDate();

        // Return the formatted weekly range as "StartMonth Day - EndMonth Day"
        return `${startDay}/${startMonth} - ${endDay}/${endMonth}`;
      } else if (days <= 365) {
        endDate.setUTCMonth(date.getUTCMonth() + 2);
        return `${startMonth}-${endDate.toLocaleString("en-US", {
          month: datepickerConstants.SHORT,
        })}`;
      } else {
        endDate.setUTCMonth(date.getUTCMonth() + 2);
        return `${startMonth}-${endDate.toLocaleString("en-US", {
          month: datepickerConstants.SHORT,
        })} ${startYear}`;
      }
    default:
      // Default format if duration is not recognized
      return date.toLocaleDateString();
  }
};
