import React from "react";
import { Field, ErrorMessage } from "formik";

import ErrorTextMessage from "../errorTextMessage/ErrorTextMessage";

//-------InputTextField component start here
const InputTextField = (props) => {
  const {
    name,
    placeHolder,
    IconImage,
    LeftIconImage,
    type = "text",
    pattern,
    label,
    disabled = false,
    className,
    maxLength = 50,
    wrapperClassName,
  } = props;
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  //-------Return InputTextField Component
  return (
    <div>
      {label && (
        <label className="field-label" htmlFor={name}>
          {label}
        </label>
      )}

      <div
        className={`input-field-wrapper ${
          wrapperClassName && wrapperClassName
        }`}
      >
        {IconImage && <IconImage className="icon-image" />}
        <Field
          className={`input-textfield ${
            IconImage || "input-textfield-without-icon"
          } ${LeftIconImage && "input-textfield-with-Lefticon"} ${
            className && className
          }`}
          type={type}
          maxLength={maxLength}
          id={name}
          name={name}
          placeholder={placeHolder}
          autocomplete="off"
          disabled={disabled}
          onKeyDown={handleKeyDown}
        />
        {LeftIconImage && (
          <LeftIconImage
            className={`icon-image  ${
              LeftIconImage && "input-textfield-with-Lefticon"
            }`}
          />
        )}
      </div>
      <ErrorMessage name={name} component={ErrorTextMessage} />
    </div>
  ); //-------InputTextField component end here
};

//-------Export InputTextField Component
export default InputTextField;
