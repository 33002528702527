import { components } from "react-select";
import { globalConstants, inventoryConstants } from "../constants";
import { TRADING_CARD_CATEGORIES_ENUMS } from "../system/globalEnums";
import { SearchIcon } from "../assets/icons/iconsProvider";
import { convertToUsd } from "./utility";

//-------check is product trading card
export const isTradingCard = (product) =>
  product.genre === TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD ||
  product.genre === TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD ||
  product.genre === TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD;

//-------check is product Video Game
export const isVideoGame = (product) => !isTradingCard(product);

//-------craete combine list for the api result list
export const createCombinedList = (apiData, sourceApi) => {
  return apiData.map((item) => {
    if (sourceApi === inventoryConstants.PRICECHARTING_API) {
      return {
        id: item.id,
        price: convertToUsd(item["cib-price"]) ?? 0,
        name: item[inventoryConstants.PRICECHARTING_PRODUCT_NAME],
        category:
          item.genre === TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
            ? TRADING_CARD_CATEGORIES_ENUMS.YUGIOH_CARD
            : item[inventoryConstants.PRICECHARTING_PRODUCT_CONSOLE_NAME],
        sourceApi: sourceApi,
        genre: item.genre,
        upc: item.upc || globalConstants.EMPTY_STRING,
        productObject: item,
      };
    } else if (sourceApi === inventoryConstants.POKEMON_API) {
      var pricesPath = item?.["tcgplayer"]?.["prices"];
      var firstObjectKey = Object.keys(pricesPath ?? {})[0];
      var marketPrice = pricesPath?.[firstObjectKey]?.["market"];
      return {
        id: item.id,
        name: `${item.name} (${item.set.name} - ${item.number}/${item.set.printedTotal})`,
        category: TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD,
        sourceApi: sourceApi,
        genre: TRADING_CARD_CATEGORIES_ENUMS.POKEMON_CARD,
        price: marketPrice ?? 0,
        upc: globalConstants.EMPTY_STRING,
      };
    } else if (sourceApi === inventoryConstants.SCRYFALL_API) {
      return {
        id: item.id,
        name: `${item.name} (${item.set_name} - ${item.collector_number})`,
        category: TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD,
        sourceApi: sourceApi,
        price: item?.prices?.usd ?? 0,
        genre: TRADING_CARD_CATEGORIES_ENUMS.MAGIC_CARD,
        upc: globalConstants.EMPTY_STRING,
      };
    }
  });
};

//------custom input for react select
export const CustomInput = (props) => (
  <div>
    <components.Input {...props} />
    <SearchIcon className="dropdown-icon" />
  </div>
);
