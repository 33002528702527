import { connect } from "react-redux";
import { Modal, Col, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";

import DataTable from "react-data-table-component";
import { storeService } from "../../../../services";
import { systemActions } from "../../../../redux/actions";
import Button from "../../../../shared/components/Buttons/Button";
import SwitchButton from "../../../../shared/components/SwitchButton/SwitchButton";
import SelectDropDown from "../../../../shared/components/selectDropdown/SelectDropdown";
import { printZebraData } from "../../../../shared/Printers/ReceiptPrinters/printReceipt";
import {
  customToast,
  downloadImage,
  resizeAndDarkenImageBlob,
  parseToThousandSeperatorWholeNumber,
} from "../../../../shared/utility";
import {
  toastType,
  toastMessages,
  inventoryConstants,
  buttonNameConstants,
  buttonTypeConstants,
  PRINT_LABEL_DESIGN_OPTIONS,
} from "../../../../constants";

const PrintLabelModal = (props) => {
  //------Destructure props
  const {
    selectedRows,
    currentStore,
    printLabelModal,
    activateSpinner,
    singlePrintArray,
    deactivateSpinner,
    defaultZebraPrinter,
    togglePrintLabelModal,
  } = props;

  const [bulkQuantity, setBulkQuantity] = useState(1);
  const [labelDesign, setLabelDesign] = useState(PRINT_LABEL_DESIGN_OPTIONS[0]);
  //-------handle hide modal
  const handleHideModal = () => {
    togglePrintLabelModal();
    setLabelDesign(PRINT_LABEL_DESIGN_OPTIONS[0]);
    setBulkQuantity(1);
  };

  //-------handle label design switch
  const handleSwitchDesignChange = (id, value) => {
    setLabelDesign(value);
  };

  //-------Get store logo  in zpl code form
  const getStoreLogo = async () => {
    if (!currentStore?.logo) {
      customToast(toastMessages.STORE_LOGO_NOT_FOUND, toastType.ERROR);
    }

    const downloadedImage = await downloadImage(currentStore?.logo);
    const resizedImage = await resizeAndDarkenImageBlob(downloadedImage, 100);
    const formData = new FormData();
    formData.append("file", resizedImage);
    return storeService.getStoreLogoZplCode(formData).then(
      (response) => {
        return response.data
          .replace("^XA", "")
          .replace("^XZ", "")
          .replace("^FO0,0", "");
      },
      (error) => {
        customToast(error, toastType.ERROR);
      }
    );
  };

  //-------handle print button click
  const handlePrintButtonClick = async () => {
    if (!labelDesign?.value) {
      customToast(toastMessages.PLEASE_SELECT_LABEL_DESIGN, toastType.ERROR);
      return;
    }

    activateSpinner(inventoryConstants.LABELS_PRINTING);
    customToast("Labels Printing", toastType.SUCCESS);
    const storeLogo = currentStore?.showOnLabels ? await getStoreLogo() : false;
    printZebraData(
      data,
      labelDesign?.value,
      storeLogo,
      defaultZebraPrinter,
      deactivateSpinner
    );
    handleHideModal();
  };

  //-------handle key down fo number fields
  const handleKeyDown = (e) => {
    if (["e", "E", "+", "-", "."].includes(e.key)) {
      e.preventDefault();
    }
    if (e.key !== "Backspace" && e.target.value.length === 7) {
      e.preventDefault();
    }
  };

  //-------column headers for the print modal table
  const columnHeaders = [
    {
      id: "productName",
      name: "Product Name",
      selector: (row) => row.title,
      cell: (row) => {
        return <span className="devices-cell-max-size">{row.title}</span>;
      },
      sortable: true,
      sortDirection: null,
      width: "40%",
    },
    {
      id: "Category",
      name: "Category",
      selector: (row) => row.title,
      cell: (row) => {
        return (
          <span className="devices-cell-max-size">{row.category_name}</span>
        );
      },
      sortable: true,
      sortDirection: null,
      width: "20%",
    },
    {
      id: "stockQuantity",
      name: "Stock Quantity",
      selector: (row) => row.stockQuantity,
      cell: (row) => {
        return (
          <span className="text-center w-100 pe-4">
            {parseToThousandSeperatorWholeNumber(row.stockQuantity)}
          </span>
        );
      },
      sortable: true,
      sortDirection: null,
      width: "20%",
    },
    {
      id: "printQuantity",
      name: (
        <span className="d-flex w-100 justify-content-center">
          Print Quantity
        </span>
      ),
      selector: (row) => row.printQuantity,
      cell: (row) => {
        return (
          <input
            min={1}
            type="number"
            max={row.stockQuantity}
            value={row.printQuantity}
            onKeyDown={handleKeyDown}
            className="print-input-field"
            onChange={(e) => handlePrintQuantityChange(row.sku, e.target.value)}
          />
        );
      },
      sortable: true,
      sortDirection: null,
      width: "20%",
    },
  ];

  const handleCancelButtonClick = () => {
    handleHideModal();
  };

  //--------data to print labels
  const [data, setData] = useState(
    selectedRows?.map((row) => ({
      sku: row.sku,
      title: row.product_name,
      stockQuantity: row.price.quantity,
      printQuantity: row.price.quantity,
      price: {
        type: row.price.type,
        unit_sell_price: row.price.unit_sell_price,
      },
      category_name: row.category_name,
      product_name: row.product_name,
      productType: row.productType,
      tags: row.tags,
    })) ||
      singlePrintArray?.map((row) => ({
        sku: row.sku,
        title: row.product_name,
        stockQuantity: row.price.quantity,
        printQuantity: row.price.quantity,
        price: {
          type: row.price.type,
          unit_sell_price: row.price.unit_sell_price,
        },
        category_name: row.category_name,
        product_name: row.product_name,
        productType: row.productType,
        tags: row.tags,
      })) ||
      []
  );
  //------handle print quantity change
  const handlePrintQuantityChange = (id, value) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.sku === id
          ? {
              ...item,
              printQuantity: value,
            }
          : item
      )
    );
  };

  //--------handle bulk input value change
  const handleBuldUpateInputChange = (e) => {
    if (e) {
      setBulkQuantity(e.target.value);
    }
  };

  //-------handle bulk update button click
  const handleUpdateButtonClick = () => {
    const updatedArray = data?.map((item) => {
      return { ...item, printQuantity: bulkQuantity };
    });
    setData(updatedArray);
  };

  //-------check any print quantity is 0 in array
  const isAnyValueGreaterThanZero = () => {
    return data.some((item) => item.printQuantity > 0);
  };

  useEffect(() => {
    setData(
      selectedRows?.map((row) => ({
        sku: row.sku,
        title: row.product_name,
        stockQuantity: row.price.quantity,
        printQuantity: row.price.quantity,
        price: {
          type: row.price.type,
          unit_sell_price: row.price.unit_sell_price,
        },
        category_name: row.category_name,
        product_name: row.product_name,
        productType: row.productType,
        tags: row.tags,
      }))
    );
  }, [selectedRows]);

  useEffect(() => {
    if (singlePrintArray?.length > 0) {
      setData(
        singlePrintArray?.map((row) => ({
          sku: row.sku,
          title: row.product_name,
          stockQuantity: row.price.quantity,
          printQuantity: row.price.quantity,
          price: {
            type: row.price.type,
            unit_sell_price: row.price.unit_sell_price,
          },
          category_name: row.category_name,
          product_name: row.product_name,
          productType: row.productType,
          tags: row.tags,
        }))
      );
    }
  }, [singlePrintArray]);

  return (
    <Modal
      centered
      size="lg"
      animation={true}
      backdrop="static"
      show={printLabelModal}
      onHide={handleHideModal}
      className="add-inventory-modal"
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">
            Print Product Label(s)
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body ">
        <Row className="m-0">
          <Col md={12} className="mb-3">
            <h5 className="label-modal-text-heading mb-0">
              Printer :{" "}
              {defaultZebraPrinter
                ? defaultZebraPrinter?.printerName
                : "No Printer Found"}
            </h5>
          </Col>
          <Col md={12} className="d-flex align-items-center gap-2 mb-3">
            <h5 className="label-modal-text-heading mb-0">Label Design :</h5>
            <SelectDropDown
              menuPortalTarget={""}
              menuPlacement={"bottom"}
              placeHolder="Select Type..."
              options={PRINT_LABEL_DESIGN_OPTIONS}
              defaultPageSize={PRINT_LABEL_DESIGN_OPTIONS[0]}
              value={labelDesign}
              handleChange={(e) => {
                setLabelDesign(e);
              }}
            />
          </Col>
          {data?.length > 1 && (
            <Col md={12} className="d-flex align-items-center gap-2 mb-3">
              <h5 className="label-modal-text-heading text-nowrap mb-0 ">
                Bulk Update Quantities :{" "}
              </h5>{" "}
              <input
                min={1}
                max={50}
                type="number"
                value={bulkQuantity}
                onKeyDown={handleKeyDown}
                className="print-input-field bulk-field"
                onChange={(e) => handleBuldUpateInputChange(e)}
              />
              <Button
                label={buttonNameConstants.UPDATE}
                handleClick={() => handleUpdateButtonClick()}
              />
            </Col>
          )}
          <Col md={12}>
            <DataTable data={data} columns={columnHeaders} fixedHeader />
          </Col>
          <Col md={6}></Col>
          <Col
            md={6}
            className="d-flex justify-content-end align-items-center gap-2"
          >
            <Button
              label={buttonNameConstants.CANCEL}
              handleClick={() => handleCancelButtonClick()}
              buttonType={buttonTypeConstants.GHOST_BUTTON}
            />
            <Button
              label={buttonNameConstants.PRINT}
              handleClick={handlePrintButtonClick}
              isDisabled={!isAnyValueGreaterThanZero() || !defaultZebraPrinter}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  currentStore: state.store.currentStore,
  defaultZebraPrinter: state.printer.defaultZebraPrinter,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(PrintLabelModal);
