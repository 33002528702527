import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import React, { useEffect, useState, useRef } from "react";
import Button from "../../../shared/components/Buttons/Button";

import Topbar from "../../../shared/components/topbar/Topbar";
import { tradeInDiscountActions } from "../../../redux/actions";
import { calculateDuration, isSpinnerEnabled } from "../../../shared/utility";
import CustomTooltip from "../../../shared/components/toolTipComponent/CustomTooltip";
import DataTableComponent from "../../../shared/components/table/ReactDataTableComponent";
import DropdownSkeleton from "../../../shared/Skeletons/DropdownSkeleton/DropdownSkeleton";
import TableCustomPriceRangeField from "../../../shared/components/table/TableCustomPriceRangeField";
import TableCustomAppliesToField from "../../../shared/components/table/TableCustomAppliesToField";
import {
  tradeInDiscount,
  buttonNameConstants,
  buttonTypeConstants,
  routesPathConstants,
  SET_BY_MARGIN_OPTIONS,
} from "../../../constants";
import {
  AddIcon,
  EditIcon,
  DeleteIcon,
  ActionDotsIcons,
} from "../../../assets/icons/iconsProvider";

const defaultGridState = {
  pageSize: 50,
  pageIndex: 0,
  sortBy: [],
};

//-------Users component start here
const Discount = (props) => {
  const {
    spinnerArray,
    currentStore,
    tradeDiscount,
    deleteCategoryTypeDiscount,
  } = props;

  const { allDiscount } = tradeDiscount;
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();
  const [categoryTypeDiscount, setCategoryTypeDiscount] = useState([]);
  const [gridState, setGridState] = useState(defaultGridState);
  const navigate = useNavigate();
  let numOfRenders = useRef(0);
  //-------Handle  edit click
  const handleEditClick = (row) => {
    navigate(routesPathConstants.SETTINGS_DISCOUNT_CREATE, {
      state: {
        data: row,
        isEdit: true,
        currentStore: currentStore,
      },
    });
  };

  //-------Handle  delete click
  const handleDeleteClick = (row) => {
    deleteCategoryTypeDiscount(currentStore?.id, [row?.id]);
  };

  //-------Handle add button click
  const handleAddClick = () => {
    navigate(routesPathConstants.SETTINGS_DISCOUNT_CREATE, {
      state: {
        isEdit: false,
        currentStore: currentStore,
      },
    });
  };

  const columnHeaders = [
    {
      id: "coupanName",
      name: "Title",
      selector: (row) => row.description,
      cell: (row) => (
        <span className="users-cell-max-size">{row?.description ?? ""}</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "discount",
      name: "Discount(%)",
      selector: (row) => row.role,
      cell: (row) => (
        <span className="users-cell-max-size">{row.discountPercentage}%</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "setBy",
      name: "Set By",
      selector: (row) => row.marginType,
      cell: (row) => {
        return (
          <span className="users-cell-max-size">
            {
              SET_BY_MARGIN_OPTIONS.find(
                (item) => item.value === row.discountType
              ).label
            }
          </span>
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "appliesTo",
      name: "Applies To",
      selector: (row) => row.discountGroup || [],
      cell: (row) => {
        return (
          <TableCustomAppliesToField
            row={row}
            groupArray={row.discountGroup}
            groupType={row.discountType}
          />
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "priceRange",
      name: "Price Range",
      selector: (row) => row.maxPriceRange,
      cell: (row) => {
        return <TableCustomPriceRangeField row={row} />;
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "activDuration",
      name: "Active Duration",
      selector: (row) => row.role,
      cell: (row) => (
        <span className="users-cell-max-size">
          {calculateDuration(row.startDate, row.endDate)}
        </span>
      ),
      sortable: true,
      sortDirection: null,
    },

    {
      id: "action",
      name: "",
      cell: (row) => {
        return (
          <>
            <CustomTooltip
              key={`tool${row._ts}`}
              anchorSelect={`#tool${row._ts}`}
            >
              <Button
                label={buttonNameConstants.EDIT}
                className="w-100"
                buttonType={buttonTypeConstants.ACTION_BUTTON}
                IconImage={EditIcon}
                handleClick={() => handleEditClick(row)}
              />
              <Button
                label={buttonNameConstants.DELETE}
                className="w-100"
                buttonType={buttonTypeConstants.ACTION_BUTTON}
                IconImage={DeleteIcon}
                handleClick={() => handleDeleteClick(row)}
              />
            </CustomTooltip>
            <button
              id={`tool${row._ts}`}
              key={`tool${row._ts}`}
              className="action-Button-Wrapper"
            >
              <ActionDotsIcons className="cursor-pointer" />
            </button>
          </>
        );
      },
      sortable: false,
      width: "5%",
    },
  ];

  const [columns, setColumns] = useState(columnHeaders);

  const onTableSort = (column, sortDirection) => {
    setColumns(
      columns.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );

    setDefaultSortFieldId(column.id);
  };

  const updateGridState = (newGridState) => {
    setGridState(newGridState);
  };

  //-------useEffects
  useEffect(() => {
    if (currentStore) {
      setColumns(columnHeaders);
    }
    // eslint-disable-next-line
  }, [currentStore]);
  //-------useEffects
  useEffect(() => {
    if (allDiscount) {
      setCategoryTypeDiscount([
        ...(allDiscount.productTypeDiscounts || []),
        ...(allDiscount.categoryDiscounts || []),
        ...(allDiscount.subCategoryDiscounts || []),
      ]);
    }
  }, [allDiscount]);
  //-------Return Discount Component
  return (
    <>
      <Topbar title="Discounts"></Topbar>
      <Row className="m-0 w-100 user-screen-wrapper">
        <Col md={5}></Col>
        <Col md={4}></Col>
        <Col
          md={3}
          className="d-flex justify-content-end align-items-center gap-3"
        >
          {isSpinnerEnabled(spinnerArray, tradeInDiscount.GET_ALL_DISCOUNT) ? (
            <DropdownSkeleton />
          ) : (
            <Button
              label={buttonNameConstants.ADD}
              IconImage={AddIcon}
              handleClick={handleAddClick}
            />
          )}

          {/* <Button
            type="button"
            label={buttonNameConstants.PRINTER}
            handleClick={() => {
              // handleCancelButton();
            }}
            buttonType={buttonTypeConstants.LIGHT_PURPLE}
            className="w-100"
            IconImage={PrinterIcon}
          ></Button> */}
        </Col>
      </Row>
      <Row className="m-0 w-100 h-100 user-screen-wrapper pt-3">
        <DataTableComponent
          className={"discounts-table"}
          key={numOfRenders.current}
          columns={columns}
          data={categoryTypeDiscount}
          onSort={onTableSort}
          defaultSortFieldId={defaultSortFieldId}
          gridState={gridState}
          setGridState={updateGridState}
          total={categoryTypeDiscount?.length}
        />
      </Row>
    </>
  );
}; //-------Discount component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  user: state.user,
  tradeDiscount: state.tradeDiscount,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  deleteCategoryTypeDiscount: (storeId, discountId) => {
    dispatch(
      tradeInDiscountActions.deleteCategoryTypeDiscount(storeId, discountId)
    );
  },
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(Discount);
