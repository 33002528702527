import React, { useEffect, useState, useRef } from "react";
import Topbar from "../../../../shared/components/topbar/Topbar";
import { Col, Row } from "react-bootstrap";
import ProductImage from "../../../../assets/images/productimage.png";
import { Formik, Form } from "formik";
import Modal from "react-bootstrap/Modal";
import pokemon from "pokemontcgsdk";

import {
  toastType,
  buttonNameConstants,
  buttonTypeConstants,
  categoryDropdownConstants,
  globalConstants,
  routesPathConstants,
  inventoryConstants,
  EMPTY_STRING,
  defaultGridState,
  toastMessages,
} from "../../../../constants";
import Button from "../../../../shared/components/Buttons/Button";
import { editInventoryValidationSchema } from "../../../../shared/validationSchema/validationSchema";
import CategoriesDropdown from "../../../../shared/components/selectDropdown/CategoriesDropdown";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import { useLocation, useNavigate } from "react-router-dom";
import { inventoryActions, systemActions } from "../../../../redux/actions";
import { connect } from "react-redux";
import {
  stringToSlug,
  convertToUsd,
  convertToFixedPrecision,
  handleViewEbaySales,
  uploadCustomProductImage,
  customToast,
  handleShowNintendoCondition,
  parseToDecimalNumber,
  parseToThousandSeperatorDecimalNumber,
  handleConditionChange,
  handleRaritiesChangeInSingleAddEdit as handleRaritiesChange,
  getTypeOrCategoryIdByName,
  getTypeOrCategoryObject,
  onSelectTagsChange,
  handleProductConditionUpdate,
  isProductMetaDataSame,
  handleViewTCGPlayer,
  handleUploadProductImage,
  isSystemProduct,
  getProductSubCategory,
  isSerialNumberUnique,
  isMarketPriceHigh,
} from "../../../../shared/utility";
import {
  PRODUCT_TYPES_ENUMS,
  TRADING_CARD_CATEGORIES_ENUMS,
} from "../../../../system/globalEnums";
import {
  DollarIcon,
  PercentageIcon,
  LogoImageReplaceIcon,
} from "../../../../assets/icons/iconsProvider";
import PrintLabelModal from "../printingLabelModal/PrintLabelModal";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import CustomTooltip from "../../../../shared/components/toolTipComponent/CustomTooltip";
import ProductTagList from "../../../../shared/components/tags/ProductTagList";
import CustomItemProductTypeSelectField from "../../Shared/CustomItemProductTypeSelectField";
import CustomItemCategorySelectField from "../../Shared/CustomItemCategorySelectField";
import CustomItemSubcategorySelectField from "../../Shared/CustomItemSubcategorySelectField";
import CustomItemConditionSelectField from "../../Shared/CustomItemConditionSelectField";

pokemon.configure({ apiKey: process.env.REACT_APP_POKEMON_API_KEY });

const EditInventory = (props) => {
  const {
    tradeDiscount,
    updateInventory,
    currentStore,
    quantityShrink,
    getPricechartingProductById,
    getScryFallProductById,
    activateSpinner,
    deactivateSpinner,
    emptyPriceChartingProductObject,
    priceChartingProductInfo,
    getInventoryByPricechartingId,
    productTags,
    setPokemonProductInReducer,
    customTypes,
    customCategories,
    customSubCategories,
    inventoryProducts,
    getInventoryByCustomItemCheck,
  } = props;

  //-------preset prices
  const { markupPresets, tradeinMargin } = tradeDiscount;

  const { state } = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [productUrl, setProductUrl] = useState(EMPTY_STRING);
  const [productDataObject, setProductDataObject] = useState({
    imgUrl: state?.data?.imgUrl,
  });
  const [showIframe, setShowIframe] = useState(false);
  const handleCloseIfram = () => setShowIframe(false);
  const handleShowIfram = () => setShowIframe(true);
  const [printLabelModal, setPrintLabelModal] = useState(false);
  const [shrinkValue, setShrinkValue] = useState(EMPTY_STRING);
  const [updatedInventory, setUpdatedInventory] = useState(EMPTY_STRING);
  const [rarities, setRarities] = useState(EMPTY_STRING);
  const [selectedRarity, setSelectedRarity] = useState(EMPTY_STRING);
  const [conditionList, setConditionList] = useState([]);
  const [productCondition, setProductCondition] = useState({});
  const [selectedTags, setSelectedTags] = useState([]);
  const [productTagsList, setProductTagsList] = useState([]);
  const [isConsolidationModal, setIsConsolidationModal] = useState(false);
  const [productType, setProductType] = useState(PRODUCT_TYPES_ENUMS.ALL);

  //------UseRef
  const hiddenFileInput = useRef(null);
  const isFirstRenderRef = useRef(true);
  const formRef = useRef(null);

  const isCustomItem = state?.data?.tags.includes(
    inventoryConstants.CUSTOM_ITEM_TAG
  );
  const initialValues = {
    productName: state?.data?.product_name,
    condition: state?.data?.price?.type,
    type: getTypeOrCategoryIdByName(customTypes, state?.data?.productType),
    category: getTypeOrCategoryIdByName(
      customCategories,
      state?.data?.category_name,
      state?.data?.productType
    ),
    subCategory: getTypeOrCategoryIdByName(
      customSubCategories,
      state?.data?.subcategory,
      state?.data?.productType,
      state?.data?.category_name
    ),
    unitsellprice:
      state?.data?.price?.unit_sell_price !== null &&
      state?.data?.price?.unit_sell_price !== undefined
        ? parseToDecimalNumber(state.data.price.unit_sell_price)
        : EMPTY_STRING,
    costPerItem:
      state?.data?.price?.unit_purchase_price !== null &&
      state?.data?.price?.unit_purchase_price !== undefined
        ? convertToFixedPrecision(state.data.price.unit_purchase_price)
        : EMPTY_STRING,
    profit:
      state?.data?.price?.unit_sell_price !== null &&
      state?.data?.price?.unit_sell_price !== undefined
        ? convertToFixedPrecision(
            state?.data?.price?.unit_sell_price -
              state?.data?.price?.unit_purchase_price
          )
        : 0,
    margin:
      state?.data?.price?.unit_sell_price !== null &&
      state?.data?.price?.unit_sell_price !== undefined
        ? convertToFixedPrecision(
            ((state?.data?.price?.unit_sell_price -
              state?.data?.price?.unit_purchase_price) /
              state?.data?.price?.unit_sell_price) *
              100
          )
        : 0,
    //   // unitsOnline: state?.data?.price?.quantity || EMPTY_STRING,
    unitsOnHand:
      state?.data?.price?.quantity !== null &&
      state?.data?.price?.quantity !== undefined
        ? state.data.price.quantity
        : EMPTY_STRING,
    originalPrice: state?.data?.price?.unit_sell_price || 0,
    originalCondition: state?.data?.price?.type || EMPTY_STRING,
    upc: state?.data?.upc || EMPTY_STRING,
    serialNumber: state?.data?.serialNumber || EMPTY_STRING,
  };

  //-------Toggle Print Label Modal
  const togglePrintLabelModal = () => {
    setPrintLabelModal(!printLabelModal);
  };
  const handleCancelButton = () => {
    navigate(routesPathConstants.INVENTORY, {
      state: {
        gridState: state?.gridState,
      },
    });
  };

  const handleShrinkModalSubmit = (isAddQuantityInShrink) => {
    setShow(false);
    updateInventory(
      currentStore?.id,
      updatedInventory,
      true,
      state?.gridState,
      navigate
    );
    if (isAddQuantityInShrink) {
      const shrinkInventoryObject = {
        ...state.data,
        shrinkQuantity: shrinkValue,
      };
      quantityShrink([shrinkInventoryObject]);
    }
  };

  const handleSubmit = (data) => {
    if (data?.serialNumber && data.unitsOnHand > 1) {
      customToast(toastMessages.SYSTEM_QUANTITY_MUST_BE_1, toastType.ERROR);
      return;
    } else if (
      isSystemProduct(getProductSubCategory(productDataObject?.genre)) &&
      !isSerialNumberUnique(
        data.serialNumber,
        inventoryProducts?.filter(
          (product) => product.id !== state?.data?.id
        ) || []
      )
    ) {
      customToast(
        toastMessages.PRODUCT_SERIAL_NUMBER_SHOULD_BE_UNIQUE,
        toastType.ERROR
      );
      return;
    }
    const updateInventoryObject = {
      id: state?.data?.id,
      product_name: data?.productName,
      margin: data.margin,
      imgUrl: productDataObject?.imgUrl,
      cardRarity: selectedRarity?.label || EMPTY_STRING,
      tags: selectedTags,
      productType: getTypeOrCategoryObject(customTypes, data.type)?.productType,
      category_name: getTypeOrCategoryObject(customCategories, data.category)
        ?.name,
      subcategory:
        getTypeOrCategoryObject(customSubCategories, data.subCategory)?.name ??
        EMPTY_STRING,
      price: {
        type: isCustomItem
          ? data?.condition
          : productCondition?.value ?? EMPTY_STRING,
        quantity: data.unitsOnHand,
        unit_purchase_price: data.costPerItem,
        unit_sell_price: data.unitsellprice,
        originalPrice: data.originalPrice,
        originalCondition: data?.originalCondition,
        marketPrice: isNaN(convertToUsd(productDataObject?.marketPrice))
          ? "0.00"
          : state?.data?.cardRarity
          ? productDataObject?.marketPrice
          : parseToDecimalNumber(
              convertToUsd(productDataObject?.marketPrice ?? 0)
            ),
        profit: data.profit,
      },
      upc: data?.upc,
      serialNumber: data?.serialNumber,
    };
    // consolidation test (if isConsolidationModal === true i.e user pressed yes so submit the form)
    const isConsolidation = inventoryProducts
      .filter((inv) => inv.id !== updateInventoryObject.id)
      .find((inv) => {
        return isProductMetaDataSame(inv, updateInventoryObject);
      });
    if (
      isConsolidation &&
      !isConsolidationModal &&
      data.serialNumber !== EMPTY_STRING
    ) {
      customToast(
        toastMessages.PRODUCT_SERIAL_NUMBER_SHOULD_BE_UNIQUE,
        toastType.ERROR
      );
      return;
    } else if (isConsolidation && !isConsolidationModal) {
      setIsConsolidationModal(true);
      return;
    }
    if (data.unitsOnHand < state?.data?.price?.quantity) {
      setShrinkValue(state?.data?.price?.quantity - data.unitsOnHand);
      handleShow();
      setUpdatedInventory(updateInventoryObject);
    } else {
      updateInventory(
        currentStore?.id,
        updateInventoryObject,
        true,
        state?.gridState,
        navigate
      );
    }
  };
  const handlePrintButtonClick = () => {
    togglePrintLabelModal();
  };
  //-------Change Logo image button
  const handleReplaceButtonClick = () => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    const productMetaData = {
      category_name: state?.data.category_name,
      genre: isSystemProduct(state?.data.subcategory)
        ? state?.data.subcategory
        : state?.data.category_name,
      imgUrl: state?.data.imgUrl,
      productType: state?.data.productType,
      product_id: state?.data.product_id,
      product_name: state?.data.product_name,
      sourceApi: state?.data.apiSource,
    };
    if (state?.data.apiSource === inventoryConstants.PRICECHARTING_API) {
      getPricechartingProductById(productMetaData, state?.data?.product_id);
    } else if (state?.data.apiSource === inventoryConstants.SCRYFALL_API) {
      getScryFallProductById(productMetaData, state?.data?.product_id);
    } else if (state?.data.apiSource === inventoryConstants.POKEMON_API) {
      pokemon.card.find(state?.data?.product_id).then((card) => {
        setPokemonProductInReducer(productMetaData, card || "");
      });
    } else if (state?.data.apiSource === inventoryConstants.CUSTOM_ITEM) {
      getInventoryByCustomItemCheck(currentStore?.id);
    } else if (state?.data.apiSource === inventoryConstants.CSV_IMPORT_FILE) {
      setPokemonProductInReducer(productMetaData, state?.data);
    }
    return () => {
      emptyPriceChartingProductObject();
    };
  }, []);
  //-------update product condition and type
  useEffect(() => {
    const handleProductDefaultObject = async () => {
      if (priceChartingProductInfo.productObject) {
        await handleProductConditionUpdate(
          currentStore,
          priceChartingProductInfo,
          getInventoryByPricechartingId,
          setProductType,
          setProductUrl,
          productDataObject,
          setProductDataObject,
          productTags,
          setProductTagsList,
          setRarities,
          setSelectedRarity,
          setConditionList,
          setProductCondition,
          state?.data?.imgUrl
        );

        if (isFirstRenderRef.current) {
          isFirstRenderRef.current = false;
          const initialCondition = {
            label: state?.data?.price?.type,
            value: state?.data?.price?.type,
          };
          handleConditionChange(
            initialCondition,
            () => {},
            priceChartingProductInfo,
            productTags,
            setProductTagsList,
            setProductDataObject,
            productDataObject,
            () => {}
          );
          setProductCondition(initialCondition);
          setSelectedRarity({
            label: state?.data?.cardRarity,
            value: state?.data?.cardRarity,
          });
          setSelectedTags(state?.data?.tags);
        }
      }
    };
    handleProductDefaultObject();
  }, [priceChartingProductInfo.productObject]);

  return (
    <>
      <Topbar
        backNavigation={{
          isVisible: true,
          route: routesPathConstants.INVENTORY,
          gridState: state?.gridState,
        }}
        exportButton={true}
        buttonType={buttonTypeConstants.LIGHT_PURPLE}
        title="Inventory Detail - Edit"
        label={buttonNameConstants.PRINT_PRODUCT_LABEL}
        handleClick={handlePrintButtonClick}
      ></Topbar>
      <Row className="m-0 w-100  container-scrollbar user-information-wrapper edit-inventory-wrapper  pb-3">
        <Col className="p-0">
          <Formik
            initialValues={initialValues}
            validate={editInventoryValidationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
            innerRef={formRef}
          >
            {({ values, setFieldValue }) => (
              <Form className="main-row">
                <Row className="m-0 mb-3  ">
                  <Col className="edit-invetory-left" md={7} sm={12}>
                    <Row className="p-0 m-0 left-container">
                      <Row className="Img-container m-0 p-0">
                        <Col md={3} className="img-Div d-flex pe-0">
                          <div className="add-inventory-product-image">
                            <img
                              src={productDataObject?.imgUrl || ProductImage}
                            />
                            <input
                              type="file"
                              className="d-none"
                              accept=".png, .jpg, .jpeg"
                              onChange={(e) =>
                                handleUploadProductImage(
                                  e,
                                  activateSpinner,
                                  deactivateSpinner,
                                  productDataObject,
                                  setProductDataObject
                                )
                              }
                              ref={hiddenFileInput}
                            />
                            <button
                              onClick={handleReplaceButtonClick}
                              type="button"
                              className="replace-logo-button"
                            >
                              <LogoImageReplaceIcon className="replace-icon" />
                            </button>
                          </div>
                        </Col>
                        <Col
                          md={9}
                          sm={12}
                          className="d-flex flex-column gap-2 p-0 "
                        >
                          <span className="saga-Text mb-0"></span>
                          <span className="saga-Text mb-0">
                            {isCustomItem ? (
                              <InputTextField
                                name="productName"
                                placeHolder="Enter Title"
                                className="border"
                                label="Title"
                              />
                            ) : (
                              state?.data?.product_name
                            )}
                          </span>
                          <div
                            className={`p-0 ${
                              isCustomItem ? "row" : "d-flex flex-wrap gap-2"
                            }`}
                          >
                            {isCustomItem ? (
                              <>
                                <div className="col-4">
                                  <CustomItemProductTypeSelectField
                                    setFieldValue={setFieldValue}
                                    customTypes={customTypes}
                                  />
                                </div>
                                <div className="col-4">
                                  <CustomItemCategorySelectField
                                    setFieldValue={setFieldValue}
                                    customCategories={customCategories}
                                    values={values}
                                  />
                                </div>
                              </>
                            ) : (
                              <span className="skitchin-Items category-skitchin">
                                {state?.data?.category_name}
                              </span>
                            )}
                            {isCustomItem ? (
                              <div className="col-4">
                                <CustomItemSubcategorySelectField
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  customTypes={customTypes}
                                  customCategories={customCategories}
                                  customSubCategories={customSubCategories}
                                />
                              </div>
                            ) : state?.data?.subcategory ? (
                              <span className="skitchin-Items category-skitchin">
                                {state?.data?.subcategory}
                              </span>
                            ) : (
                              EMPTY_STRING
                            )}
                          </div>
                          <div className="row">
                            <div className={"col-4"}>
                              {isCustomItem ? (
                                <CustomItemConditionSelectField
                                  customTypes={customTypes}
                                  values={values}
                                />
                              ) : (
                                <CategoriesDropdown
                                  options={conditionList}
                                  disabled={false}
                                  handleChange={(selectedOption) => {
                                    state.data.tags = [];
                                    return handleConditionChange(
                                      selectedOption,
                                      setProductCondition,
                                      priceChartingProductInfo,
                                      productTags,
                                      setProductTagsList,
                                      setProductDataObject,
                                      productDataObject,
                                      setSelectedTags
                                    );
                                  }}
                                  noOptionsMessage="Empty Box"
                                  label={"Condition"}
                                  placeholder={handleShowNintendoCondition(
                                    state?.data?.category_name,
                                    state?.data?.price?.type
                                  )}
                                  SelectIconImage={
                                    categoryDropdownConstants.BOX_ICON
                                  }
                                  isClearable={false}
                                  value={productCondition}
                                />
                              )}
                            </div>
                            {isSystemProduct(
                              getProductSubCategory(productDataObject?.genre)
                            ) && (
                              <div className={"col-4"}>
                                <InputTextField
                                  label="Serial Number"
                                  name="serialNumber"
                                  maxLength={100}
                                  placeHolder="Enter Serial Number"
                                  className="border add-inventory-serial-number-field"
                                />
                              </div>
                            )}
                            {isCustomItem ? (
                              <div className="col-4">
                                <InputTextField
                                  name="upc"
                                  placeHolder="111000294854"
                                  className="border"
                                  label="UPC(Optional)"
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {state.data.apiSource !==
                              inventoryConstants.PRICECHARTING_API &&
                              state?.data?.cardRarity && (
                                <div className={"col-4"}>
                                  <CategoriesDropdown
                                    options={rarities}
                                    disabled={false}
                                    label={"Rarity"}
                                    handleChange={(selectedOption) =>
                                      handleRaritiesChange(
                                        selectedOption,
                                        setSelectedRarity,
                                        setProductDataObject,
                                        productDataObject,
                                        tradeinMargin,
                                        markupPresets
                                      )
                                    }
                                    noOptionsMessage="Empty Box"
                                    placeholder={state?.data?.cardRarity}
                                    SelectIconImage={
                                      categoryDropdownConstants.BOX_ICON
                                    }
                                    isClearable={false}
                                    value={selectedRarity}
                                  />
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col md={3} className="pe-0 mt-3 sku-Text ">
                          SKU:
                        </Col>
                        <Col md={9} className="p-0 mt-3 sku-Num-Text">
                          {skuToolTip(state)}
                        </Col>
                        <Col
                          md={12}
                          className="py-2 d-flex flex-wrap align-items-center gap-2"
                        >
                          <ProductTagList
                            tagsList={productTagsList || []}
                            suggestedArrayList={productTags}
                            onSelectTagsChange={(selectedTags) => {
                              onSelectTagsChange(
                                selectedTags,
                                setSelectedTags,
                                productDataObject,
                                setProductDataObject,
                                priceChartingProductInfo,
                                productCondition,
                                productType
                              );
                            }}
                            productDetail={{
                              ...state?.data,
                              tags: state?.data.tags.map((tag, index) => ({
                                id: index,
                                label: tag,
                                selected: true,
                              })),
                            }}
                          />
                        </Col>
                        <Row className="mt-4 pricing-Div m-0">
                          <Col md={12} className="pricing-Text p-0">
                            Pricing
                          </Col>
                          <Col md={3} className="mt-4 p-0">
                            In Stock Price:
                          </Col>
                          <Col md={5} className="mt-4 p-0"></Col>
                          <Col md={4} className=" ">
                            <InputNumberField
                              name="unitsellprice"
                              placeHolder="$74.99"
                              type="number"
                              disabled={false}
                              IconImage={DollarIcon}
                              className={`${
                                isMarketPriceHigh(
                                  isNaN(
                                    convertToUsd(productDataObject?.marketPrice)
                                  )
                                    ? "0.00"
                                    : state?.data?.cardRarity
                                    ? productDataObject?.marketPrice ?? "0.00"
                                    : parseToThousandSeperatorDecimalNumber(
                                        convertToUsd(
                                          productDataObject?.marketPrice ?? 0
                                        )
                                      ),
                                  values.unitsellprice,
                                  productTags,
                                  selectedTags
                                ) && values.unitsellprice
                                  ? "border-danger"
                                  : ""
                              }`}
                            />
                          </Col>

                          <Row className="mt-4 p-0 m-0">
                            <Col md={4} className=" ps-0">
                              <InputNumberField
                                name="costPerItem"
                                label="Cost per Item: "
                                placeHolder="$74.99"
                                type="number"
                                IconImage={DollarIcon}
                              />
                            </Col>
                            <Col md={4} className="">
                              <InputTextField
                                name="profit"
                                placeHolder="0.00"
                                label={"Profit:"}
                                IconImage={DollarIcon}
                                disabled={true}
                              />
                            </Col>
                            <Col md={4} className="">
                              <InputTextField
                                name="margin"
                                placeHolder="0.00"
                                label={"Profit Margin"}
                                LeftIconImage={PercentageIcon}
                                disabled={true}
                              />
                            </Col>
                          </Row>
                        </Row>
                        <Row className="mt-4 pricing-Div m-0 ">
                          <Col md={12} className="pricing-Text p-0">
                            Quantity
                          </Col>
                          <Col md={6} className="mt-4 ps-0">
                            <InputNumberField
                              name="unitsOnHand"
                              placeHolder="Units On-Hand"
                              type="number"
                              isConvertToDecimal={false}
                              isHandleKeyDown={true}
                              min={-9999}
                              step={"1"}
                              pattern="-?[0-9]+"
                            />
                          </Col>
                          <Col md={6} className=" mt-4 ">
                            {/* <InputNumberField
                            name="unitsOnline"
                            placeHolder="Units Avaible Online"
                            type="number"
                          /> */}
                          </Col>
                        </Row>
                      </Row>
                    </Row>
                  </Col>
                  <Col
                    md={5}
                    sm={12}
                    className="edit-invetory-right mt-4 mt-md-0"
                  >
                    <Row className="right-container m-0 p-0">
                      <Row className="m-0 completeBoxDiv">
                        <p className="para-Text">{state?.data?.product_name}</p>
                        <div className="p-0 d-flex flex-wrap gap-2">
                          <span className="skitchin-Items">
                            {state?.data?.category_name}
                          </span>
                          {state?.data?.subcategory && (
                            <span className="skitchin-Items">
                              {state?.data?.subcategory}
                            </span>
                          )}
                        </div>
                        <p className="complete-box">
                          {state?.data?.price?.type}
                        </p>
                        <span className="complete-number">
                          {skuToolTip(state)}
                        </span>
                      </Row>
                      <Row className="m-0  market-price-div">
                        <Row className="d-flex m-0 p-0 justify-content-between">
                          <Col className="price-market-text" md={6}>
                            Market Price:
                          </Col>
                          <Col
                            className={`price-market-amount d-flex justify-content-end ${
                              isMarketPriceHigh(
                                isNaN(
                                  convertToUsd(productDataObject?.marketPrice)
                                )
                                  ? "0.00"
                                  : state?.data?.cardRarity
                                  ? productDataObject?.marketPrice ?? "0.00"
                                  : parseToThousandSeperatorDecimalNumber(
                                      convertToUsd(
                                        productDataObject?.marketPrice ?? 0
                                      )
                                    ),
                                values.unitsellprice,
                                productTags,
                                selectedTags
                              ) && values.unitsellprice
                                ? "text-danger"
                                : ""
                            }`}
                            md={6}
                          >
                            $
                            {isNaN(convertToUsd(productDataObject?.marketPrice))
                              ? "0.00"
                              : state?.data?.cardRarity
                              ? productDataObject?.marketPrice ?? "0.00"
                              : parseToThousandSeperatorDecimalNumber(
                                  convertToUsd(
                                    productDataObject?.marketPrice ?? 0
                                  )
                                )}
                          </Col>
                        </Row>
                        <Row className="d-flex m-0 p-0 mt-2 justify-content-between">
                          <Col className="suggest-text " md={6}>
                            Suggested Sell Price:
                          </Col>
                          <Col
                            className="suggest-amount d-flex justify-content-end"
                            md={6}
                          >
                            $
                            {isNaN(
                              convertToUsd(
                                productDataObject?.suggestedSellPrice
                              )
                            )
                              ? "0.00"
                              : parseToThousandSeperatorDecimalNumber(
                                  convertToUsd(
                                    productDataObject?.suggestedSellPrice ?? 0
                                  )
                                )}
                          </Col>
                        </Row>
                        <Col className="p-0 mt-3 d-flex gap-2 ms-auto">
                          {state?.data?.apiSource ===
                          inventoryConstants.PRICECHARTING_API ? (
                            <>
                              <Button
                                handleClick={() => {
                                  handleViewEbaySales(
                                    state?.data?.product_name,
                                    state?.data?.category_name,
                                    state?.data?.subcategory
                                  );
                                }}
                                type="button"
                                label={buttonNameConstants.EBAY_SALES}
                                buttonType={buttonTypeConstants.GHOST_BUTTON_2}
                                className="w-100"
                              ></Button>
                              <Button
                                type="button"
                                handleClick={() => {
                                  setProductUrl(
                                    `${
                                      process.env
                                        .REACT_APP_PRICECHARTING_IFRAME_URL
                                    }${stringToSlug(
                                      state?.data?.category_name
                                    )}/${stringToSlug(
                                      state?.data?.product_name
                                    )}`
                                  );
                                  handleShowIfram();
                                }}
                                label={buttonNameConstants.PRICE_CHARTING}
                                buttonType={buttonTypeConstants.BLUE_BUTTON}
                                className="w-100"
                              ></Button>
                            </>
                          ) : (
                            <Button
                              type="button"
                              // handleClick={() => {
                              //   state?.data?.apiSource ===
                              //     inventoryConstants.POKEMON_API &&
                              //     setProductUrl(productDataObject.productUrl);
                              //   handleShowIfram();
                              // }}
                              handleClick={() => {
                                handleViewTCGPlayer(productDataObject);
                              }}
                              label={buttonNameConstants.VIEW_TCG_PLAYER}
                              buttonType={buttonTypeConstants.BLUE_BUTTON}
                              className="ms-auto w-50"
                              isDisabled={!productDataObject?.tcgPlayerUrl}
                            ></Button>
                          )}
                        </Col>
                      </Row>
                      <Row className="m-0 product-stats">
                        <Col className="product-stats-text">
                          Your Product Stats
                        </Col>

                        <div className="detail-div">
                          <p>Total Units Sold:</p>
                          <span>{state?.data?.price?.totalUnitsSold}</span>
                        </div>
                        <div className="detail-div">
                          <p>Average Cost of Goods:</p>
                          <span>
                            {" "}
                            $
                            {parseToThousandSeperatorDecimalNumber(
                              state?.data?.price?.averageBuyPrice
                            )}
                          </span>
                        </div>
                        <div className="detail-div">
                          <p>Average Sold Price:</p>
                          <span>
                            $
                            {parseToThousandSeperatorDecimalNumber(
                              state?.data?.price?.averageSellPrice
                            )}
                          </span>
                        </div>
                        <div className="detail-div">
                          <p>Max Buy Price</p>
                          <span>
                            {parseToThousandSeperatorDecimalNumber(
                              state?.data?.price?.maxBuyPrice
                            ) || "0.00"}
                          </span>
                        </div>
                      </Row>
                    </Row>
                  </Col>
                  <Col md={7}></Col>
                  <Col md={5} className="mt-3 pb-4 pe-0 d-flex gap-2 ms-auto">
                    <Button
                      type="button"
                      label={buttonNameConstants.CANCEL}
                      buttonType={buttonTypeConstants.GHOST_BUTTON}
                      className="w-100"
                      handleClick={() => {
                        handleCancelButton();
                      }}
                    ></Button>
                    <Button
                      type="submit"
                      className="w-100"
                      label={buttonNameConstants.SAVE}
                    ></Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
        {isConsolidationModal && (
          <ConfirmationModal
            isConfirmationModal={isConsolidationModal}
            handleNoButtonClick={() => {
              setIsConsolidationModal(false);
            }}
            handleYesButtonClick={() => {
              if (formRef.current) {
                formRef.current.handleSubmit();
              }
            }}
            message="An item with these details already exists. Saving/Adding will update its quantity and delete this version. Proceed?"
          />
        )}
        <Modal
          size="md"
          animation={true}
          centered
          backdrop="static"
          show={show}
          className={"nested-modal-z-index"}
          backdropClassName={"nested-modal-z-index"}
        >
          <Modal.Body>
            {" "}
            <Row className="m-0 p-0">
              <Col
                md={3}
                className="img-Div-stolen d-flex p-0 box-shadow-default"
              >
                <img
                  src={state?.data?.imgUrl ? state.data.imgUrl : ProductImage}
                  alt=""
                />
              </Col>
              <Col md={9} className="m-0 pe-0">
                <Row className="m-0 p-0 h-100">
                  <Col md={12} className="p-0 mt-2 stolen-card-text ">
                    Was {state?.data?.product_name} lost or stolen?
                  </Col>
                  <Col md={12} className="mt-auto p-0 d-flex gap-2 ">
                    <Button
                      type="button"
                      label={buttonNameConstants.NO}
                      buttonType={buttonTypeConstants.GHOST_BUTTON}
                      className="w-100"
                      handleClick={() => {
                        // handleCancelButton();
                        handleShrinkModalSubmit(false);
                      }}
                    ></Button>
                    <Button
                      type="button"
                      className="w-100"
                      handleClick={() => {
                        // setShrinkValue(buttonNameConstants.YES);
                        handleShrinkModalSubmit(true);
                      }}
                      label={buttonNameConstants.YES}
                    ></Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        <Modal
          size="xl"
          animation={true}
          centered
          backdrop="static"
          show={showIframe}
          onHide={handleCloseIfram}
          // className="iframe-edit-inventory"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <iframe
              title="Product Iframe"
              src={productUrl}
              className="iframe-website-edit-inventory  w-100"
            />
          </Modal.Body>
        </Modal>
        <PrintLabelModal
          printLabelModal={printLabelModal}
          togglePrintLabelModal={togglePrintLabelModal}
          // selectedRows={selectedRows}
          singlePrintArray={[state?.data]}
        />
      </Row>
    </>
  );
};

//-------EditInventory component ends here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  inventory: state.inventory,
  tradeDiscount: state.tradeDiscount,
  inventoryProducts: state.inventory.inventoryProducts,
  priceChartingProductInfo: state.inventory.priceChartingProductInfo,
  productTags: state.inventory.productTags,
  currentStore: state.store.currentStore,
  customTypes: state.itemOrganization.customTypes,
  customCategories: state.itemOrganization.customCategories,
  customSubCategories: state.itemOrganization.customSubCategories,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  updateInventory: (
    storeId,
    data,
    isLabelNotification,
    gridState,
    isPageRedirect
  ) =>
    dispatch(
      inventoryActions.updateInventory(
        storeId,
        data,
        isLabelNotification,
        gridState,
        isPageRedirect
      )
    ),

  quantityShrink: (data) => dispatch(inventoryActions.quantityShrink(data)),
  getPricechartingProductById: (productMetaData, productId) =>
    dispatch(
      inventoryActions.getPricechartingProductById(productMetaData, productId)
    ),
  getScryFallProductById: (productMetaData, productId) =>
    dispatch(
      inventoryActions.getScryFallProductById(productMetaData, productId)
    ),
  emptyPriceChartingProductObject: () =>
    dispatch(inventoryActions.emptyPriceChartingProductObject()),
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  getInventoryByPricechartingId: (storeId, data) =>
    dispatch(inventoryActions.getInventoryByPricechartingId(storeId, data)),
  setPokemonProductInReducer: (productMetaData, value) =>
    dispatch(
      inventoryActions.setPokemonProductInReducer(productMetaData, value)
    ),
  getInventoryByCustomItemCheck: (storeId) =>
    dispatch(inventoryActions.getInventoryByCustomItemCheck(storeId)),
});

//-------Export EditInventory Component
export default connect(mapStateToProps, mapDispatchToProps)(EditInventory);

const skuToolTip = (state) => {
  return (
    state?.data?.sku?.length && (
      <>
        <p id="sku-drop" className="w-25 cursor-pointer text-nowrap">
          #{state.data.sku[0]}
        </p>
        <CustomTooltip
          anchorSelect={`#${"sku-drop"}`}
          place="bottom-start"
          className={"tooltip-for-text sku-tooltip"}
        >
          {state.data.sku.map((sku) => (
            <p className="p-0 m-0">{sku}</p>
          ))}
        </CustomTooltip>
      </>
    )
  );
};
