import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { connect } from "react-redux";

import { logErrors } from "../../../services";
import { LOGS_TYPES, toastType } from "../../../constants";
import { customToast } from "../../../shared/utility";
import ErrorScreen from "./ErrorScreen";

const ErrorBoundaryContainer = ({ children, user, currentStore }) => {
  const onError = async (e) => {
    try {
      await logErrors({
        type: LOGS_TYPES.ERROR_BOUNDARY_LOGS,
        errors: JSON.stringify(e, Object.getOwnPropertyNames(e)),
        metaData: `Store Name: ${currentStore?.storeName}, User Email: ${user.email}, Error Screen URL: ${window.location.href}`,
      });
    } catch (e) {
      customToast(e, toastType.ERROR);
    }
  };

  return (
    <ErrorBoundary fallback={<ErrorScreen />} onError={onError}>
      {children}
    </ErrorBoundary>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  user: state.authentication.user,
  currentStore: state.store.currentStore,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({});

//-------Export report Component
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorBoundaryContainer);
