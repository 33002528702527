import moment from "moment";
import { connect } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import { Row, Col } from "react-bootstrap";

import ReactApexChart from "react-apexcharts";
import Topbar from "../../shared/components/topbar/Topbar";
import { storeActions, systemActions } from "../../redux/actions";
import {
  parseToNumber,
  parseToThousandSeperatorDecimalNumber,
} from "../../shared/utility";
import DatetimePickerDropdown from "../../shared/components/datetimePickerDropdown/DatetimePickerDropdown";
import Billing from "../Billing/Billing";
import SimpleNumberField from "../../shared/components/inputNumberField/SimpleNumberField";
import {
  CloseIcon,
  PercentageIcon,
  TickIconLong,
} from "../../assets/icons/iconsProvider";
import { STORE_CARD_PAYMENT_TYPE_ENUMS } from "../../system/globalEnums";
const Payouts = (props) => {
  const { currentStore, payouts, getPayoutsInformation, editSaleTax } = props;
  const [timeframe, setTimeFrame] = useState({
    start: "",
    end: "",
    duration: "daily",
  });

  const series = [
    parseToNumber(payouts?.netCashSale),
    parseToNumber(payouts?.netCreditCardSale),
  ];

  const totalEarnings = useMemo(() => {
    return (payouts?.netCashSale ?? 0) + (payouts?.netCreditCardSale ?? 0);
  }, [payouts]);

  const options = useMemo(
    () => ({
      chart: {
        id: `basic-bar${Math.random()}`,
        type: "donut",
      },
      labels: ["Cash Sales", "Credit Card Sales"],
      colors: ["#6A18ED", "rgba(217, 215, 219, 1)"],
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "80%",
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: "Earnings",
                color: "#6A18ED",
                formatter: function (w) {
                  return `$${parseToThousandSeperatorDecimalNumber(
                    totalEarnings
                  )}`;
                },
              },
            },
          },
        },
      },
      legend: {
        show: false,
      },
    }),
    [payouts]
  );

  const getTimeFrame = (value) => {
    setTimeFrame({
      ...timeframe,
      start: moment.utc(value.start).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      end: moment.utc(value.end).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      duration: value?.duration,
    });
  };

  useEffect(() => {
    if (currentStore && timeframe.start && timeframe.end) {
      getPayoutsInformation(currentStore?.id, timeframe.start, timeframe.end);
    } // eslint-disable-next-line
  }, [currentStore, timeframe]);
  useEffect(() => {
    setUpdateTax(payouts?.defaultTax); // eslint-disable-next-line
  }, [payouts]);

  const [isEditSaleTax, setIsEditSaleTax] = useState(false);
  const [updateTax, setUpdateTax] = useState("");
  const toggleEditSaleTax = () => {
    setIsEditSaleTax((prevValue) => !prevValue);
  };
  const hanldeEditSaleTaxCancel = () => {
    toggleEditSaleTax();
    setUpdateTax(payouts?.defaultTax);
  };
  const onChangeTaxValue = (inputValue) => {
    setUpdateTax(inputValue);
  };
  const handleEditedSaleTax = (values) => {
    toggleEditSaleTax();
    editSaleTax(
      {
        storeId: currentStore?.id,
        startDate: timeframe.start,
        endDate: timeframe.end,
      },
      {
        defaultTax: updateTax,
      }
    );
  };

  return (
    <div className="user-info-wrapper container-scrollbar">
      <Row className="m-0 d-flex justify-content-center w-100 ">
        <Col md={11}>
          <Topbar title="Earnings/Payouts"></Topbar>
          <Row className="m-0 d-flex justify-content-center align-items-center">
            <Col
              md={12}
              className="d-flex justify-content-start align-items-center"
            >
              <DatetimePickerDropdown getTimeFrame={getTimeFrame} />
            </Col>
            <Col md={12} className="d-flex mt-4">
              <div className="w-50 ">
                <ReactApexChart
                  options={options}
                  series={series}
                  type="donut"
                  height={300}
                />
              </div>
              <div className="w-50 d-flex flex-column p-3">
                <div className="w-100 p-2 d-flex justify-content-between align-items-center py-3 border-bottom">
                  <div className="d-flex align-items-center">
                    <span className="point-indicator"></span>Total Sales
                  </div>
                  <span className="payout-price">
                    $
                    {parseToThousandSeperatorDecimalNumber(
                      payouts?.totalSale || 0
                    )}
                  </span>
                </div>
                <div className="w-100 p-2 d-flex justify-content-between align-items-center py-3 border-bottom">
                  <div className="d-flex align-items-center">
                    <span className="point-indicator"></span>Net Sales
                  </div>
                  <span className="payout-price">
                    $
                    {parseToThousandSeperatorDecimalNumber(
                      payouts?.netSales || 0
                    )}
                  </span>
                </div>
                <div className="w-100 p-2 d-flex justify-content-between align-items-center py-3 border-bottom">
                  <div className="d-flex align-items-center">
                    <span className="point-indicator grey-color-indicator"></span>
                    Total Credit/Debit Sales
                  </div>
                  <span className="payout-price">
                    $
                    {parseToThousandSeperatorDecimalNumber(
                      payouts?.netCreditCardSale || 0
                    )}
                  </span>
                </div>
                <div className="w-100 p-2 d-flex justify-content-between align-items-center py-3 border-bottom">
                  <div className="d-flex align-items-center">
                    <span className="point-indicator"></span>Total Cash Sales
                  </div>
                  <span className="payout-price">
                    $
                    {parseToThousandSeperatorDecimalNumber(
                      payouts?.netCashSale || 0
                    )}
                  </span>
                </div>
                <div className="w-100 p-2 d-flex justify-content-between align-items-center py-3 border-bottom">
                  <div className="d-flex align-items-center">
                    <span className="point-indicator"></span>Total Returns
                  </div>
                  <span className="payout-price">
                    $
                    {parseToThousandSeperatorDecimalNumber(
                      payouts?.totalReturns || 0
                    )}
                  </span>
                </div>
                <div className="w-100 p-2 d-flex justify-content-between align-items-center py-3 border-bottom">
                  <div className="d-flex align-items-center">
                    <span className="point-indicator"></span>Gross Profit
                  </div>
                  <span className="payout-price">
                    $
                    {parseToThousandSeperatorDecimalNumber(
                      payouts?.grossProfit || 0
                    )}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="m-0 mt-3">
            <Col
              md={
                currentStore?.activeCardPaymentType ===
                STORE_CARD_PAYMENT_TYPE_ENUMS.THIRD_PARTY
                  ? 6
                  : 4
              }
            >
              <div className="payout-card">
                <span>Collected Tax</span>
                <span>
                  $
                  {parseToThousandSeperatorDecimalNumber(
                    payouts?.totalCollectedTax || 0
                  )}
                </span>
                {/* <span>
                  Collecting tax in <a href="#">United States</a>
                </span> */}
                {!isEditSaleTax ? (
                  <div className="d-flex gap-3">
                    <span>
                      {/* Collecting tax in <a href="#">United States</a> */}
                      Current Sales Tax Rate: {payouts.defaultTax}%
                    </span>
                    {!isEditSaleTax ? (
                      <button
                        type="button"
                        className="edit-budget-button p-0"
                        onClick={() => toggleEditSaleTax()}
                      >
                        Edit
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className="d-flex gap-1  p-1 pe-0">
                    <SimpleNumberField
                      // disabled={true}
                      name="tradeOffer"
                      placeHolder="0"
                      className="add-inventory-price-field input-background"
                      IconImage={PercentageIcon}
                      value={updateTax}
                      onChange={onChangeTaxValue}
                      maxValue={100}
                      minimumValue={0}
                    />
                    <div className="w-100 d-flex  align-content-center ">
                      <button
                        type="button"
                        className="edit-buttons  pe-0"
                        onClick={() => hanldeEditSaleTaxCancel()}
                      >
                        <CloseIcon className="close-button-purchasing-power" />
                      </button>
                      <button
                        onClick={() => handleEditedSaleTax()}
                        type="button"
                        className="edit-buttons pe-0"
                      >
                        <TickIconLong className="close-button-purchasing-power" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Col>

            <Col
              md={
                currentStore?.activeCardPaymentType ===
                STORE_CARD_PAYMENT_TYPE_ENUMS.THIRD_PARTY
                  ? 6
                  : 4
              }
            >
              <div className="payout-card">
                <span>Total Purchases</span>
                <span>
                  $
                  {parseToThousandSeperatorDecimalNumber(
                    payouts?.totalPurchases || 0
                  )}
                </span>
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    (Total Cash/Store Credit Spent to Acquire Products)
                  </span>
                </div>
              </div>
            </Col>
            {currentStore?.activeCardPaymentType ===
            STORE_CARD_PAYMENT_TYPE_ENUMS.THIRD_PARTY ? (
              ""
            ) : (
              <Col md={4}>
                <div className="payout-card">
                  <span>Next Payout (Card Sales)</span>
                  <span>$50.00</span>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>
                      {/* {calculateTimeFromNow(currentStore?.billingEndDate)} */}
                      Expected Payout Date: 04/10/2024
                    </span>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row className="m-0 d-flex justify-content-center w-100 ">
        {/* <Col md={8}> */}
        <Billing />
        {/* </Col> */}
      </Row>
    </div>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  currentStore: state.store.currentStore,
  payouts: state.store.payouts,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getPayoutsInformation: (storeId, startDate, endDate) =>
    dispatch(storeActions.getPayoutsInformation(storeId, startDate, endDate)),
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  editSaleTax: (ObjectDetail, data) =>
    dispatch(storeActions.editSaleTax(ObjectDetail, data)),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(Payouts);
