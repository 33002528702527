import { connect } from "react-redux";
import { Formik, Form } from "formik";
import ApexCharts from "react-apexcharts";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Row, Col } from "react-bootstrap";

import moment, { duration } from "moment";
import ReactDatePicker from "react-datepicker";
import Topbar from "../../shared/components/topbar/Topbar";
import { PRODUCT_TYPES_ENUMS } from "../../system/globalEnums";
import { dashboardActions, itemOrganizationActions } from "../../redux/actions";
import InputNumberField from "../../shared/components/inputNumberField/InputNumberField";
import CategoriesDropdown from "../../shared/components/selectDropdown/CategoriesDropdown";
import {
  customToast,
  getCustomTypesOptionList,
  getCustomCategoriesOptionList,
  isSpinnerEnabled,
  parseToDecimalNumber,
  parseToThousandSeperatorDecimalNumber,
  parseToThousandSeperatorWholeNumber,
  isDateInTimeRange,
  calculateBarWidth,
  NoDataWrapper,
} from "../../shared/utility";
import {
  ROLES,
  toastType,
  toastMessages,
  SIDEBAR_BUTTONS,
  globalConstants,
  systemConstants,
  dashboardConstants,
  categoryDropdownConstants,
  datepickerConstants,
} from "../../constants";
import {
  ChartIcon,
  RoundIcon,
  TradeIcon,
  TrendIcon,
  CloseIcon,
  DollarIcon,
  WalletIcon,
  TickIconLong,
  LeftArrowIcon,
  RightArrowIcon,
  ReturnArrowIcon,
  DashboardDollarIcon,
} from "../../assets/icons/iconsProvider";
import DashboardIconCardSkeleton from "../../shared/Skeletons/DashboardSkeletons/DashboardIconCardSkeleton";
import DropdownSkeleton from "../../shared/Skeletons/DropdownSkeleton/DropdownSkeleton";
import Skeleton from "react-loading-skeleton";
import DashboardLeftColumnSkeleton from "../../shared/Skeletons/DashboardSkeletons/DashboardLeftColumnSkeleton";
import DashboardDatePicker from "../../shared/components/datetimePickerDropdown/DashboardDatePicker";
import ToggleButtonWithText from "../../shared/components/toggleButtons/ToggleButtonWithText";
import DashboardProgressBar from "../../shared/components/progressBar/DashboardProgressBar";
import {
  aggregateSalesByBiWeek,
  aggregateSalesByDay,
  aggregateSalesByDayInMonth,
  aggregateSalesByHour,
  aggregateSalesByMonth,
  aggregateSalesCustom,
  calculateNumberOfDays,
  getLabel,
} from "./utility";

//-------Dashboard component start here
const Dashboard = (props) => {
  const {
    role,
    atAGlance,
    getAtAGlance,
    currentStore,
    spinnerArray,
    isStoreActive,
    itemOrganization,
    editPurchasingPower,
    setActiveSidebarBtn,
    storeTransactionHistory,
    getStoreTransactionHistory,
  } = props;

  //-------Reducer
  const { customTypes, customCategories } = itemOrganization;

  const [timeFrame, setTimeFrame] = useState({
    start: "",
    end: "",
    duration: dashboardConstants.DAILY,
  });
  const [byDate, setByDate] = useState([]);
  const [byMonth, setByMonth] = useState([]);
  const [isCashOnly, setIsCashOnly] = useState(false);
  const [graphEndDate, setGraphEndDate] = useState(timeFrame?.end);
  const [data, setData] = useState(atAGlance?.topFiveSoldItems || []);
  const [timeFilter, setTimeFilter] = useState(globalConstants.DAILY);
  const [graphStartDate, setGraphStartDate] = useState(timeFrame?.start);
  const [chartData, setChartData] = useState(storeTransactionHistory || []);
  const [isEditPurchasingPower, setIsEditPurchasingPower] = useState(false);
  const [productType, setProductType] = useState(globalConstants.EMPTY_STRING);
  const [isSortByVolume, setIsSortByVolume] = useState({
    topItems: false,
    category: false,
  });
  const [productCategory, setProductCategory] = useState(
    globalConstants.EMPTY_STRING
  );

  const initialVallues = {
    purchasingPower: parseToDecimalNumber(currentStore?.budget),
  };

  const columnHeaders = [
    {
      id: "productName",
      name: "Product Name",
      selector: (row) => row.itemName,
      cell: (row) => {
        return <span className="devices-cell-max-size">{row.itemName}</span>;
      },
      sortable: true,
      sortDirection: null,
      width: "35%",
    },
    {
      id: "price",
      name: "Price",
      selector: (row) => row.price,
      cell: (row) => {
        return (
          <span>$ {parseToThousandSeperatorDecimalNumber(row?.price)}</span>
        );
      },
      sortable: true,
      sortDirection: null,
      width: "20%",
    },
    {
      id: "sold",
      name: "Sold",
      selector: (row) => row.numberOfSoldUnits,
      cell: (row) => {
        return <span>{row.soldUnitsQty}</span>;
      },
      sortable: true,
      sortDirection: null,
      width: "15%",
    },
    {
      id: "status",
      name: "Status",
      selector: (row) => row.inStock,
      cell: (row) => {
        return (
          <span
            className={`text-left w-100 pe-4 d-flex align-items-center gap-2 ${
              row.inStock ? "in-stock-column" : "text-danger"
            }`}
          >
            <RoundIcon />
            <span className="text-nowrap">
              {row.inStock ? "In Stock" : "Out of Stock"}
            </span>
          </span>
        );
      },
      sortable: true,
      sortDirection: null,
      width: "30%",
    },
  ];

  const getLineChartConfig = (filter) => {
    let labels = [];
    let data = [];
    return {
      options: {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0.3,
            stops: [0, 90, 100],
          },
        },
        colors: ["#6A18ED"],
        xaxis: {
          type: "datetime",
          name: "Sales",
          // min: new Date(graphStartDate).getTime(),
          // max: new Date(graphEndDate).getTime(),
          labels: {
            datetimeUTC: false,
            formatter: (value) => getLabel(value, timeFrame),
            // datetimeFormatter: {
            //   year: "yyyy",
            //   month: "MMM 'yy",
            //   day: "dd MMM",
            //   hour: "hh:mm TT",
            // },
          },
        },
        stroke: { curve: "smooth", width: 1 },
        dataLabels: {
          enabled: false,
        },
        // markers: {
        //   size: 5,
        // },
      },
      series: [
        {
          name: "Sales",

          data:
            timeFrame?.duration === datepickerConstants.DAILY
              ? aggregateSalesByHour(chartData, timeFrame)
              : timeFrame?.duration === datepickerConstants.WEEKLY
              ? aggregateSalesByDay(chartData, timeFrame)
              : timeFrame?.duration === datepickerConstants.BIWEEKLY
              ? aggregateSalesByBiWeek(chartData, timeFrame)
              : timeFrame?.duration === datepickerConstants.MONTHLY
              ? aggregateSalesByDayInMonth(chartData, timeFrame)
              : timeFrame?.duration === datepickerConstants.YEARLY
              ? aggregateSalesByMonth(chartData, timeFrame)
              : timeFrame?.duration === datepickerConstants.CUSTOM
              ? aggregateSalesCustom(
                  chartData,
                  timeFrame,
                  calculateNumberOfDays(timeFrame?.start, timeFrame?.end)
                )
              : byMonth.map((js) => {
                  return {
                    x: new Date(js?.createdOn ?? 0).getTime(),
                    y: Number(js?.totalAmountToPay ?? 0).toFixed(2),
                  };
                }),
        },
      ],
    };
  };

  const { options, series } = getLineChartConfig(timeFilter);

  const togglePurchasingPower = () => {
    setIsEditPurchasingPower(!isEditPurchasingPower);
  };

  const hanldeEditBudgetClick = () => {
    togglePurchasingPower();
  };

  const handleClosePurchasingPower = () => {
    togglePurchasingPower();
  };

  const hanldePurchasingPowerSubmit = (values) => {
    togglePurchasingPower();
    editPurchasingPower(currentStore?.id, {
      budget: values.purchasingPower,
    });
  };

  const getTimeFrame = (value) => {
    setTimeFrame({
      ...timeFrame,
      start: moment.utc(value.start).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      end: moment.utc(value.end).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      duration: value?.duration,
    });
  };

  useEffect(() => {
    if (currentStore && timeFrame.start && timeFrame.end) {
      getAtAGlance(
        currentStore?.id,
        timeFrame.start,
        timeFrame.end,
        productCategory?.value || globalConstants.EMPTY_STRING,
        productType,
        timeFrame.duration
      );
      getStoreTransactionHistory(
        currentStore.id,
        "Transaction",
        timeFrame.start,
        timeFrame.end,
        timeFrame.duration
      );
    }
    setGraphStartDate(timeFrame.start);
    setGraphEndDate(timeFrame.end);
  }, [timeFrame, currentStore]);

  function formatMonth(date) {
    let nDate = new Date(date);
    nDate.setDate(2);

    // Now 'date' contains the first date of the month

    // You can format the 'date' as a local date string if needed
    var firstDateOfMonthString = nDate.toLocaleDateString(); // Example: "10/01/2023" (may vary based on your locale)

    return firstDateOfMonthString;
  }

  const handleCategoryChange = (selectedOption) => {
    setProductCategory(selectedOption);
    getAtAGlance(
      currentStore?.id,
      timeFrame.start,
      timeFrame.end,
      selectedOption?.label === PRODUCT_TYPES_ENUMS.ALL
        ? ""
        : selectedOption?.label,
      productType?.label,
      timeFrame.duration
    );
  };
  const handleProductTypeChange = (selectedOption) => {
    const productTypeValue =
      selectedOption?.label === PRODUCT_TYPES_ENUMS.ALL
        ? ""
        : selectedOption?.label;
    setProductType(selectedOption);
    setProductCategory(globalConstants.EMPTY_STRING);
    getAtAGlance(
      currentStore?.id,
      timeFrame.start,
      timeFrame.end,
      globalConstants.EMPTY_STRING,
      productTypeValue,
      timeFrame.duration
    );
  };

  const getPreviousDate = () => {
    const dateRange = atAGlance?.storeProgress?.dateRange;
    const previousStartDate = dateRange?.previousStart;
    const previousEndDate = dateRange?.previousEnd;
    switch (timeFrame?.duration) {
      case datepickerConstants.DAILY:
        return moment(previousEndDate).format("MM/DD/YYYY");
      case datepickerConstants.WEEKLY:
      case datepickerConstants.BIWEEKLY:
      case datepickerConstants.CUSTOM:
        return `${moment(previousStartDate).format("MM/DD/YYYY")} - ${moment(
          previousEndDate
        ).format("MM/DD/YYYY")}`;
      case datepickerConstants.MONTHLY:
        return `${moment(previousStartDate).format("MMMM")}`;
      case datepickerConstants.YEARLY:
        return `${moment(previousStartDate).format("yyyy")}`;
      default:
        return ""; // Return an empty string if no case matches
    }
  };

  const getTrendHistory = (value) => {
    return (
      <>
        <TrendIcon className={`trend-icon ${value < 0 && "down-trend-icon"}`} />
        <span className={value < 0 && "text-danger"}>
          {value || 0}%{" "}
          <span className="light-text-color">from {getPreviousDate()}</span>
        </span>
      </>
    );
  };

  const handleGraphStartDate = (e) => {
    const value = moment(e).format(datepickerConstants.DATE_TIME_FORMAT);
    if (value <= graphEndDate) {
      setGraphStartDate(value);
    } else {
      customToast(
        toastMessages.START_DATE_MUST_BE_LESS_THAN_END_DATE,
        toastType.ERROR
      );
    }
  };

  const handleGraphEndDate = (e) => {
    const value = moment(e).format(datepickerConstants.DATE_TIME_FORMAT);
    if (graphStartDate <= value) {
      setGraphEndDate(value);
    } else {
      customToast(
        toastMessages.END_DATE_MUST_BE_GREATER_THAN_START_DATE,
        toastType.ERROR
      );
    }
  };

  const handleDatePickerNextButtonClick = (value) => {
    if (value === dashboardConstants.GRAPH_START_DATE) {
      const updatedDate = moment(graphStartDate)
        .add(1, "days")
        .format(datepickerConstants.DATE_TIME_FORMAT);
      handleGraphStartDate(updatedDate);
    } else {
      const updatedDate = moment(graphEndDate)
        .add(1, "days")
        .format(datepickerConstants.DATE_TIME_FORMAT);
      handleGraphEndDate(updatedDate);
    }
  };

  const handleDatePickerBackButtonClick = (value) => {
    if (value === dashboardConstants.GRAPH_START_DATE) {
      const updatedDate = moment(graphStartDate)
        .subtract(1, "days")
        .format(datepickerConstants.DATE_TIME_FORMAT);
      handleGraphStartDate(updatedDate);
    } else {
      const updatedDate = moment(graphEndDate)
        .subtract(1, "days")
        .format(datepickerConstants.DATE_TIME_FORMAT);
      handleGraphEndDate(updatedDate);
    }
  };

  const toggleCashOnly = () => {
    setIsCashOnly(!isCashOnly);
  };

  const toggleSortByVolume = (key) => {
    setIsSortByVolume((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  useEffect(() => {
    setChartData(storeTransactionHistory || []);
    const dailyGroupedData = {};
    const monthlyGroupedData = {};

    // Iterate through the original data
    storeTransactionHistory?.forEach((item) => {
      const date = item.createdOn.substr(0, 10); // Extract the date portion (YYYY-MM-DD)
      if (!dailyGroupedData[date]) {
        dailyGroupedData[date] = {
          createdOn: date,
          totalAmount: 0,
        };
      }
      dailyGroupedData[date].totalAmount += Number(item.TotalAmountPaid || 0);

      const yearMonth = formatMonth(item.createdOn); // Format the month
      if (!monthlyGroupedData[yearMonth]) {
        monthlyGroupedData[yearMonth] = {
          createdOn: yearMonth,
          totalAmount: 0,
        };
      }
      monthlyGroupedData[yearMonth].totalAmount += Number(
        item.TotalAmountPaid || 0
      );
    });

    // Convert the grouped data objects into arrays
    setByDate(Object.values(dailyGroupedData));
    setByMonth(Object.values(monthlyGroupedData));
  }, [storeTransactionHistory]);

  useEffect(() => {
    setData(atAGlance?.topFiveSoldItems || []);
  }, [atAGlance]);

  useEffect(() => {
    setActiveSidebarBtn(SIDEBAR_BUTTONS.DASHBOARD);
  }, []);

  useEffect(() => {
    if (graphStartDate && graphEndDate) {
      getStoreTransactionHistory(
        currentStore.id,
        "Transaction",
        moment(graphStartDate).startOf("day").toISOString(),
        moment(graphEndDate).endOf("day").toISOString(),
        timeFrame?.duration
      );
    }
  }, [graphEndDate, graphStartDate]);

  return isStoreActive || role === ROLES.ADMIN ? (
    <>
      <div className={`user-info-wrapper container-scrollbar`}>
        <Row className="m-0 d-flex flex-column align-items-center">
          <Col lg={12} className="px-0 dashboard-max-width">
            <Topbar title="Dashboard"></Topbar>
          </Col>

          <Col
            lg={12}
            className="d-flex flex-wrap align-items-center gap-3 dashboard-max-width"
          >
            {isSpinnerEnabled(
              spinnerArray,
              dashboardConstants.GET_AT_A_GLANCE
            ) &&
              isSpinnerEnabled(
                spinnerArray,
                dashboardConstants.GET_STORE_HISTORY
              ) && (
                <>
                  <DropdownSkeleton /> <DropdownSkeleton /> <DropdownSkeleton />
                </>
              )}
            <div
              className={`d-flex justify-content-center align-items-center gap-3 ${
                isSpinnerEnabled(
                  spinnerArray,
                  dashboardConstants.GET_AT_A_GLANCE
                ) &&
                isSpinnerEnabled(
                  spinnerArray,
                  dashboardConstants.GET_STORE_HISTORY
                )
                  ? "opacity-0 hide-filter-dropdowns "
                  : "opacity-100"
              }  `}
            >
              <DashboardDatePicker label="Date" getTimeFrame={getTimeFrame} />
              <CategoriesDropdown
                placeholder="Type"
                label="Type"
                className="dashboard-dropdown-type "
                options={getCustomTypesOptionList(customTypes)?.filter(
                  (item) => item.label !== PRODUCT_TYPES_ENUMS.ALL
                )}
                noOptionsMessage="No Type Found"
                SelectIconImage={categoryDropdownConstants.DEFAULT_ICON}
                handleChange={(selectedOption) => {
                  setProductType(selectedOption);
                  handleProductTypeChange(selectedOption);
                }}
              />
              <CategoriesDropdown
                placeholder="Category"
                label="Category"
                value={productCategory}
                options={getCustomCategoriesOptionList(
                  customCategories,
                  productType?.value,
                  productType?.label
                )}
                className="dashboard-dropdown-category"
                noOptionsMessage="No Category Found"
                SelectIconImage={categoryDropdownConstants.DEFAULT_ICON}
                handleChange={(selectedOption) => {
                  handleCategoryChange(
                    selectedOption || globalConstants.EMPTY_STRING
                  );
                }}
              />
            </div>
          </Col>

          <Col lg={12} className="dashboard-max-width">
            <Row className="m-0">
              <Col md={5} className="px-0">
                {!isSpinnerEnabled(
                  spinnerArray,
                  dashboardConstants.GET_AT_A_GLANCE
                ) ? (
                  <Row className="m-0 mt-2">
                    <Col md={12} className="px-0">
                      <div className="dashboard-card-wrapper glance-wrapper">
                        <span className="card-heading">At a Glance</span>

                        <Row className="m-0">
                          <Col md={4} className="ps-md-0">
                            <div className="dashboard-mini-inner-card d-flex flex-column justify-content-center gap-2">
                              <span>Total Sales</span>
                              <span>
                                $
                                {parseToThousandSeperatorDecimalNumber(
                                  atAGlance?.totalSale || 0
                                )}
                              </span>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="dashboard-mini-inner-card d-flex flex-column justify-content-center gap-2">
                              <span>Sales</span>
                              <span>
                                {parseToThousandSeperatorWholeNumber(
                                  atAGlance?.numberOfSales || 0
                                )}
                              </span>
                            </div>
                          </Col>
                          <Col md={4} className="pe-md-0">
                            <div className="dashboard-mini-inner-card d-flex flex-column justify-content-center gap-2">
                              <span>Trade Ins</span>
                              <span>
                                {parseToThousandSeperatorWholeNumber(
                                  atAGlance?.numberOfTradeIns || 0
                                )}
                              </span>
                            </div>
                          </Col>
                          <Col md={12} className="px-md-0">
                            <div className="dashboard-mini-inner-card d-flex flex-column justify-content-center gap-2">
                              <span>Best Selling Category</span>
                              <span>
                                {atAGlance?.bestSellingStats
                                  ?.bestSellingCategory || "None"}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col md={12} className="px-0 mt-2">
                      <Row className="m-0">
                        <Col md={6} className="p-0">
                          <div className="square-card-wrapper square-card-wrapper-min-height me-md-1 mb-2">
                            <DashboardDollarIcon className="square-card-icon" />
                            <div className="d-flex flex-column justify-content-center">
                              <span className="light-text-color">
                                Cost of Goods
                              </span>
                              <span className="square-card-price-heading">
                                $
                                {parseToThousandSeperatorDecimalNumber(
                                  atAGlance?.totalCogs || 0
                                )}
                              </span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                              {getTrendHistory(
                                atAGlance?.storeProgress?.totalCogsHistory
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col md={6} className="p-0">
                          <div className="square-card-wrapper square-card-wrapper-min-height ms-md-1 mb-2">
                            <ChartIcon className="square-card-icon" />
                            <div className="d-flex flex-column justify-content-center">
                              <span className="light-text-color">
                                Gross Profit
                              </span>
                              <span className="square-card-price-heading">
                                $
                                {parseToThousandSeperatorDecimalNumber(
                                  atAGlance?.grossProfit || 0
                                )}
                              </span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                              {getTrendHistory(
                                atAGlance?.storeProgress?.grossProfitHistory
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col md={6} className="p-0">
                          <div className="square-card-wrapper square-card-wrapper-min-height me-md-1 mb-2">
                            <TradeIcon className="square-card-icon" />
                            <div className="d-flex flex-column justify-content-center">
                              <span className="light-text-color">Trade In</span>
                              <span className="square-card-price-heading">
                                $
                                {parseToThousandSeperatorDecimalNumber(
                                  atAGlance?.totalStockPrice || 0
                                )}
                              </span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                              {getTrendHistory(
                                atAGlance?.storeProgress?.totalTradeInsHistory
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col md={6} className="p-0">
                          <div className="square-card-wrapper square-card-wrapper-min-height ms-md-1 mb-2">
                            <div className="d-flex align-content-center justify-content-between">
                              <TradeIcon className="square-card-icon mt-auto mb-auto" />
                              <ToggleButtonWithText
                                isToggle={isCashOnly}
                                handleLeftButtonClick={toggleCashOnly}
                                handleRightButtonClick={toggleCashOnly}
                                leftButtonLabel="All"
                                rightButtonLabel="Cash"
                                className="discount-toggle-button dashboard-card-button"
                              />
                            </div>

                            <div className="d-flex flex-column justify-content-center">
                              <span className="light-text-color">
                                Total Purchases
                              </span>
                              <span className="square-card-price-heading">
                                $
                                {parseToThousandSeperatorDecimalNumber(
                                  isCashOnly
                                    ? atAGlance?.totalCashTradeIns || 0
                                    : atAGlance?.totalTradeIns || 0
                                )}
                              </span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                              {getTrendHistory(
                                atAGlance?.storeProgress?.totalTradeInsHistory
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col md={6} className="p-0">
                          <div className="square-card-wrapper square-card-wrapper-min-height me-md-1 mb-2">
                            <ReturnArrowIcon className="square-card-icon" />
                            <div className="d-flex flex-column justify-content-center">
                              <span className="light-text-color">Returns</span>
                              <span className="square-card-price-heading">
                                $
                                {parseToThousandSeperatorDecimalNumber(
                                  atAGlance?.totalReturns || 0
                                )}
                              </span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                              {getTrendHistory(
                                atAGlance?.storeProgress?.totalReturnsHistory
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col md={6} className="p-0">
                          <div className="square-card-wrapper square-card-wrapper-min-height ms-md-1 mb-2">
                            <WalletIcon className="square-card-icon" />
                            <div className="d-flex flex-column justify-content-center">
                              <span className="light-text-color">
                                Avg. Order Value
                              </span>
                              <span className="square-card-price-heading">
                                $
                                {parseToThousandSeperatorDecimalNumber(
                                  atAGlance?.averageOrderValue || 0
                                )}
                              </span>
                            </div>
                            <div className="d-flex gap-1 align-items-center">
                              {getTrendHistory(
                                atAGlance?.storeProgress
                                  ?.averageOrderValueHistory
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    {isDateInTimeRange(timeFrame) && (
                      <Col md={12} className="px-0">
                        <div
                          className={`dashboard-card-wrapper ${
                            isEditPurchasingPower && "active-card"
                          }`}
                        >
                          <Row className="m-0">
                            <Col
                              md={6}
                              className="card-wrapper-wallet remaing-purchase-power-min-height  d-flex flex-column justify-content-center align-items-start p-0"
                            >
                              <span>Remaining Purchasing Power</span>
                              <span>
                                $
                                {parseToThousandSeperatorDecimalNumber(
                                  currentStore?.budget -
                                    atAGlance?.totalCashTradeInsOfCurrentMonth ||
                                    0
                                )}
                              </span>
                            </Col>
                            <Col
                              md={6}
                              className="d-flex justify-content-end align-items-center"
                            >
                              {!isEditPurchasingPower ? (
                                <button
                                  type="button"
                                  className="edit-budget-button"
                                  onClick={() => hanldeEditBudgetClick()}
                                >
                                  Edit Budget
                                </button>
                              ) : (
                                <div className="edit-purchasing-power-wrapper d-flex flex-column justify-content-center gap-2">
                                  <Formik
                                    initialValues={initialVallues}
                                    onSubmit={hanldePurchasingPowerSubmit}
                                  >
                                    <Form>
                                      <InputNumberField
                                        name="purchasingPower"
                                        placeHolder="0"
                                        className="purchasing-power-field"
                                        IconImage={DollarIcon}
                                      />
                                      <div className="w-100 d-flex justify-content-center align-content-center gap-2 mt-2">
                                        <button
                                          type="button"
                                          className="edit-buttons"
                                          onClick={() =>
                                            handleClosePurchasingPower()
                                          }
                                        >
                                          <CloseIcon className="close-button-purchasing-power" />
                                        </button>
                                        <button
                                          type="submit"
                                          className="edit-buttons"
                                        >
                                          <TickIconLong className="close-button-purchasing-power" />
                                        </button>
                                      </div>
                                    </Form>
                                  </Formik>
                                </div>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    )}
                  </Row>
                ) : (
                  <DashboardLeftColumnSkeleton />
                )}
              </Col>
              <Col md={7} className="pe-0 ps-2">
                <Row className="m-0 mt-2">
                  <Col md={12} className="px-0">
                    <div className="dashboard-card-wrapper graph-card-wrapper">
                      <Row className="m-0">
                        {!isSpinnerEnabled(
                          spinnerArray,
                          dashboardConstants.GET_STORE_HISTORY
                        ) ? (
                          <Col md={12}>
                            <span className="card-heading">Sales</span>
                            {chartData?.length ? (
                              <>
                                <div className="dashboard-chart-wrapper">
                                  <ApexCharts
                                    options={options}
                                    series={series}
                                    type="area"
                                    height={150}
                                  />
                                </div>
                              </>
                            ) : (
                              <div className="d-flex justify-content-center align-items-center flex-column no-graph-data-wrapper">
                                <p>No Sales in this date range</p>
                                <p>Try selecting a different date range</p>
                              </div>
                            )}
                            {/* Hide Graph Filters for now */}
                            {/* <div className="d-flex justify-content-end w-75 ms-auto gap-2 ">
                              <div className="d-flex justify-content-center align-items-center gap-3 graph-datepicker-container">
                                From
                                <div className="react-datepicker-wrapper">
                                  <ReactDatePicker
                                    value={moment(graphStartDate).format(
                                      "MMM DD / YYYY"
                                    )}
                                    className="graph-datepicker"
                                    onChange={handleGraphStartDate}
                                  />
                                  <div className="datepicker-buttons-wrapper">
                                    <button
                                      type="button"
                                      className="datepicker-next-button"
                                      onClick={() =>
                                        handleDatePickerNextButtonClick(
                                          dashboardConstants.GRAPH_START_DATE
                                        )
                                      }
                                    >
                                      <LeftArrowIcon className="datepicker-next-icon" />
                                    </button>
                                    <button
                                      type="button"
                                      className="datepicker-previous-button"
                                      onClick={() =>
                                        handleDatePickerBackButtonClick(
                                          dashboardConstants.GRAPH_START_DATE
                                        )
                                      }
                                    >
                                      <RightArrowIcon className="datepicker-previous-icon" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                              
                              <div className="d-flex justify-content-center align-items-center gap-3 graph-datepicker-container">
                                To
                                <div className="react-datepicker-wrapper">
                                  <ReactDatePicker
                                    value={moment(graphEndDate).format(
                                      "MMM DD / YYYY"
                                    )}
                                    className="graph-datepicker"
                                    onChange={handleGraphEndDate}
                                  />
                                  <div className="datepicker-buttons-wrapper">
                                    <button
                                      type="button"
                                      className="datepicker-next-button"
                                      onClick={() =>
                                        handleDatePickerNextButtonClick(
                                          dashboardConstants.GRAPH_END_DATE
                                        )
                                      }
                                    >
                                      <LeftArrowIcon className="datepicker-next-icon" />
                                    </button>
                                    <button
                                      type="button"
                                      className="datepicker-previous-button"
                                      onClick={() =>
                                        handleDatePickerBackButtonClick(
                                          dashboardConstants.GRAPH_END_DATE
                                        )
                                      }
                                    >
                                      <RightArrowIcon className="datepicker-previous-icon" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </Col>
                        ) : (
                          <DashboardIconCardSkeleton
                            cardStyle={dashboardConstants.GRAPH_CARD_STYLE}
                          />
                        )}
                      </Row>
                    </div>
                  </Col>
                  <Col md={12} className="px-0 mt-2">
                    <div className="dashboard-card-wrapper ">
                      {!isSpinnerEnabled(
                        spinnerArray,
                        dashboardConstants.GET_AT_A_GLANCE
                      ) ? (
                        <Row className="m-0">
                          <Col
                            md={12}
                            className="mb-2 p-0 d-flex justify-content-between align-items-center w-100 "
                          >
                            <span className="card-heading">
                              Top 5 Selling Items
                            </span>
                            <div className="d-flex justify-content-center align-items-center gap-2">
                              <ToggleButtonWithText
                                isToggle={isSortByVolume.topItems}
                                handleLeftButtonClick={() =>
                                  toggleSortByVolume("topItems")
                                }
                                handleRightButtonClick={() =>
                                  toggleSortByVolume("topItems")
                                }
                                leftButtonLabel="Vol"
                                rightButtonLabel="Qty"
                                className="discount-toggle-button dashboard-card-button"
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            {atAGlance?.bestSellingStats?.topFiveSellingItems
                              ?.byQuantity?.length &&
                            atAGlance?.bestSellingStats?.topFiveSellingItems
                              ?.byVolume?.length ? (
                              <DataTable
                                data={
                                  isSortByVolume?.topItems
                                    ? atAGlance?.bestSellingStats
                                        ?.topFiveSellingItems?.byQuantity || []
                                    : atAGlance?.bestSellingStats
                                        ?.topFiveSellingItems?.byVolume || []
                                }
                                columns={columnHeaders}
                                fixedHeader
                              />
                            ) : (
                              NoDataWrapper()
                            )}
                          </Col>
                        </Row>
                      ) : (
                        <DashboardIconCardSkeleton
                          cardStyle={dashboardConstants.TOP_SELLING_ITEMS}
                        />
                      )}
                    </div>
                  </Col>
                  <Col md={12} className="px-0 mt-2">
                    <div className={`dashboard-card-wrapper`}>
                      {!isSpinnerEnabled(
                        spinnerArray,
                        dashboardConstants.GET_AT_A_GLANCE
                      ) ? (
                        <Row className="m-0 w-100">
                          <Col
                            md={12}
                            className="p-0 mb-2 d-flex justify-content-between align-items-center w-100"
                          >
                            <span className="card-heading">
                              Sales by Category
                            </span>
                            <div className="d-flex justify-content-center align-items-center gap-2">
                              <ToggleButtonWithText
                                isToggle={isSortByVolume.category}
                                handleLeftButtonClick={() =>
                                  toggleSortByVolume("category")
                                }
                                handleRightButtonClick={() =>
                                  toggleSortByVolume("category")
                                }
                                leftButtonLabel="Vol"
                                rightButtonLabel="Qty"
                                className="discount-toggle-button dashboard-card-button"
                              />
                            </div>
                          </Col>

                          <DashboardProgressBar
                            atAGlance={atAGlance}
                            isSortByVolume={isSortByVolume}
                          />
                        </Row>
                      ) : (
                        <DashboardIconCardSkeleton
                          cardStyle={dashboardConstants.TOP_SELLING_ITEMS}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  ) : isStoreActive === false ? (
    <div className="d-flex flex-column flex-md-row justify-content-center align-items-center my-5 py-5">
      <h4 className="text-center p-0 m-0">
        Store is deactivated, please contact your administrator:
      </h4>
      &nbsp;
      <a className="text-primary" href="mailto:support@collectpos.com">
        <h4 className="text-center p-0 m-0">support@collectpos.com</h4>
      </a>
    </div>
  ) : (
    globalConstants.EMPTY_STRING
  );
};

//-------Dashboard component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  role: state.authentication.user.role,
  atAGlance: state.dashboard.atAGlance,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
  itemOrganization: state.itemOrganization,
  isStoreActive: state.store.currentStore.isStoreActive,
  storeTransactionHistory: state.dashboard.storeTransactionHistory,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  setActiveSidebarBtn: (data) =>
    dispatch({ type: systemConstants.SET_ACTIVE_SIDEBAR_BTN, data: data }),
  getAtAGlance: (selectedStore, start, end, category, productType, duration) =>
    dispatch(
      dashboardActions.getAtAGlance(
        selectedStore,
        start,
        end,
        category,
        productType,
        duration
      )
    ),
  getStoreTransactionHistory: (selectedStore, type, start, end, duration) =>
    dispatch(
      dashboardActions.getStoreTransactionHistory(
        selectedStore,
        type,
        start,
        end,
        duration
      )
    ),
  editPurchasingPower: (storeId, data) =>
    dispatch(dashboardActions.editPurchasingPower(storeId, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
