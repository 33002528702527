export const viewCartPrintDropdownStyle = {
  control: (base, state) => ({
    ...base,
    minHeight: "3em",
    maxHeight: "3em",
    minWidth: "12.4em",
    width: "100% !important",
    borderRadius: "0.75em",
    cursor: "Pointer",
    boxShadow:
      "0.8px 1.6px 3.2px 0px rgba(0, 0, 0, 0.02),2.4px 4.8px 6.4px 0px rgba(0, 0, 0, 0.02),5.6px 11.2px 8px 0px rgba(0, 0, 0, 0.01)",
    backgroundColor: "rgb(106, 24, 237, 0.2)",
    borderColor: "rgb(106, 24, 237, 0.2)",
    color: "#6a18ed",
    "&:hover": {
      border: "1px solid #6a18ed",
    },
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "10em",
    cursor: "Pointer",
    "::-webkit-scrollbar": {
      width: ".3em",
      height: ".3em",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#b7b5ba",
      borderRadius: "100px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#6a18ed",
    },
  }),
  menu: (base) => ({
    ...base,
    zIndex: 5,
    borderRadius: ".75em",
    overflow: "hidden",
    padding: ".75em",
    cursor: "Pointer",
  }),
  option: (base, state) => ({
    ...base,
    borderRadius: "0.5em",
    marginBottom: ".25em",
    marginTop: ".25em",
    cursor: "Pointer",
    backgroundColor: state.isSelected
      ? "rgb(106, 24, 237, 0.2)"
      : "transparent",
    color: "#6a18ed",
    "&:hover": {
      color: "#6a18ed",
      backgroundColor: "rgb(106, 24, 237, 0.2)",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    textWrap: "nowrap !important",
    color: "#6a18eds",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: "all .3s",
    color: "#6a18ed",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      opacity: "1",
      color: "#6a18ed",
    };
  },
};
