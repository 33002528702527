import React, { useEffect, useState } from "react";
import { Country, State, City } from "country-state-city";
import Select from "react-select";

import { countryCodesConstants } from "../../../constants";
import { Field, ErrorMessage } from "formik";
import ErrorTextMessage from "../errorTextMessage/ErrorTextMessage";
import { selectFieldStyle } from "../../../assets/scss/style";
import { globalConstants } from "../../../constants";

const removeDuplicateObjectsFromArray = (arr) => {
  const uniqueObjArray = [];
  return arr.filter((i, index) => {
    if (uniqueObjArray.includes(i.label)) {
      return false;
    } else {
      uniqueObjArray.push(i.label);
      return true;
    }
  });
};

const SelectFieldCity = (props) => {
  const {
    name = "city",
    placeHolder = "Select City",
    label = "City",
    isSearchable = true,
    cityValue,
    defaultValue = globalConstants.EMPTY_STRING,
  } = props;

  const [selectCityOptions, setSelectCityOptions] = useState(
    defaultValue
      ? [
          ...City.getCitiesOfCountry(countryCodesConstants.USA),
          ...City.getCitiesOfCountry(countryCodesConstants.CANADA),
        ]
          .filter((city) => {
            return city.name.toLowerCase().includes(defaultValue.toLowerCase());
          })
          .map((city) => ({ label: city.name, value: city.name }))
      : []
  );

  const handleCityOptions = (inputValue) => {
    if (inputValue.length > 3) {
      setSelectCityOptions(
        [
          ...City.getCitiesOfCountry(countryCodesConstants.USA),
          ...City.getCitiesOfCountry(countryCodesConstants.CANADA),
        ]
          .filter((city) => {
            return city.name.toLowerCase().includes(inputValue.toLowerCase());
          })
          .map((city) => ({ label: city.name, value: city.name }))
      );
    } else {
      setSelectCityOptions([]);
    }
  };

  return (
    <>
      <div>
        <label className="field-label" htmlFor={name}>
          {label && label}
        </label>
        <div>
          <Field name={name}>
            {({ field, form, value }) => (
              <Select
                value={cityValue}
                defaultValue={selectCityOptions.filter(
                  (option) => option.value === form.initialValues.city
                )}
                options={removeDuplicateObjectsFromArray(selectCityOptions)}
                isSearchable={isSearchable}
                placeholder={placeHolder}
                styles={selectFieldStyle}
                components={{
                  IndicatorSeparator: () => null,
                }}
                onChange={(selectedOption) =>
                  form.setFieldValue(field.name, selectedOption.value)
                }
                onInputChange={(inputValue) => {
                  handleCityOptions(inputValue);
                }}
              />
            )}
          </Field>
        </div>
        <ErrorMessage name={name} component={ErrorTextMessage} />
      </div>
    </>
  );
};

export default SelectFieldCity;
