import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

import Button from "../../../../shared/components/Buttons/Button";
import {
  buttonNameConstants,
  buttonTypeConstants,
  toastMessages,
  toastType,
  transactionConstants,
} from "../../../../constants";
import InventoryOverviewThreeCards from "../../../../shared/components/inventoryOverviewThreeCards/InventoryOverviewThreeCards";
import {
  CloseIcon,
  DollarIcon,
  TickIconLong,
} from "../../../../assets/icons/iconsProvider";
import SimpleNumberField from "../../../../shared/components/inputNumberField/SimpleNumberField";
import {
  customToast,
  isAllSystemHasSerialNumber,
  isSpinnerEnabled,
  parseToThousandSeperatorDecimalNumber,
} from "../../../../shared/utility";

const AddTradeBatchFooter = ({
  closeModal,
  data,
  spinnerArray,
  handleCashOfferValueChange,
  handleTradeOfferValueChange,
  cashTradeOfferValue,
  handleResetBatch,
  ...props
}) => {
  const [isEditTotalCashTrade, setIsEditTotalCashTrade] = useState({
    isEditCash: false,
    isEditTrade: false,
  });
  const [totalCashTrade, setTotalCashTrade] = useState({
    cashOfferValue: 0,
    tradeOfferValue: 0,
  });
  const hanldeEditTotalCashOffer = () => {
    setIsEditTotalCashTrade({
      ...isEditTotalCashTrade,
      isEditCash: !isEditTotalCashTrade.isEditCash,
    });
    setTotalCashTrade({
      ...totalCashTrade,
      cashOfferValue: cashTradeOfferValue.cashOfferValue,
    });
  };
  const hanldeEditTotalTradeOffer = () => {
    setIsEditTotalCashTrade({
      ...isEditTotalCashTrade,
      isEditTrade: !isEditTotalCashTrade.isEditTrade,
    });
    setTotalCashTrade({
      ...totalCashTrade,
      tradeOfferValue: cashTradeOfferValue.tradeOfferValue,
    });
  };
  const setTotalCashValue = (inputValue) => {
    setTotalCashTrade({
      ...totalCashTrade,
      cashOfferValue: inputValue,
    });
  };
  const setTotalTradeValue = (inputValue) => {
    setTotalCashTrade({
      ...totalCashTrade,
      tradeOfferValue: inputValue,
    });
  };
  const handleCashChange = () => {
    if (totalCashTrade.cashOfferValue > 0) {
      handleCashOfferValueChange(totalCashTrade.cashOfferValue);
      hanldeEditTotalCashOffer();
    } else {
      customToast(toastMessages.TOTAL_CASH_OFFER, toastType.ERROR);
      hanldeEditTotalCashOffer();
      setTotalCashTrade({
        ...totalCashTrade,
        cashOfferValue: cashTradeOfferValue.cashOfferValue,
      });
    }
  };
  const handleTradeChange = () => {
    if (totalCashTrade.tradeOfferValue > 0) {
      handleTradeOfferValueChange(totalCashTrade.tradeOfferValue);
      hanldeEditTotalTradeOffer();
    } else {
      customToast(toastMessages.TOTAL_TRADE_OFFER, toastType.ERROR);
      hanldeEditTotalTradeOffer();
      setTotalCashTrade({
        ...totalCashTrade,
        tradeOfferValue: cashTradeOfferValue.tradeOfferValue,
      });
    }
  };
  useEffect(() => {
    setTotalCashTrade({
      ...totalCashTrade,
      cashOfferValue: cashTradeOfferValue.cashOfferValue,
      tradeOfferValue: cashTradeOfferValue.tradeOfferValue,
    });
  }, [cashTradeOfferValue]);
  return (
    <>
      <Row className="m-0 mb-3 px-0  mt-4">
        <Col
          md={6}
          className="ps-0  d-flex flex-column align-items-center justify-content-center"
        >
          <Col xs={12} className="d-flex flex-column  gap-3">
            <div className="add-inventory-stock-price-wrapper">
              <div className="w-100 mb-3 d-flex justify-content-between align-items-center add-inventory-price-heading">
                <span className="addTrade-gray-text">Total Cash Offer: </span>
                {!isEditTotalCashTrade.isEditCash ? (
                  <div className="d-flex gap-3">
                    <div className="dashboard-card-wrapper background-gray d-flex gap-3 p-3">
                      <div
                        md={12}
                        className="card-wrapper-wallet d-flex flex-column justify-content-center align-items-start p-0"
                      >
                        <span>
                          $
                          {parseToThousandSeperatorDecimalNumber(
                            totalCashTrade.cashOfferValue || 0,
                            true
                          )}
                        </span>
                      </div>
                    </div>
                    {totalCashTrade.cashOfferValue ? (
                      <>
                        <button
                          type="button"
                          className="edit-budget-button p-0"
                          onClick={() => hanldeEditTotalCashOffer()}
                        >
                          Edit
                        </button>{" "}
                        <button
                          type="button"
                          className="edit-budget-button p-0"
                          onClick={() => handleResetBatch()}
                        >
                          Reset
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className="d-flex gap-1   p-1 pe-0">
                    <SimpleNumberField
                      // disabled={true}
                      name="cashOffer"
                      placeHolder="0"
                      className="add-inventory-price-field input-background"
                      IconImage={DollarIcon}
                      value={totalCashTrade.cashOfferValue}
                      onChange={setTotalCashValue}
                    />

                    <div className="w-100 d-flex justify-content-center align-content-center ">
                      <button
                        type="button "
                        className="edit-buttons  pe-0"
                        onClick={() => hanldeEditTotalCashOffer()}
                      >
                        <CloseIcon className="close-button-purchasing-power " />
                      </button>
                      <button
                        onClick={() => handleCashChange()}
                        type="submit"
                        className="edit-buttons pe-0"
                      >
                        <TickIconLong className="close-button-purchasing-power" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="w-100 d-flex justify-content-between align-items-center add-inventory-price-heading">
                <span className="addTrade-gray-text">Total Trade Offer: </span>
                {!isEditTotalCashTrade.isEditTrade ? (
                  <div className="d-flex gap-3">
                    <div className="dashboard-card-wrapper background-gray d-flex gap-3 p-3">
                      <div
                        md={12}
                        className="card-wrapper-wallet d-flex flex-column justify-content-center align-items-start p-0"
                      >
                        <span>
                          $
                          {parseToThousandSeperatorDecimalNumber(
                            totalCashTrade.tradeOfferValue || 0
                          )}
                        </span>
                      </div>
                    </div>
                    {totalCashTrade.tradeOfferValue ? (
                      <>
                        <button
                          type="button"
                          className="edit-budget-button p-0"
                          onClick={() => hanldeEditTotalTradeOffer()}
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="edit-budget-button p-0"
                          onClick={() => handleResetBatch()}
                        >
                          Reset
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div className="d-flex gap-1  p-1 pe-0">
                    <SimpleNumberField
                      // disabled={true}
                      name="tradeOffer"
                      placeHolder="0"
                      className="add-inventory-price-field input-background"
                      IconImage={DollarIcon}
                      value={totalCashTrade.tradeOfferValue}
                      onChange={setTotalTradeValue}
                    />
                    <div className="w-100 d-flex justify-content-center align-content-center ">
                      <button
                        type="button"
                        className="edit-buttons  pe-0"
                        onClick={() => hanldeEditTotalTradeOffer()}
                      >
                        <CloseIcon className="close-button-purchasing-power" />
                      </button>
                      <button
                        onClick={() => handleTradeChange()}
                        type="submit"
                        className="edit-buttons pe-0"
                      >
                        <TickIconLong className="close-button-purchasing-power" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Col>
        <Col md={6} className="pe-0">
          <InventoryOverviewThreeCards
            data={[
              {
                label: "Total Items",
                value: data.totalItems ?? 0,
              },
              {
                label: "Total Market Value",
                value:
                  "$" +
                  parseToThousandSeperatorDecimalNumber(
                    data.totalMarketPrice ?? 0,
                    true
                  ),
              },
              {
                label: "Total Stock Price",
                value:
                  "$" +
                  parseToThousandSeperatorDecimalNumber(
                    data.totalPrice ?? 0,
                    true
                  ),
              },
            ]}
          />
          <Row className="m-0 mt-3">
            <Col
              md={6}
              className="d-flex ps-0 justify-content-end align-items-center"
            >
              <Button
                label={buttonNameConstants.CLOSE}
                className="w-100 "
                type="button"
                handleClick={() => closeModal()}
                buttonType={buttonTypeConstants.GHOST_BUTTON}
                isDisabled={isSpinnerEnabled(
                  spinnerArray,
                  transactionConstants.ADD_TRADE_BATCH_ITEM
                )}
              />
            </Col>
            <Col md={6} className="d-flex pe-0 align-items-center">
              <Button
                label={buttonNameConstants.ADD_TO_CART}
                className="w-100"
                type="submit"
                handleClick={props.handleSubmitAddBatchInventory}
                isDisabled={
                  !Number(data.totalItems) ||
                  cashTradeOfferValue.cashOfferValue <= 0 ||
                  cashTradeOfferValue.tradeOfferValue <= 0 ||
                  isSpinnerEnabled(
                    spinnerArray,
                    transactionConstants.ADD_TRADE_BATCH_ITEM
                  ) ||
                  isAllSystemHasSerialNumber(
                    props.currentStore,
                    props.addTradeBatchInventory
                  )
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AddTradeBatchFooter;
