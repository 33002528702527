import React from "react";
import DatePicker from "react-datepicker";

import {
  LeftArrowIcon,
  RightArrowIcon,
} from "../../../assets/icons/iconsProvider";
import { EMPTY_STRING } from "../../../constants";

const CustomDatePicker = ({
  nextKey,
  onChange,
  previousKey,
  customEndDate,
  selectsRange = false,
  handleNextOrPreviousClick,
  showMonthYearPicker = false,
  isStartDateSelected = false,
  className = "daily-datepicker",
  customStartDate = EMPTY_STRING,
  dateFormat = "MM/dd/YYYY",
  showYearPicker = false,
}) => {
  return (
    <div className={`datetime-background ${className}`}>
      <button
        type="button"
        className="date-picker-left-arrow-button"
        onClick={() => handleNextOrPreviousClick(previousKey)}
      >
        <LeftArrowIcon className="datepicker-next-icon" />
      </button>
      <DatePicker
        endDate={customEndDate}
        dateFormat={dateFormat}
        startDate={customStartDate}
        selectsRange={selectsRange}
        showYearPicker={showYearPicker}
        disabledKeyboardNavigation={false}
        showMonthYearPicker={showMonthYearPicker}
        selected={isStartDateSelected ? customStartDate : customEndDate}
        onChange={(e) => {
          onChange(e);
        }}
      />
      <button
        type="button"
        className="date-picker-right-arrow-button"
        onClick={() => handleNextOrPreviousClick(nextKey)}
      >
        <RightArrowIcon className="datepicker-previous-icon" />
      </button>
    </div>
  );
};

export default CustomDatePicker;
