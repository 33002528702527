import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import { Col, Modal, Row, Spinner } from "react-bootstrap";

import Button from "../../../../shared/components/Buttons/Button";
import {
  GLOBAL_FILTER_ENUMS,
  PRODUCT_TYPES_ENUMS,
} from "../../../../system/globalEnums";
import AGGridTable from "../../../../shared/components/agGrid/AGGridTable";
import CustomGridHeader from "../../../../shared/components/agGrid/CustomGridHeader";
import SelectDropDown from "../../../../shared/components/selectDropdown/SelectDropdown";
import SimpleNumberField from "../../../../shared/components/inputNumberField/SimpleNumberField";
import {
  AddIcon,
  DeleteIcon,
  DollarIcon,
  PercentageIcon,
} from "../../../../assets/icons/iconsProvider";
import {
  globalConstants,
  inventoryConstants,
  buttonNameConstants,
  buttonTypeConstants,
  priceChangesConstants,
} from "../../../../constants";
import {
  stringifyObject,
  getDefaultFilterType,
  getThresholdFilterList,
  getCustomCategoriesOptionList,
  isSpinnerEnabled,
} from "../../../../shared/utility";
import ToggleButtonWithText from "../../../../shared/components/toggleButtons/ToggleButtonWithText";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";

const PriceChangesFilter = ({
  customTypes,
  currentStore,
  thresholdData,
  spinnerArray,
  isFilterModal,
  customCategories,
  toggleFilterModal,
  addPriceChangeThreshold,
}) => {
  const [categoriesList, setCategoriesList] = useState([]);
  const [isToggle, setIsToggle] = useState(thresholdData?.global?.valueType);
  const [globalThreshold, setGlobalThreshold] = useState({
    type: 0,
    value: "0.00",
  });
  const [customThreshold, setCustomThreshold] = useState({
    filterType: 0,
    filterValue: 0,
  });
  const [customFilter, setCustomFilter] = useState({
    filterType: globalConstants.EMPTY_STRING,
    filterMinimumValue: "0.00",
  });
  const [filterList, setFilterList] = useState([]);

  const handleHideModal = () => {
    setFilterList([]);
    setCustomFilter({
      filterType: globalConstants.EMPTY_STRING,
      filterMinimumValue: "0.00",
    });
    setCustomThreshold({
      filterType: 0,
      filterValue: 0,
    });
    setGlobalThreshold({
      type: thresholdData?.global?.valueType || 0,
      value: thresholdData?.global?.value || "0.00",
    });
    updateFilterList();
    toggleFilterModal();
  };

  const handleAddCustomFilter = () => {
    const dataToSend = {
      id: thresholdData?.id || inventoryConstants.INITIAL_THRESHOLD_ID,
      byCategory:
        filterList
          .filter((item) => !item.isCategory)
          .map((item) => ({
            categoryId: item.categoryId,
            categoryName: item.categoryName,
            value: item.value,
            valueType: item.valueType,
          })) || [],

      byPriceRange:
        filterList
          .filter((item) => item.isCategory)
          .map((item) => ({
            min: item.min,
            max: item.max,
            value: item.value,
            valueType: item.valueType,
          })) || [],

      global: {
        value: globalThreshold.value,
        valueType: globalThreshold.type,
      },
      store: {
        id: currentStore.id,
        name: currentStore.storeName,
      },
    };
    if (dataToSend.id === inventoryConstants.INITIAL_THRESHOLD_ID) {
      delete dataToSend["id"];
    }
    addPriceChangeThreshold(dataToSend, handleHideModal);
  };

  const handleAddToList = () => {
    const ItemToAdd = !customThreshold.filterType
      ? {
          categoryId: customFilter?.filterType?.value,
          categoryName: customFilter?.filterType?.label,
          value: customFilter?.filterMinimumValue,
        }
      : {
          min: customFilter?.filterType?.value?.min,
          max: customFilter?.filterType?.value?.max,
          value: customFilter?.filterMinimumValue,
        };
    let updatedFilterList = [
      ...filterList,
      {
        ...ItemToAdd,
        isCategory: customThreshold.filterType,
        valueType: customThreshold.filterValue,
      },
    ];

    updatedFilterList = updatedFilterList.map((item, index) => ({
      ...item,
      id: index,
    }));

    setFilterList(updatedFilterList);
    setCustomFilter({
      filterType: globalConstants.EMPTY_STRING,
      filterMinimumValue: "0.00",
    });
  };

  const handleDeleteItem = (params) => {
    const tableData = params?.api
      ?.getRenderedNodes()
      ?.map((node) => node?.data);

    const updatedList = tableData.filter((item) => item.id !== params.data.id);
    const newList = updatedList.map((item, index) => ({
      ...item,
      id: index,
    }));
    setFilterList(newList);
  };
  //-------UseRef
  const gridRef = useRef();

  const filterColDefs = useMemo(
    () => [
      {
        headerName: "Category/Price Range",
        field: "filterType",
        lockPosition: "left",
        cellRenderer: (params) => {
          return !Number(params.data.isCategory)
            ? params.data.categoryName
            : `$${params.data.min} - $${params.data.max}`;
        },
      },
      {
        headerName: "Minimum",
        field: "minimum",
        cellRenderer: (params) => {
          return !Number(params.data.valueType)
            ? `${params.data.value} %`
            : `$ ${params.data.value}`;
        },
      },
      {
        maxWidth: 100,
        headerName: "",
        field: "id",
        lockPosition: "right",
        cellRenderer: (params) => {
          return (
            <button
              type="button"
              onClick={() => handleDeleteItem(params)}
              className="delete-button-style"
            >
              <DeleteIcon />
            </button>
          );
        },
        cellStyle: {
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        },
        sortable: false,
      },
    ],
    // eslint-disable-next-line
    []
  );

  const defaultColDef = useMemo(() => {
    return {
      cellStyle: { display: "flex", alignItems: "center" },
      resizable: false,
    };
  }, []);

  const filterGetRowId = useCallback((params) => params.data.id, []);

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomGridHeader,
    };
  }, []);

  const getRowStyle = (params) => {
    if (params.node && params.node.editing) {
      return { backgroundColor: "#000000" };
    }
    return null;
  };

  const updateFilterList = () => {
    let combineFilterList = getThresholdFilterList(thresholdData);

    combineFilterList = combineFilterList.map((item, index) => ({
      ...item,
      id: index,
    }));

    setFilterList(combineFilterList);
  };

  useEffect(() => {
    updateFilterList();
    setGlobalThreshold({
      type: thresholdData?.global?.valueType || 0,
      value: thresholdData?.global?.value || "0.00",
    });
  }, [thresholdData]);

  useEffect(() => {
    const videogameType = getDefaultFilterType(
      customTypes,
      PRODUCT_TYPES_ENUMS.VIDEO_GAME
    );
    let updatedCategoryList = [];
    if (!Number(customThreshold.filterType)) {
      updatedCategoryList = getCustomCategoriesOptionList(
        customCategories,
        videogameType?.value
      );
    } else {
      updatedCategoryList = inventoryConstants.priceRangeOptions;
    }

    const categoriesFilterList =
      filterList
        ?.filter((item) => !item.isCategory)
        .map((item) => item.categoryId) || [];
    const priceChangeFilterList =
      filterList
        ?.filter((item) => item.isCategory)
        ?.map((item) => stringifyObject({ min: item.min, max: item.max })) ||
      [];

    if (!customThreshold?.filterType) {
      updatedCategoryList = updatedCategoryList.filter(
        (option) => !categoriesFilterList.includes(option.value)
      );
    } else {
      updatedCategoryList = updatedCategoryList.filter(
        (option) =>
          !priceChangeFilterList.includes(
            stringifyObject({
              min: option.value.min,
              max: option.value.max,
            })
          )
      );
    }

    setCategoriesList(updatedCategoryList);
  }, [filterList, customThreshold.filterType]);

  return (
    <Modal
      centered
      size="sm"
      animation={true}
      backdrop="static"
      show={isFilterModal}
      onHide={handleHideModal}
      className="add-inventory-modal"
    >
      <Modal.Header closeButton>
        {/* <Modal.Title>Filter Settings</Modal.Title> */}
      </Modal.Header>
      <Modal.Body className="py-0">
        <Row className="m-0">
          <Col md={12} className="px-0">
            <div className="filter-section-wrapper p-3 w-100 d-flex justify-content-between align-items-center flex-column gap-3">
              <span className="filter-heading ">Set Minimum Filter</span>
              <ToggleButtonWithText
                isToggle={isToggle}
                handleLeftButtonClick={() => {
                  setGlobalThreshold({
                    ...globalThreshold,
                    type: GLOBAL_FILTER_ENUMS.BY_PERCENTAGE.value,
                  });
                  setIsToggle(false);
                }}
                handleRightButtonClick={() => {
                  setGlobalThreshold({
                    ...globalThreshold,
                    type: GLOBAL_FILTER_ENUMS.BY_VALUE.value,
                  });
                  setIsToggle(true);
                }}
                leftButtonLabel={buttonNameConstants.PERCENT}
                rightButtonLabel={buttonNameConstants.VALUE}
              />
              <div className="w-75 d-flex justify-content-center align-items-center gap-2">
                <div className="text-nowrap">Minimum:</div>
                <SimpleNumberField
                  placeHolder="0.00"
                  className="w-100"
                  IconImage={globalThreshold.type ? DollarIcon : PercentageIcon}
                  value={globalThreshold.value}
                  onChange={(value) => {
                    setGlobalThreshold({ ...globalThreshold, value: value });
                  }}
                />
              </div>
            </div>
          </Col>
          {/* <Col md={12} className="px-0">
            <div className="filter-section-wrapper p-3 w-100">
              <span className="filter-small-heading">Global Filter</span>
              <Row className="m-0 w-100">
                <Col
                  md={2}
                  className="ps-md-0 text-muted d-flex align-items-center my-2"
                >
                  Minimum
                </Col>
                <Col md={5} className=" my-2 d-flex align-items-center">
                  <SelectDropDown
                    value={priceChangesConstants.GLOBAL_FILTER_TYPE_OPTIONS.find(
                      (item) => item.value === globalThreshold.type
                    )}
                    className={"w-100"}
                    menuPortalTarget={""}
                    menuPlacement="bottom"
                    placeHolder="Global Type..."
                    options={priceChangesConstants.GLOBAL_FILTER_TYPE_OPTIONS}
                    handleChange={(option) => {
                      setGlobalThreshold({
                        ...globalThreshold,
                        type: option.value,
                      });
                    }}
                  />
                </Col>
                <Col md={5} className="pe-md-0 d-flex align-items-center my-2">
                  <SimpleNumberField
                    placeHolder="0.00"
                    className="w-100"
                    IconImage={
                      globalThreshold.type ? DollarIcon : PercentageIcon
                    }
                    value={globalThreshold.value}
                    onChange={(value) => {
                      setGlobalThreshold({ ...globalThreshold, value: value });
                    }}
                  />
                </Col>
              </Row>
            </div>
          </Col> 
          <Col md={12} className="px-0">
            <div className="filter-section-wrapper p-3 w-100">
              <span className="filter-small-heading">Custom Filter</span>
              <Row className="m-0 w-100">
                <Col
                  md={2}
                  className="ps-md-0 text-muted d-flex align-items-center my-2 text-nowrap"
                >
                  Add filter by:
                </Col>
                <Col md={5} className=" my-2 d-flex align-items-center ">
                  <SelectDropDown
                    placeHolder="Custom Type..."
                    className={"w-100"}
                    menuPortalTarget={""}
                    menuPlacement="bottom"
                    defaultPageSize={
                      priceChangesConstants.CUSTOM_FILTER_TYPE_OPTIONS[0]
                    }
                    options={priceChangesConstants.CUSTOM_FILTER_TYPE_OPTIONS}
                    handleChange={(option) => {
                      setCustomThreshold({
                        ...customThreshold,
                        filterType: option.value,
                      });
                    }}
                  />
                </Col>
                <Col
                  md={1}
                  className="px-md-0 text-muted d-flex align-items-center justify-content-center my-2 text-nowrap"
                >
                  and
                </Col>
                <Col md={4} className="pe-md-0 d-flex align-items-center my-2">
                  <SelectDropDown
                    className={"w-100"}
                    menuPortalTarget={""}
                    menuPlacement="bottom"
                    placeHolder="Global Type..."
                    defaultPageSize={
                      priceChangesConstants.GLOBAL_FILTER_TYPE_OPTIONS[0]
                    }
                    options={priceChangesConstants.GLOBAL_FILTER_TYPE_OPTIONS}
                    handleChange={(option) => {
                      setCustomThreshold({
                        ...customThreshold,
                        filterValue: option.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="m-0 w-100">
                <Col md={6} className="ps-md-0 my-2 d-flex align-items-center ">
                  <SelectDropDown
                    isSearchable={true}
                    className={"w-100"}
                    menuPortalTarget={""}
                    menuPlacement="bottom"
                    options={categoriesList}
                    placeHolder="Category Type..."
                    value={customFilter.filterType}
                    handleChange={(option) => {
                      setCustomFilter({
                        ...customFilter,
                        filterType: option,
                      });
                    }}
                  />
                </Col>
                <Col md={5} className="d-flex align-items-center my-2">
                  <SimpleNumberField
                    placeHolder="0.00"
                    className="w-100"
                    IconImage={
                      customThreshold.filterValue ? DollarIcon : PercentageIcon
                    }
                    value={customFilter.filterMinimumValue}
                    onChange={(value) => {
                      setCustomFilter({
                        ...customFilter,
                        filterMinimumValue: value,
                      });
                    }}
                  />
                </Col>
                <Col
                  md={1}
                  className="pe-md-0 text-muted d-flex align-items-center justify-content-end my-2 text-nowrap"
                >
                  <button
                    type="button"
                    className="add-dropdown-button"
                    onClick={() => handleAddToList()}
                  >
                    <AddIcon />
                  </button>
                </Col>
                <hr className="w-100" />
                <Col md={12} className="mb-3 px-0">
                  <AGGridTable
                    gridRef={gridRef}
                    colDefs={filterColDefs}
                    components={components}
                    getRowId={filterGetRowId}
                    gridData={filterList}
                    getRowStyle={getRowStyle}
                    defaultColDef={defaultColDef}
                    pagination={false}
                    tableHeight={`calc(100vh - 340px)`}
                  />
                </Col>
              </Row>
            </div>
          </Col> */}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Col md={12} className=" m-0 d-flex gap-2">
          <Button
            type="button"
            label={buttonNameConstants.CANCEL}
            handleClick={handleHideModal}
            buttonType={buttonTypeConstants.GHOST_BUTTON}
            className="w-100"
            isDisabled={isSpinnerEnabled(spinnerArray, [
              inventoryConstants.ADD_PRICE_CHANGE_THRESHOLD,
              inventoryConstants.GET_ALL_INVENTORY,
            ])}
          ></Button>
          <Button
            type="button"
            className="w-100"
            label={buttonNameConstants.SAVE}
            handleClick={handleAddCustomFilter}
            isDisabled={isSpinnerEnabled(spinnerArray, [
              inventoryConstants.ADD_PRICE_CHANGE_THRESHOLD,
              inventoryConstants.GET_ALL_INVENTORY,
            ])}
          ></Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

export default PriceChangesFilter;
