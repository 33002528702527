import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { printerActions } from "../../../redux/actions";
import TradePrintLabelModal from "../../../views/Inventory/Items/printingLabelModal/TradePrintLabelModal";
import { NOTIFICATION_TYPES } from "../../../system/globalEnums";
import { getProductLabelNotifications } from "../../utility";

const ToastPrintLabelModal = (props) => {
  const { userNotifications, setPrintLabelModal, isPrintLabelModal } = props;
  const [selectedRows, setSelectedRows] = useState([]);

  const togglePrintLabelModal = () => {
    setPrintLabelModal(!isPrintLabelModal);
  };

  useEffect(() => {
    setSelectedRows([]);
    setSelectedRows([]);

    const reversedArray = getProductLabelNotifications(userNotifications);

    setSelectedRows(
      reversedArray[0]?.products.map((item) => {
        const newObject = {
          sku: item.sku,
          product_name: item.product_name,
          price: {
            quantity: item?.price?.quantity,
            type: item?.price?.type,
            unit_sell_price: item?.price?.unit_sell_price,
            dbQuantity: item.price.dbQuantity,
            isPriceChanged: item.price.isPriceChanged,
            isProductExisted: item.price.isProductExisted,
            quantityAdded: item.price.quantityAdded,
          },
          labelType: reversedArray[0].type,
          category_name: item?.category_name,
          product_name: item?.product_name,
          productType: item?.productType,
          tags: item?.tags,
        };
        return newObject;
      })
    );
  }, [userNotifications]);

  return (
    <TradePrintLabelModal
      printLabelModal={isPrintLabelModal}
      togglePrintLabelModal={togglePrintLabelModal}
      selectedRows={selectedRows}
    />
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  userNotifications: state.store.userNotifications,
  isPrintLabelModal: state.printer.isPrintLabelModal,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  setPrintLabelModal: (data) =>
    dispatch(printerActions.setPrintLabelModal(data)),
});

//-------Export transaction Component
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToastPrintLabelModal);
