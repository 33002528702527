import React from "react";
import { Col } from "react-bootstrap";
import ProgressBar from "./ProgressBar";
import {
  calculateBarWidth,
  formatValueToThousand,
  NoDataWrapper,
} from "../../utility";

const DashboardProgressBar = ({ atAGlance, isSortByVolume }) => {
  const topSellingCategories =
    atAGlance?.bestSellingStats?.topFiveSellingCategory;

  //------- Slice Array to 5 if greater than 5
  const getTopCategories = (categories) =>
    (categories?.length > 5 ? categories.slice(0, 5) : categories) || [];

  //------- Top Selling Categories Sort By Quantity
  const topSellingCategoriesByQuantity = getTopCategories(
    topSellingCategories?.byQuantity?.categories
  );
  //------- Top Selling Categories Sort By Volume
  const topSellingCategoriesByVolume = getTopCategories(
    topSellingCategories?.byVolume?.categories
  );

  //------- get max and current for the bars
  const getMaxAndCurrent = (categories, field, isQuantity) => {
    const max = categories[0]?.[field] || 0;
    return categories.map((category) => ({
      ...category,
      max,
      isQuantity: isQuantity,
      current: category?.[field] || 0,
    }));
  };

  //-------get by quantity data
  const quantityData = getMaxAndCurrent(
    topSellingCategoriesByQuantity,
    "soldUnitsQty",
    true
  );
  //-------get by volumne data
  const volumeData = getMaxAndCurrent(
    topSellingCategoriesByVolume,
    "soldUnitsVolume",
    false
  );

  //-------Render Category bar chart
  const renderCategoryBar = ({ category, current, max, isQuantity }, index) => (
    <React.Fragment key={index}>
      <Col md={12} className="p-0">
        {category}
      </Col>
      <Col md={12} className="p-0 mt-1 mb-3 d-flex align-items-center gap-2">
        <ProgressBar
          min={0}
          max={max}
          label={formatValueToThousand(current, isQuantity ? "" : "$")}
          current={current}
          percentage={calculateBarWidth(max, current)}
        />
      </Col>
    </React.Fragment>
  );

  const categoryBars = (data) =>
    data.length ? data.map(renderCategoryBar) : NoDataWrapper();

  const renderAllOtherCategoriesBar = (max, label, current, isQuantity) => (
    <>
      <Col md={12} className="p-0">
        All Other Categories
      </Col>
      <Col md={12} className="p-0 mt-1 mb-3 d-flex align-items-center gap-2">
        <ProgressBar
          min={0}
          max={max}
          label={formatValueToThousand(label, isQuantity)}
          current={current}
          percentage={calculateBarWidth(max, current)}
        />
      </Col>
    </>
  );

  const remainingData = isSortByVolume?.category
    ? {
        condition: topSellingCategoriesByQuantity.length === 5,
        max: topSellingCategoriesByQuantity[0]?.soldUnitsQty || 0,
        label: topSellingCategories?.byQuantity?.sumOfremainingCategoryQty,
        current: topSellingCategories?.byQuantity?.sumOfremainingCategoryQty,
      }
    : {
        condition: topSellingCategoriesByVolume.length === 5,
        max: topSellingCategoriesByVolume[0]?.soldUnitsVolume || 0,
        label: topSellingCategories?.byVolume?.sumOfremainingCategoryVolume,
        current: topSellingCategories?.byVolume?.sumOfremainingCategoryVolume,
      };

  return (
    <>
      {isSortByVolume?.category
        ? categoryBars(quantityData)
        : categoryBars(volumeData)}
      {remainingData.condition &&
        renderAllOtherCategoriesBar(
          remainingData.max,
          remainingData.label,
          remainingData.current,
          isSortByVolume?.category ? "" : "$"
        )}
    </>
  );
};

export default DashboardProgressBar;
