import React from "react";
import { Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import { dashboardConstants } from "../../../constants";
import DashboardCardSkeleton from "./DashboardCardSkeleton";
import DashboardIconCardSkeleton from "./DashboardIconCardSkeleton";

const DashboardLeftColumnSkeleton = () => {
  return (
    <Row className="m-0 mt-2">
      <Col md={12} className="px-0">
        <div className="dashboard-card-wrapper glance-wrapper">
          <Skeleton className="paragraph-skeleton skeleton-summary-card-heading" />
          <Row className="m-0">
            <Col md={4} className="ps-md-0">
              <DashboardCardSkeleton />
            </Col>
            <Col md={4}>
              <DashboardCardSkeleton />
            </Col>
            <Col md={4} className="pe-md-0">
              <DashboardCardSkeleton />
            </Col>
            <Col md={12} className="px-md-0">
              <DashboardCardSkeleton />
            </Col>
          </Row>
        </div>
      </Col>

      <Col md={12} className="px-0 mt-2">
        <Row className="m-0">
          <Col md={6} className="p-0">
            <DashboardIconCardSkeleton
              cardStyle={dashboardConstants.VERTICAL_CARD_STYLE}
            />
          </Col>
          <Col md={6} className="p-0">
            <DashboardIconCardSkeleton
              cardStyle={dashboardConstants.VERTICAL_CARD_STYLE_2ND}
            />
          </Col>
          <Col md={6} className="p-0">
            <DashboardIconCardSkeleton
              cardStyle={dashboardConstants.VERTICAL_CARD_STYLE}
            />
          </Col>
          <Col md={6} className="p-0">
            <DashboardIconCardSkeleton
              cardStyle={dashboardConstants.VERTICAL_CARD_STYLE_2ND}
            />
          </Col>
          <Col md={6} className="p-0">
            <DashboardIconCardSkeleton
              cardStyle={dashboardConstants.VERTICAL_CARD_STYLE_2ND}
            />
          </Col>
          <Col md={6} className="p-0">
            <DashboardIconCardSkeleton
              cardStyle={dashboardConstants.VERTICAL_CARD_STYLE_2ND}
            />
          </Col>
        </Row>
      </Col>
      <Col md={12} className="px-0">
        <DashboardIconCardSkeleton
          cardStyle={dashboardConstants.REMAINING_CARD_STYLE}
        />
      </Col>
    </Row>
  );
};

export default DashboardLeftColumnSkeleton;
